import React from "react";
import { Switch, Route } from "react-router-dom";
import { SignupForm } from "../components/signupForm/SignupForm";
import { AppLayout } from "../layouts/AppLayout";
import { CreditCardOverride } from "../screens/CreditCardOverride";
import { TermsConditions } from "../screens/TermsConditions";

export const Routes = () => (
  <Switch>
    <Route path="/cc_override">
      <AppLayout>
        <CreditCardOverride />
      </AppLayout>
    </Route>
    <Route path="/terms_conditions">
      <AppLayout>
        <TermsConditions />
      </AppLayout>
    </Route>
    <Route path="/">
      <AppLayout>
        <SignupForm />
      </AppLayout>
    </Route>
  </Switch>
);

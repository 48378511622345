export const createVenue = /* GraphQL */ `
  mutation CreateVenue($name: String!) {
    createVenue() {
     
    }
  }
`;

// TODO update the venue if the business details are changed. Only on save and exit, or submission though
export const updateVenue = /* GraphQL */ `
  mutation UpdateVenue($name: String!) {
    updateVenue() {
     
    }
  }
`;

export const saveSignup = /* GraphQL */ `
  mutation SaveSignup(
    $objectId: String
    $restId: String
    $businessName: String
    $businessAddress1: String
    $businessAddress2: String
    $businessCity: String
    $businessState: String
    $businessPostcode: String
    $businessABN: String
    $businessDBA: String
    $businessACN: String
    $businessPhone: String
    $businessWebsite: String
    $region: String
    $latitude: Float
    $longitude: Float
    $ownerFirstName: String
    $ownerLastName: String
    $ownerEmail: String
    $ownerTitle: String
    $ownerBirthday: AWSDate
    $ownerHomeAddress: String
    $ownerAddress1: String
    $ownerAddress2: String
    $ownerCity: String
    $ownerState: String
    $ownerPostcode: String
    $ownerPhone: String
    $bdmName: String
    $bdmId: String
    $restaurantType: RestaurantType
    $qtrOwner: Boolean
    $qtrOwnerOnly: Boolean
    $boardMember: Boolean
    $boardMemberOnly: Boolean
    $offlineDineIn: Boolean
    $offlineTakeaway: Boolean
    $inAppOrdering: Boolean
    $tableOrdering: Boolean
    $odTableOrdering: Boolean
    $odTakeaway: Boolean
    $odDelivery: Boolean
    $setupFee: Int
    $membershipFee: Int
    $dineInFee: Int
    $dineInFeeType: FeeType
    $dineInAgreement: FeeAgreement
    $dineInOnlinePercent: Float
    $takeawayFee: Int
    $takeawayFeeType: FeeType
    $takeawayAgreement: FeeAgreement
    $takeawayOnlinePercent: Float
    $odFee: Int
    $pricingNotes: String
    $hours: AWSJSON
    $emailSettings: [EmailSettingInput]
    $typicalEta: Int
    $wifiAvailable: Boolean
    $tabletRequired: Boolean
    $pdfMenu: String
    $menuImages: [String]
    $deals: [DealInput]
    $odLogoLink: String
    $odHeroLink: String
    $odMenuImages: [String]
    $odTableCount: Int
    $odPrimaryColor: String
    $odSlug: String
    $bankAccountName: String
    $bankAccountNumber: String
    $bankBSB: String
    $loginUsername: String
    $loginPassword: String
    $signatureImageLink: String
    $completeSubmission: Boolean
    $ddBillingMethodId: String
    $ddBillingMethodName: String
    $ddBillingMandateLink: String
    $ccBillingMethodId: String
    $ccBillingMethodName: String
    $preferredBillingMethod: String
    $businessType: String
    $ecBookings: String
    $ecBookingsSlug: String
    $ecBookingsOperationalEmail: String
    $bookingsPlatform: String
    $bookingsPlatformExpiry: AWSDate
    $posSystem: String
  ) {
    saveSignup(
      objectId: $objectId
      restId: $restId
      businessName: $businessName
      businessAddress1: $businessAddress1
      businessAddress2: $businessAddress2
      businessCity: $businessCity
      businessState: $businessState
      businessPostcode: $businessPostcode
      businessABN: $businessABN
      businessDBA: $businessDBA
      businessACN: $businessACN
      businessPhone: $businessPhone
      businessWebsite: $businessWebsite
      region: $region
      latitude: $latitude
      longitude: $longitude
      ownerFirstName: $ownerFirstName
      ownerLastName: $ownerLastName
      ownerEmail: $ownerEmail
      ownerTitle: $ownerTitle
      ownerBirthday: $ownerBirthday
      ownerAddress1: $ownerAddress1
      ownerAddress2: $ownerAddress2
      ownerCity: $ownerCity
      ownerState: $ownerState
      ownerPostcode: $ownerPostcode
      ownerHomeAddress: $ownerHomeAddress
      ownerPhone: $ownerPhone
      bdmName: $bdmName
      bdmId: $bdmId
      restaurantType: $restaurantType
      qtrOwner: $qtrOwner
      qtrOwnerOnly: $qtrOwnerOnly
      boardMember: $boardMember
      boardMemberOnly: $boardMemberOnly
      offlineDineIn: $offlineDineIn
      offlineTakeaway: $offlineTakeaway
      inAppOrdering: $inAppOrdering
      tableOrdering: $tableOrdering
      odTableOrdering: $odTableOrdering
      odTakeaway: $odTakeaway
      odDelivery: $odDelivery
      setupFee: $setupFee
      membershipFee: $membershipFee
      dineInFee: $dineInFee
      dineInFeeType: $dineInFeeType
      dineInAgreement: $dineInAgreement
      dineInOnlinePercent: $dineInOnlinePercent
      takeawayFee: $takeawayFee
      takeawayFeeType: $takeawayFeeType
      takeawayAgreement: $takeawayAgreement
      takeawayOnlinePercent: $takeawayOnlinePercent
      odFee: $odFee
      pricingNotes: $pricingNotes
      hours: $hours
      emailSettings: $emailSettings
      typicalEta: $typicalEta
      wifiAvailable: $wifiAvailable
      tabletRequired: $tabletRequired
      pdfMenu: $pdfMenu
      menuImages: $menuImages
      deals: $deals
      odLogoLink: $odLogoLink
      odHeroLink: $odHeroLink
      odMenuImages: $odMenuImages
      odTableCount: $odTableCount
      odPrimaryColor: $odPrimaryColor
      odSlug: $odSlug
      bankAccountName: $bankAccountName
      bankAccountNumber: $bankAccountNumber
      bankBSB: $bankBSB
      loginUsername: $loginUsername
      loginPassword: $loginPassword
      signatureImageLink: $signatureImageLink
      completeSubmission: $completeSubmission
      ddBillingMethodId: $ddBillingMethodId
      ddBillingMethodName: $ddBillingMethodName
      ddBillingMandateLink: $ddBillingMandateLink
      ccBillingMethodId: $ccBillingMethodId
      ccBillingMethodName: $ccBillingMethodName
      preferredBillingMethod: $preferredBillingMethod
      businessType: $businessType
      ecBookings: $ecBookings
      ecBookingsSlug: $ecBookingsSlug
      ecBookingsOperationalEmail: $ecBookingsOperationalEmail
      bookingsPlatform: $bookingsPlatform
      bookingsPlatformExpiry: $bookingsPlatformExpiry
      posSystem: $posSystem
    ) {
      objectId
      restId
      businessName
      businessAddress1
      businessAddress2
      businessCity
      businessState
      businessPostcode
      businessABN
      businessDBA
      businessACN
      businessPhone
      businessWebsite
      region
      latitude
      longitude
      ownerFirstName
      ownerLastName
      ownerEmail
      ownerTitle
      ownerBirthday
      ownerAddress1
      ownerAddress2
      ownerCity
      ownerState
      ownerPostcode
      ownerHomeAddress
      ownerPhone
      bdmName
      bdmId
      restaurantType
      qtrOwner
      qtrOwnerOnly
      boardMember
      boardMemberOnly
      offlineDineIn
      offlineTakeaway
      inAppOrdering
      tableOrdering
      odTableOrdering
      odTakeaway
      odDelivery
      setupFee
      membershipFee
      dineInFee
      dineInFeeType
      dineInAgreement
      dineInOnlinePercent
      takeawayFee
      takeawayFeeType
      takeawayAgreement
      takeawayOnlinePercent
      odFee
      pricingNotes
      hours
      emailSettings {
        email
        category
      }
      typicalEta
      wifiAvailable
      tabletRequired
      pdfMenu
      menuImages
      deals {
        discount
        qty
        startDate
        timeLimited
        dineIn
        takeaway
        recurring
        recurringDays
        startTime
        endTime
      }
      odLogoLink
      odHeroLink
      odMenuImages
      odTableCount
      odPrimaryColor
      odSlug
      bankAccountName
      bankAccountNumber
      bankBSB
      loginUsername
      loginPassword
      signatureImageLink
      completionDate
      contractPdfLink
      ddBillingMethodId
      ddBillingMethodName
      ddBillingMandateLink
      ccBillingMethodId
      ccBillingMethodName
      preferredBillingMethod
      businessType
      ecBookings
      ecBookingsSlug
      ecBookingsOperationalEmail
      ccOverride
    }
  }
`;

export const createDDSetupIntent = /* GraphQL */ `
  mutation CreateDDSetupIntent($signupId: String!) {
    createDDSetupIntent(signupId: $signupId)
  }
`;

export const createCCSetupIntent = /* GraphQL */ `
  mutation CreateCCSetupIntent($signupId: String!) {
    createCCSetupIntent(signupId: $signupId)
  }
`;

export const overrideCCForSignup = /* GraphQL */ `
  mutation OverrideCCForSignup($signupId: String!) {
    overrideCCForSignup(signupId: $signupId) {
      objectId
      ccOverride
    }
  }
`;

import { Button } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import { formStateType } from "../../../types";
import SuccessSVG from "../../../assets/success_tick.svg";

export const Success = ({ formState, resetForm }) => (
  <Box
    className="success-page"
    style={{
      textAlign: "center",
      maxWidth: "500px",
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
    <Box style={{ marginBottom: "30px" }}>
      <SuccessSVG />
    </Box>
    <Box style={{ marginBottom: "30px" }}>
      <h1>Success</h1>
    </Box>
    <Box style={{ alignItems: "baseline", marginBottom: "40px" }}>
      <Box className="paragraph-large">
        {formState.businessInfo.tradingName || "Your business"} has successfully been set up and a
        signed contract has been created.
      </Box>
    </Box>
    <Box style={{ marginBottom: "40px" }}>
      <Button
        text="View contract"
        onClick={() => {
          window.location.href = formState?.contractPdfLink;
        }}
      />
    </Box>
    <Box>
      <Button
        className="brand-primary paragraph-regular"
        type="text"
        text="Back to signup home page"
        onClick={resetForm}
      />
    </Box>
  </Box>
);

Success.propTypes = {
  formState: formStateType.isRequired,
  resetForm: PropTypes.func.isRequired,
};

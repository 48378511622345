import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Amplify, { Hub, Auth } from "aws-amplify";
import {
  Authenticator,
  Greetings,
  SignUp,
  ForgotPassword,
  SignIn,
  VerifyContact,
} from "aws-amplify-react";
import config from "../aws-exports";
import CustomSignIn from "./CustomSignIn";

Amplify.configure(config);

const AppWithAuth = ({ children }) => {
  const [authSignedIn, setAuthSignedIn] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "signIn":
          setAuthSignedIn(true);
          Auth.currentAuthenticatedUser({ bypassCache: true }).then((response) => {
            localStorage.setItem("userId", response?.attributes["custom:objectId"]);
          });
          break;
        case "signOut":
          console.log("signOut");
          localStorage.setItem("userId", "");
          setAuthSignedIn(false);
          setAuthLoading(false);
          break;
        case "customOAuthState":
          break;
        case "signIn_failure":
          setAuthLoading(false);
          break;
        default:
          break;
      }
    });
  }, []);

  const mapErrorMessages = (message) => {
    if (message === "Custom auth lambda trigger is not configured for the user pool.") {
      return "Please enter your password";
    }
    return message;
  };

  const signUpConfig = {
    header: "Sign Up to EatClub",
    hiddenDefaults: ["phone_number"],
  };

  return (
    <Authenticator
      onStateChange={(authState) => {
        if (authState === "signedIn") {
          // NOTE: this will trigger when refreshing and logged in
          // show app here to prevent showing both login screen, and app
          setAuthSignedIn(true);
          setAuthLoading(false);
        }
        if (authState === "signIn") {
          setAuthLoading(false);
        }
      }}
      hide={[Greetings, SignIn, SignUp, ForgotPassword, VerifyContact]}
      includeGreetings
      amplifyConfig={config}
      signUpConfig={signUpConfig}
      usernameAttributes="email"
      errorMessage={mapErrorMessages}
    >
      <Box>
        {authSignedIn && children}

        {!authLoading && !authSignedIn && <CustomSignIn authSignedIn={authSignedIn} />}
      </Box>
    </Authenticator>
  );
};

AppWithAuth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AppWithAuth;

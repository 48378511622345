import { Auth } from "aws-amplify";

export const signIn = async (email, password) => {
  const user = await Auth.signIn(email, password);

  console.log("signed in user:", user);

  // TODO fetch user details

  return user;
};

export const signOut = async () => Auth.signOut();

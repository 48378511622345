export const COLORS = {
  BRAND_PRIMARY: "#E93F37",
  LINEN: "#F5F2ED",
  JET: "#1A1919",
  CHARCOAL: "#313131",
  SMOKE: "#6E6E6D",
  NIMBUS: "#AEAEAE",
  SHARK: "#DCDCDB",
  CLOUD: "#F0F0F0",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  BACKGROUND_GRAY: "#F3F3F3",
};

export const SHADOWS = {
  STANDARD: {
    "-webkit-box-shadow": "0 4px 14px 0 rgba(202,202,202,0.5)",
    boxShadow: "0 4px 14px 0 rgba(202,202,202,0.5)",
  },
};

export const TRANSITIONS = {};

export const ANIMATIONS = {};

export const BORDERS = {
  LIGHT: {
    border: `solid 1px ${COLORS.NIMBUS}`,
  },
};

export const INTERACTIONS = {
  CLICKABLE: {
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      filter: "brightness(95%)",
    },
    "&:active": {
      filter: "brightness(90%)",
    },
  },
};

export const SPACING = {
  XXS: "",
  XS: "",
  S: "10px",
  M: "20px",
  L: "",
  XL: "",
  XXL: "",
};

export const FONT = {
  WEIGHT: {
    LIGHT: 300,
    REGULAR: 400,
    SEMIBOLD: 500,
    BOLD: 600,
  },

  SIZE: {
    SMALL: "",
    MEDIUM: "",
    LARGE: "",
  },
};

export const BORDER_RADIUS = {
  STANDARD: {
    borderRadius: "6px",
  },
  STANDARD_TOP_ONLY: {
    borderRadius: "6px 6px 0 0",
  },
  STANDARD_BOTTOM_ONLY: {
    borderRadius: "0 0 6px 6px",
  },
};

export const CONTENT_WIDTHS = {
  LARGE: 1200,
};

export const TEXT = {
  HEADING_1: {},
  HEADING_2: {},
  HEADING_3: {},
  HEADING_4: {},
  PARAGRAPH_L: {},
  PARAGRAPH_S: {},
  PARAGRAPH_XS: {},
};

export const MODAL_SIZES = {};

export const ICON_SIZES = {};

export const BREAKPOINTS = {
  DESKTOP: "@media(min-width: 1200px)",
  TABLET: "@media(max-width: 1199px)",
  MOBILE: "@media(max-width: 700px)",
};

export const Z_INDEX = {};

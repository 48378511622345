import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import CheckSVG from "../../assets/confirmation_check_medium.svg";
import CrossSVG from "../../assets/confirmation_cross_medium.svg";

export const CompletedItem = ({ completed, label, style }) => (
  <Box style={style}>
    <Box style={{ display: "inline-flex", columnGap: "10px" }}>
      {completed ? (
        <CheckSVG className="button-icon" alt="Green check indicating completed item" />
      ) : (
        <CrossSVG alt="Red cross indicating incomplete item" />
      )}
      <Box>{label}</Box>
    </Box>
  </Box>
);

CompletedItem.defaultProps = {
  completed: true,
  label: "",
  style: {},
};

CompletedItem.propTypes = {
  completed: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.shape({}),
};

import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import DownArrowSVG from "../../assets/down_arrow.svg";
import UpArrowSVG from "../../assets/up_arrow.svg";
import { detectClickOutside } from "../../helpers/Helpers";

// TODO this is (mostly) just a skinned dropdown
export const StepSelector = ({ step, screens, setStep }) => {
  const [isOpen, setOpen] = useState(false);

  const selectableScreens = screens.filter((screen) => screen.showInSelector);
  const totalSteps = selectableScreens.length;

  // Used for keyboard accessibility
  const onFocus = () => onOpen();
  const onBlur = () => {
    if (isOpen) {
      onClose();
    }

    if (document.activeElement !== listRef.current) {
      return;
    }

    document.activeElement?.blur();
  };
  const [hoveredItem, setHoveredItem] = useState(null);
  const listRef = useRef(null);
  const itemRefs = [];

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setHoveredItem(null);
    setOpen(false);
  };

  const onSelect = (stepId) => {
    setOpen(false);
    setStep(stepId);
  };

  const wrapperRef = useRef(null);
  detectClickOutside(wrapperRef, () => setOpen(false));

  const screenIndex = selectableScreens.findIndex((screen) => screen.id === step);

  // TODO can these be a hook?

  const handleKeyPress = (event) => {
    // Return early if this is not the active element
    if (document.activeElement !== listRef.current) {
      return;
    }

    // Blur on escape without selecting
    if (event.key === "Escape") {
      onBlur();
    }

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }

    if (isOpen) {
      const itemHeight = 54;
      if (event.key === "ArrowDown") {
        if (hoveredItem === null) {
          setHoveredItem(0);
        } else {
          const nextItem = hoveredItem + 1;
          if (nextItem < selectableScreens.length) {
            setHoveredItem(nextItem);
          }
        }
      } else if (event.key === "ArrowUp") {
        const previousItem = hoveredItem - 1;
        if (previousItem >= 0) {
          setHoveredItem(previousItem);
        }
      }

      // If press enter, select the hovered item
      if (event.key === "Enter") {
        onSelect(selectableScreens[hoveredItem].id);
      }
    } else {
      // Down arrow should open dropdown if it's closed
      if (event.key === "ArrowDown") {
        onOpen();
        setHoveredItem(0);
      }
    }
  };

  const setRef = (ref) => {
    itemRefs.push(ref);
  };

  // Add keyboard event listener to input
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [hoveredItem, isOpen]);

  if (screenIndex < 0) {
    return <></>;
  }

  return (
    <>
      <Box ref={wrapperRef} className="step-selector-container">
        <Box
          className={`step-selector ${isOpen && "step-selector-open"}`}
          onMouseDown={(e) => {
            e.preventDefault();
            setOpen(!isOpen);
          }}
          tabIndex={0}
          ref={listRef}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          <Box className="step-selector-content">
            <Box>
              Step{" "}
              <b>
                {screenIndex >= totalSteps ? totalSteps : screenIndex + 1} of {totalSteps}
              </b>
            </Box>
            <Box>
              {isOpen ? (
                <UpArrowSVG style={{ marginBottom: "2px" }} />
              ) : (
                <DownArrowSVG style={{ marginBottom: "1px" }} />
              )}
            </Box>
          </Box>
        </Box>
        <Box className={`step-selector-menu ${isOpen ? "visible" : "hidden"}`}>
          {isOpen &&
            selectableScreens.map((screen, i) => (
              <Box
                className={`${screen.id === step ? "active-step" : ""} ${
                  i === hoveredItem ? "step-selector-item-hovered" : ""
                }`}
                key={screen.name}
                onMouseDown={() => onSelect(screen.id)}
                onMouseEnter={() => setHoveredItem(i)}
                ref={setRef}
              >
                {i + 1} - {screen.name}
              </Box>
            ))}
        </Box>
      </Box>
    </>
  );
};

StepSelector.propTypes = {
  step: PropTypes.string.isRequired,
  screens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setStep: PropTypes.func.isRequired,
};

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    maxWidth: "800px",
  },
  termsContainer: {
    alignItems: "baseline",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  termContainer: {
    alignItems: "baseline",
    display: "flex",
    gap: "12px",
  },
});

export default useStyles;

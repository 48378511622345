// TODO double check with the signup API which fields are required

export const formFields = () => [
  {
    fieldName: "newSignup",
    displayName: "New restaurant",
    description: "new restaurant",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "signup",
    defaultValue: true,
  },
  {
    fieldName: "businessInfo.legalName",
    displayName: "Business legal name",
    description: "business' legal name",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  // {
  //   fieldName: "businessInfo.abn",
  //   displayName: "Business ABN",
  //   description: "business's ABN",
  //   required: true,
  //   validationFunction: null,
  //   validationErrorMessage: null,
  //   pageId: "restaurant",
  //   defaultValue: null,
  // },
  // {
  //   fieldName: "businessInfo.tradingName",
  //   displayName: "Business trading name",
  //   description: "business's trading name",
  //   required: true,
  //   validationFunction: null,
  //   validationErrorMessage: null,
  //   pageId: "restaurant",
  //   defaultValue: null,
  // },
  {
    fieldName: "businessInfo.phoneNumber",
    displayName: "Business phone number",
    description: "business' phone number",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "businessInfo.businessType",
    displayName: "Business type",
    description: "business type",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "payment",
    defaultValue: null,
  },
  {
    fieldName: "businessInfo.addressLine1",
    displayName: "Business address",
    description: "business' address",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "businessInfo.city",
    displayName: "Business suburb",
    description: "business' suburb",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "businessInfo.region",
    displayName: "Business region",
    description: "business' state",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "businessInfo.eatclubRegion",
    displayName: "EatClub region",
    description: "EatClub region",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "businessInfo.postcode",
    displayName: "Business postcode",
    description: "business's postcode",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "payment",
    defaultValue: null,
  },
  {
    fieldName: "accountHolder.firstName",
    displayName: "Signing authority first name",
    description: "signing authority's first name",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "accountHolder.lastName",
    displayName: "Signing authority last name",
    description: "signing authority's last name",
    required: false, // Some people only go by one name
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "accountHolder.email",
    displayName: "Signing authority email",
    description: "signing authority's email",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "accountHolder.jobTitle",
    displayName: "Signing authority job title",
    description: "signing authority's job title",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "accountHolder.dateOfBirth",
    displayName: "Signing authority date of birth",
    description: "signing authority's date of birth",
    required: true,
    validationFunction: null, // TODO
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: "",
  },
  {
    fieldName: "accountHolder.addressLine1",
    displayName: "Signing authority address",
    description: "signing authority's address",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: "",
  },
  {
    fieldName: "accountHolder.postcode",
    displayName: "Signing authority postcode",
    description: "signing authority's postcode",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: "",
  },
  {
    fieldName: "accountHolder.city",
    displayName: "Signing authority city",
    description: "signing authority's city",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: "",
  },
  {
    fieldName: "accountHolder.region",
    displayName: "Account holder state",
    description: "account holder's state",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: "",
  },
  {
    fieldName: "accountHolder.phoneNumber",
    displayName: "Signing authority phone number",
    description: "signing authority's phone number",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "bdm",
    displayName: "BDM",
    description: "BDM",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: JSON.parse(localStorage.getItem("lastBDM")),
  },
  {
    fieldName: "restaurantType",
    displayName: "Restaurant type",
    description: "restaurant type",
    required: false,
    validationFunction: null, // sit_down or QSR
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "services.takeaway.takeawayVouchers",
    displayName: "Takeaway vouchers",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "services",
    defaultValue: null,
  },
  {
    fieldName: "services.takeaway.takeawayIAO",
    displayName: "Takeaway in-app ordering",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "services",
    defaultValue: null,
  },
  {
    fieldName: "services.dineIn.dineInVouchers",
    displayName: "Dine-in vouchers",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "services",
    defaultValue: null,
  },
  {
    fieldName: "services.dineIn.tableOrders",
    displayName: "Table orders",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "services",
    defaultValue: null,
  },
  {
    fieldName: "services.whiteLabel.tableOrdering",
    displayName: "Table ordering",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "services",
    defaultValue: null,
  },
  {
    fieldName: "services.whiteLabel.takeaway",
    displayName: "Takeaway",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "services",
    defaultValue: null,
  },
  {
    fieldName: "services.whiteLabel.delivery",
    displayName: "Delivery",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "services",
    defaultValue: null,
  },
  {
    fieldName: "fees.dineIn.amount",
    displayName: "Dine-in fee amount",
    description: "dine-in fee amount",
    required: false,
    validationFunction: null, // required if dine in set
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: null,
  },
  {
    fieldName: "fees.dineIn.pricingType",
    displayName: "Dine-in pricing type",
    description: "dine-in pricing type",
    required: false,
    validationFunction: null, // required if dine in set
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: "booking",
  },
  {
    fieldName: "fees.dineIn.agreement",
    displayName: "Dine-in agreement",
    description: "dine-in agreement",
    required: false,
    validationFunction: null, // required if dine in set
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: "exclusive",
  },
  {
    fieldName: "fees.takeawayVouchers.amount",
    displayName: "Takeaway vouchers amount",
    description: "takeaway voucher amount",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: null,
  },
  {
    fieldName: "fees.takeawayOrders.amount",
    displayName: "Takeaway orders amount",
    description: "takeaway order amount",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: 4,
  },
  {
    fieldName: "fees.takeawayOrders.pricingType",
    displayName: "Takeaway order pricing type",
    description: "takeaway order pricing type",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: "booking",
  },
  {
    fieldName: "fees.dineInTableOrders.amount",
    displayName: "Dine-in table orders amount",
    description: "dine-in order amount",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: null,
  },
  {
    fieldName: "fees.dineInTableOrders.pricingType",
    displayName: "Dine-in table orders pricing type",
    description: "dine-in order pricing type",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: null,
  },
  {
    fieldName: "fees.whiteLabel.amount",
    displayName: "Order Direct amount",
    description: "Order Direct amount",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: null,
  },
  {
    fieldName: "fees.whiteLabel.pricingType",
    displayName: "Order Direct pricing type",
    description: "Order Direct pricing type",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: null,
  },
  {
    fieldName: "fees.whiteLabel.predominantOrderingMethod",
    displayName: "Order Direct predominant ordering method",
    description: "Order Direct predominant ordering method",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: null,
  },
  {
    fieldName: "fees.whiteLabel.processingFees",
    displayName: "Order Direct processing fees",
    description: "Order Direct processing fees",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: 2,
  },
  {
    fieldName: "fees.whiteLabel.commission",
    displayName: "Order Direct commission",
    description: "Order Direct commission",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: 3,
  },
  {
    fieldName: "fees.setup.amount",
    displayName: "Setup fee amount",
    description: "setup fee amount",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: 200,
  },
  {
    fieldName: "fees.setup.frequency",
    displayName: "Setup fee frequency",
    description: "setup fee frequency",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: "onetime",
  },
  {
    fieldName: "fees.membership.amount",
    displayName: "Membership fee amount",
    description: "membership fee amount",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: 80,
  },
  {
    fieldName: "fees.membership.frequency",
    displayName: "Membership fee frequency",
    description: "membership fee frequency",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: "monthly",
  },
  {
    fieldName: "fees.pricingNotes",
    displayName: "Pricing notes",
    description: "pricing notes",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "pricing",
    defaultValue: null,
  },
  // TODO opening hours, with validation of start and end times for each shift
  {
    fieldName: "communication.emailAddresses",
    displayName: "Communication email addresses",
    description: "communication email addresses",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "communication",
    defaultValue: [],
  },
  {
    fieldName: "communication.marketing",
    displayName: "Marketing",
    description: "marketing emails",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "communication",
    defaultValue: true,
  },
  {
    fieldName: "communication.orders",
    displayName: "Confirmations",
    description: "confirmation emails",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "communication",
    defaultValue: true,
  },
  {
    fieldName: "communication.invoices",
    displayName: "Payment summaries",
    description: "email for payment summaries and invoices",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "communication",
    defaultValue: true,
  },
  {
    fieldName: "communication.oppos",
    displayName: "Daily opportunities",
    description: "daily opportunities",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "communication",
    defaultValue: true,
  },
  {
    fieldName: "operations.preparationTime",
    displayName: "Preparation time",
    description: "preparation time",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "operations",
    defaultValue: 30,
  },
  {
    fieldName: "operations.wifiAvailable",
    displayName: "WiFi available",
    description: "WiFi availability",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "operations",
    defaultValue: null,
  },
  {
    fieldName: "operations.tabletRequired",
    displayName: "Tablet required",
    description: "tablet requirement",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "operations",
    defaultValue: null,
  },
  {
    fieldName: "operations.pdfLink",
    displayName: "PDF link",
    description: "PDF link",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "operations",
    defaultValue: null,
  },
  {
    fieldName: "operations.menuImageLink",
    displayName: "Menu image link",
    description: "menu image link",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "operations",
    defaultValue: null,
  },
  {
    fieldName: "deals",
    displayName: "Deals",
    description: "deals",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "deals",
    defaultValue: [],
  },
  {
    fieldName: "orderDirect.logo",
    displayName: "Restaurant logo",
    description: "restaurant's logo",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "order_direct",
    defaultValue: null,
  },
  {
    fieldName: "orderDirect.heroImage",
    displayName: "Restaurant hero image",
    description: "restaurant's hero image",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "order_direct",
    defaultValue: null,
  },
  {
    fieldName: "orderDirect.menuImageLink",
    displayName: "Restaurant menu image link",
    description: "restaurant's menu image link",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "order_direct",
    defaultValue: null,
  },
  {
    fieldName: "orderDirect.tableAmount",
    displayName: "Table amount",
    description: "table amount",
    required: false,
    validationFunction: null, // Required if order direct is selected
    validationErrorMessage: null,
    pageId: "order_direct",
    defaultValue: null,
  },
  {
    fieldName: "orderDirect.brandColor",
    displayName: "Brand colour",
    description: "brand colour",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "order_direct",
    defaultValue: null,
  },
  {
    fieldName: "marketing.emailFeature",
    displayName: "Marketing emails",
    description: "marketing emails",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "marketing",
    defaultValue: false,
  },
  {
    fieldName: "marketing.emailMention",
    displayName: "Marketing email mentions",
    description: "marketing email mentions",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "marketing",
    defaultValue: false,
  },
  {
    fieldName: "marketing.facebookPost",
    displayName: "Marketing facebook posts",
    description: "marketing facebook posts",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "marketing",
    defaultValue: false,
  },
  {
    fieldName: "marketing.pushNotifications",
    displayName: "Marketing push notifications",
    description: "marketing push notifications",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "marketing",
    defaultValue: false,
  },
  {
    fieldName: "marketing.fullPackage",
    displayName: "Marketing full package",
    description: "marketing full package",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "marketing",
    defaultValue: false,
  },
  {
    fieldName: "paymentDetails.bankAccountName",
    displayName: "Bank account name",
    description: "bank account name",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "payment",
    defaultValue: null,
  },
  {
    fieldName: "paymentDetails.bsb",
    displayName: "BSB",
    description: "bank account BSB",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "payment",
    defaultValue: null,
  },
  {
    fieldName: "paymentDetails.bankAccountNumber",
    displayName: "Bank account number",
    description: "bank account number",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "payment",
    defaultValue: null,
  },
  {
    fieldName: "paymentDetails.ownTwentyFivePercent",
    displayName: "Own 25 percent",
    description: "ownership details",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "paymentDetails.onlyTwentyFivePercentOwner",
    displayName: "Only 25 percent owner",
    description: "ownership details",
    required: false,
    validationFunction: null, // todo only required if above is set
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "paymentDetails.memberOfGoverningBoard",
    displayName: "Member of governing board",
    description: "governing board details",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "paymentDetails.onlyMemberOfGoverningBoard",
    displayName: "Only member of governing board",
    description: "governing board details",
    required: false,
    validationFunction: null, // todo only required if above is set
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "username",
    displayName: "Username",
    description: "username",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "review",
    defaultValue: null,
  },
  {
    fieldName: "password",
    displayName: "Password",
    description: "password",
    required: false,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "review",
    defaultValue: null,
  },
  {
    fieldName: "signature",
    displayName: "Signature",
    description: "signature",
    required: false,
    validationFunction: null, // TODO validate not blank
    validationErrorMessage: null,
    pageId: "review",
    defaultValue: null,
  },
  {
    fieldName: "posSystem",
    displayName: "POS System",
    description: "pos system",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "bookingSystem",
    displayName: "Booking System",
    description: "booking system",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
  {
    fieldName: "bookingsPlatformExpiry",
    displayName: "Booking Platform Expiry",
    description: "booking platform expiry",
    required: true,
    validationFunction: null,
    validationErrorMessage: null,
    pageId: "restaurant",
    defaultValue: null,
  },
];

export const signupAmount = 199;
export const membershipAmount = 49;

export const getDefaultFormValues = (defaultData = null) => ({
  newSignup: true,
  businessInfo: {
    id: null,
    businessType: null,
    legalName: null,
    abn: null,
    acn: null,
    tradingName: defaultData?.tradingName || null,
    phoneNumber: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    region: null,
    postcode: null,
    eatclubRegion: null,
    latitude: null,
    longitude: null,
    website: null,
  },
  accountHolder: {
    firstName: null,
    lastName: null,
    dateOfBirth: "",
    email: null,
    jobTitle: null,
    phoneNumber: null,
    addressLine1: null,
    postcode: null,
    city: null, // suburb
    region: null, // state
  },
  bdm: JSON.parse(localStorage.getItem("lastBDM")),
  restaurantType: null, // Sit down or QSR. Make sure this is used to set default pricing
  services: {
    bookings: {
      enabled: false,
      plan: "lite",
      slug: "",
      email: "",
    },
    takeaway: {
      takeawayVouchers: null,
      takeawayIAO: null,
    },
    dineIn: {
      dineInVouchers: null,
      tableOrders: null,
    },
    whiteLabel: {
      tableOrdering: null,
      takeaway: null,
      delivery: null,
    },
  },
  fees: {
    dineIn: {
      amount: 4,
      pricingType: "booking",
      agreement: "exclusive",
      differentOnlinePricing: false,
      onlineAmount: 10,
    },
    takeawayOrders: {
      amount: 4,
      pricingType: "percent",
      agreement: "exclusive",
      differentOnlinePricing: true,
      onlineAmount: 10,
    },
    whiteLabel: {
      pricingType: "transaction_fee",
      amount: 3.5,
    },
    setup: {
      amount: signupAmount,
      frequency: "onetime",
    },
    membership: {
      amount: membershipAmount,
      frequency: "monthly",
    },
    pricingNotes: null,
  },
  openingHours: {
    monday: {
      isOpen: true,
      shifts: [{ start: null, end: null, id: 0 }],
    },
    tuesday: {
      isOpen: true,
      shifts: [{ start: null, end: null, id: 0 }],
    },
    wednesday: {
      isOpen: true,
      shifts: [{ start: null, end: null, id: 0 }],
    },
    thursday: {
      isOpen: true,
      shifts: [{ start: null, end: null, id: 0 }],
    },
    friday: {
      isOpen: true,
      shifts: [{ start: null, end: null, id: 0 }],
    },
    saturday: {
      isOpen: true,
      shifts: [{ start: null, end: null, id: 0 }],
    },
    sunday: {
      isOpen: true,
      shifts: [{ start: null, end: null, id: 0 }],
    },
  },
  communication: {
    marketing: {
      isUsed: true,
      emailAddresses: [],
    },
    orders: {
      isUsed: true,
      emailAddresses: [],
    },
    invoices: {
      isUsed: true,
      emailAddresses: [],
    },
    oppos: {
      isUsed: true,
      emailAddresses: [],
    },
  },
  operations: {
    preparationTime: 30,
    wifiAvailable: null,
    tabletRequired: null,
    pdfLink: null,
    menuImageLink: null,
  },
  deals: [],
  orderDirect: {
    logo: null,
    heroImage: null,
    menuImageLink: null,
    tableAmount: null,
    brandColor: null,
    slug: null,
  },
  marketing: {
    emailFeature: false,
    emailMention: false,
    facebookPost: false,
    pushNotifications: false,
    fullPackage: false,
  },
  paymentDetails: {
    // DANGER
    bankAccountName: null,
    bsb: null,
    bankAccountNumber: null,
    ownTwentyFivePercent: null,
    onlyTwentyFivePercentOwner: null,
    memberOfGoverningBoard: null,
    onlyMemberOfGoverningBoard: null,
    creditCardCapture: true,
    directDebitCapture: false,
    creditCardName: null, // the last four credit card digits
    directDebitName: null, // the last four bank account digits
    creditCardStripeId: null, // the stripe ID to fetch cc details
    directDebitStripeId: null, // the stripe ID to fetch dd details
    preferredMethod: "cc",
    ccOverride: false, // Whether to override the credit card details requirement
  },
  username: null,
  password: null,
  signature: null,
  signupId: null,
  completionDate: null,
  contractPdfLink: null,
  posSystem: null,
  customPos: null,
  bookingSystem: null,
  customBookingSystem: null,
  bookingsPlatformExpiry: null,
});

import PropTypes from "prop-types";
import React from "react";
import { Box, Modal } from "@eatclub-apps/ec-component-library";
import useStyles from "./DDRServiceAgreementStyles";

// TODO move to folder
export const DDRServiceAgreement = ({ isOpen, onClose }) => {
  const classes = useStyles();

  const terms = [
    "By agreeing to the Direct Debit Request you authorise Stripe to arrange for funds to be debited from your nominated financial institution account (the “nominated account”). Stripe is acting as an agent for the Merchant and Stripe does not provide any goods or services to you.",
    "Stripe or the Merchant will give you at least 14 days notice in writing of any changes to the terms of the drawing arrangements.",
    <Box>
      Stripe will keep information relating to your nominated account confidential in accordance
      with Stripe’s{" "}
      <a href="https://stripe.com/au/privacy" target="_blank" rel="noreferrer">
        privacy policy
      </a>
      , except where required for the purposes of conducting direct debits with your financial
      institution. Your personal information will be transferred by Stripe to the United States. If
      you do not want to provide your personal information to Stripe in connection with the Direct
      Debit Request, Stripe will not be able to debit your nominated account.
    </Box>,
    "Where the due date is not a business day Stripe will draw from your nominated financial institution account on the next business day.",
    <Box className={classes.termsContainer}>
      <Box>It is your responsibility to:</Box>
      <Box className={classes.termsContainer}>
        {[
          "Ensure your nominated account can accept direct debits;",
          "Ensure that there are sufficient clear funds available in the nominated account to meet each drawing on the due date;",
          "Advise immediately if the nominated account is transferred or closed or your account details change;",
          "Arrange a suitable payment method if Stripe or the Merchant cancels the drawing arrangements;",
          "Ensure that all authorised signatories nominated on the financial institution account to be debited authorise the Direct Debit Request.",
        ].map((term, i) => (
          <Box className={classes.termContainer}>
            <Box>{i + 1}.</Box>
            <Box>{term}</Box>
          </Box>
        ))}
      </Box>
    </Box>,
    <Box className={classes.termsContainer}>
      <Box>
        Subject to the terms and conditions of your nominated financial institution account and your
        agreement with the Merchant, you may alter the drawing arrangements. Such advice should be
        received by the Merchant at least 7 business days prior to the drawing date for any of the
        following:
      </Box>
      <Box className={classes.termsContainer}>
        {[
          "Changing your nominated account number",
          "Deferring a drawing",
          "Altering a DDR schedule",
          "Cancelling the drawings completely",
        ].map((term, i) => (
          <Box className={classes.termContainer}>
            <Box>{i + 1}.</Box>
            <Box>{term}</Box>
          </Box>
        ))}
      </Box>
      If you require further information, please contact the Merchant. Alternatively, you can also
      contact your financial institution.
    </Box>,
    [
      "If you believe that there has been an error in debiting your account, you should notify the Merchant as soon as possible. The Merchant will notify you in writing of its determination and the amount of any adjustment that will be made to your nominated account (if any). Stripe will arrange for your financial institution to adjust your nominated account by the applicable amount (if any). Alternatively, you can also contact your financial institution.",
    ],
    ["The details of your drawing arrangements are contained in the above Direct Debit Request."],
    [
      "Stripe reserves the right to cancel the drawing arrangements if three consecutive drawings are dishonoured by your financial institution, and for the Merchant to arrange with you an alternative payment method. Please refer to the terms and conditions of your nominated financial institution account to see whether dishonour fees apply. The Merchant may charge additional dishonour fees in accordance with your agreement with the Merchant.",
    ],
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      headerLabel="BECS Direct Debit Request Service Agreement"
      fullWidth={false}
      showCloseText={false}
      style={{
        header: {
          background: "#F5F2ED",
          display: "flex",
          fontWeight: "500",
          justifyContent: "space-between",
          padding: "20px 35px",
          position: "sticky",
          top: 0,
        },
        content: {
          padding: "20px 35px",
        },
        footer: {
          background: "white",
          bottom: 0,
          padding: "0 35px",
          position: "sticky",
        },
      }}
    >
      <Box className={classes.container}>
        {terms.map((term, i) => (
          <Box className={classes.termContainer}>
            <Box>{i + 1}.</Box>
            <Box>{term}</Box>
          </Box>
        ))}
      </Box>
    </Modal>
  );
};

DDRServiceAgreement.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

DDRServiceAgreement.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

import {
  Button,
  Modal,
  SegmentedControl,
  TextArea,
  TextSelect,
  TextField,
  Checkbox,
} from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { formStateType } from "../../../types";
import {
  getLabelForValue,
  agreements,
  dineInPricingTypes,
  setupPaymentFrequencies,
  bookingAmounts,
  takeawayPricingTypes,
  setupFeeAmounts,
  membershipFrequencies,
  whiteLabelTransactionFees,
  bookingPercentAmounts,
  eatclubBookingsPlans,
} from "../../../data/ValueLists";
import { TYPES } from "../../../helpers/InputTypes";
import { formatCurrency, isECPayRegion, trim } from "../../../helpers/Helpers";

export const getBookingsFee = (plan) => {
  switch (plan) {
    case "lite":
      return "$10 per month";
    case "premium":
      return "$50 per month";
    case "bookingsOnly":
      return "$149 per month";
    default:
      return null;
  }
};

export const Pricing = ({ formState, setFieldValue, isComplete, setFieldValues }) => {
  const [showDineinFeeModal, setShowDineinFeeModal] = useState(false);
  const [showTakeawayOrdersModal, setShowTakeawayOrdersModal] = useState(false);
  const [showOrderDirectModal, setShowOrderDirectModal] = useState(false);
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showMembershipModal, setShowMembershipModal] = useState(false);

  const [additionalNotes, setAdditionalNotes] = useState(formState.fees.pricingNotes);
  const [dineInAmount, setDineInAmount] = useState(formState.fees.dineIn.amount);
  const [dineInOnlineAmount, setDineInOnlineAmount] = useState(formState.fees.dineIn.onlineAmount);
  const [takeawayAmount, setTakeawayAmount] = useState(formState.fees.takeawayOrders.amount);
  const [takeawayOnlineAmount, setTakeawayOnlineAmount] = useState(
    formState.fees.takeawayOrders.onlineAmount,
  );
  const [membershipAmount, setMembershipAmount] = useState(formState?.fees?.membership?.amount);
  const [setupFee, setSetupFee] = useState(formState?.fees?.setup?.amount);
  const [whiteLabelFee, setWhiteLabelFee] = useState(formState?.fees?.whiteLabel?.amount);

  const useWhiteLabel =
    formState?.services?.whiteLabel?.tableOrdering ||
    formState?.services?.whiteLabel?.takeaway ||
    formState?.services?.whiteLabel?.delivery;

  const lockMembership = formState?.services?.bookings?.enabled;
  const bookingsOnly =
    formState?.services?.bookings?.enabled &&
    formState?.services?.bookings?.plan === "bookingsOnly";

  const updateDineInAmount = (value) => {
    if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
      setTakeawayAmount(value);
      setFieldValues([
        { name: "fees.dineIn.amount", value },
        { name: "fees.takeawayOrders.amount", value },
      ]);
    } else {
      setFieldValue("fees.dineIn.amount", value);
    }
  };

  const updateDineInOnlineAmount = (value) => {
    // const roundedValue = getClosestValueInList(
    //   value,
    //   bookingPercentAmounts().map((amount) => amount.value)
    // );

    if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
      setTakeawayOnlineAmount(value);
      setFieldValues([
        { name: "fees.dineIn.onlineAmount", value },
        { name: "fees.takeawayOrders.onlineAmount", value },
      ]);
    } else {
      setFieldValue("fees.dineIn.onlineAmount", value);
    }
  };

  const updateTakeawayAmount = (value) => {
    if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
      setDineInAmount(value);
      setFieldValues([
        { name: "fees.dineIn.amount", value },
        { name: "fees.takeawayOrders.amount", value },
      ]);
    } else {
      setFieldValue("fees.takeawayOrders.amount", value);
    }
  };

  const updateTakeawayOnlineAmount = (value) => {
    // // Get closest value if it doesn't match
    // const roundedValue = getClosestValueInList(
    //   value,
    //   bookingPercentAmounts().map((amount) => amount.value)
    // );
    //
    // console.log("rounded value", roundedValue);

    if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
      setDineInOnlineAmount(value);
      setFieldValues([
        { name: "fees.dineIn.onlineAmount", value },
        { name: "fees.takeawayOrders.onlineAmount", value },
      ]);
    } else {
      setFieldValue("fees.takeawayOrders.onlineAmount", value);
    }
  };

  const updateTakeawayOnlinePricing = (value) => {
    if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
      setFieldValues([
        { name: "fees.dineIn.differentOnlinePricing", value },
        { name: "fees.takeawayOrders.differentOnlinePricing", value },
      ]);
    } else {
      setFieldValues([{ name: "fees.takeawayOrders.differentOnlinePricing", value }]);
    }
  };

  const getFeeDescription = (amount, pricingType) =>
    `${formatCurrency(amount)} ${getLabelForValue(
      pricingType,
      takeawayPricingTypes(),
    ).toLowerCase()}`;

  const dineinModal = (
    <Modal
      title="Dine-in"
      fullWidth={false}
      showCloseButton
      showCloseText={false}
      isOpen={showDineinFeeModal}
      onClose={() => setShowDineinFeeModal(false)}
      onSubmit={() => setShowDineinFeeModal(false)} // TODO should only save when submitting?
      submitButtonLabel="Save and continue"
      style={{
        modal: { width: "550px" },
      }}
    >
      {!formState?.fees?.dineIn?.differentOnlinePricing && (
        <Box style={{ marginBottom: "30px" }}>
          <TextSelect
            onChange={(value) => {
              setDineInAmount(value);
              updateDineInAmount(value);
            }}
            onBlur={() => updateDineInAmount(dineInAmount)}
            label="Revised amount"
            items={bookingAmounts()}
            value={dineInAmount}
            disabled={isComplete}
            prefix="$"
          />
        </Box>
      )}
      {formState?.fees?.dineIn?.differentOnlinePricing && (
        <Box style={{ marginBottom: "30px" }}>
          <TextSelect
            onChange={(value) => {
              setDineInOnlineAmount(value);
              updateDineInOnlineAmount(value);
            }}
            onBlur={() => updateDineInOnlineAmount(dineInOnlineAmount)}
            label="Revised amount"
            items={bookingPercentAmounts()}
            value={dineInOnlineAmount}
            disabled={isComplete}
            suffix="%"
            minNumber={0}
            maxNumber={10}
          />
        </Box>
      )}
      <Box style={{ marginBottom: "30px" }}>
        <SegmentedControl
          onSelect={(value) => {
            // If EC Pay, dine in and takeaway values must match
            if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
              setFieldValues([
                { name: "fees.dineIn.pricingType", value },
                { name: "fees.takeawayOrders.pricingType", value },
                { name: "fees.dineIn.differentOnlinePricing", value: value === "percent" },
                { name: "fees.takeawayOrders.differentOnlinePricing", value: value === "percent" },
              ]);
            } else {
              setFieldValues([
                { name: "fees.dineIn.pricingType", value },
                { name: "fees.dineIn.differentOnlinePricing", value: value === "percent" },
              ]);
            }
          }}
          label="Pricing type"
          items={dineInPricingTypes()}
          value={formState?.fees?.dineIn?.pricingType}
          disabled={isComplete}
        />
      </Box>
      <Box style={{ marginBottom: "30px" }}>
        <SegmentedControl
          onSelect={(value) => {
            // If EC Pay, dine in and takeaway values must match
            if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
              setFieldValues([
                { name: "fees.dineIn.agreement", value },
                { name: "fees.takeawayOrders.agreement", value },
              ]);
            } else {
              setFieldValue("fees.dineIn.agreement", value);
            }
          }}
          label="Agreement"
          items={agreements()}
          value={formState?.fees?.dineIn?.agreement}
          disabled={isComplete}
        />
      </Box>
    </Modal>
  );

  const takeawayOrdersModal = (
    <Modal
      title="Takeaway"
      showCloseButton
      showCloseText={false}
      isOpen={showTakeawayOrdersModal}
      onClose={() => setShowTakeawayOrdersModal(false)}
      onSubmit={() => setShowTakeawayOrdersModal(false)} // TODO should only save when submitting?
      submitButtonLabel="Save and continue"
      fullWidth={false}
      style={{
        modal: { width: "550px" },
      }}
    >
      <Box style={{ marginBottom: "30px" }}>
        {formState?.fees?.takeawayOrders?.pricingType === "percent" ? (
          // if ec pay, match dine-in
          <Box style={{ marginBottom: "30px" }}>
            <TextSelect
              onChange={(value) => {
                setTakeawayOnlineAmount(value);
                updateTakeawayOnlineAmount(value);
              }}
              onBlur={() => updateTakeawayOnlineAmount(takeawayOnlineAmount)}
              label="Revised amount"
              items={bookingPercentAmounts()}
              value={takeawayOnlineAmount}
              disabled={isComplete}
              suffix="%"
              minNumber={0}
              maxNumber={10}
            />
          </Box>
        ) : (
          <>
            <Box style={{ marginBottom: "30px" }}>
              <TextSelect
                onChange={(value) => {
                  setTakeawayAmount(value);
                  updateTakeawayAmount(value);
                }}
                onBlur={() => updateTakeawayAmount(takeawayAmount)}
                label="Revised amount"
                items={bookingAmounts()}
                value={takeawayAmount}
                disabled={isComplete}
                prefix="$"
              />
            </Box>
            <Box style={{ marginBottom: "30px" }}>
              <Checkbox
                label="Different pricing for online orders"
                selected={formState?.fees?.takeawayOrders?.differentOnlinePricing}
                onChange={(value) => {
                  if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
                    setFieldValues([
                      { name: "fees.dineIn.differentOnlinePricing", value },
                      { name: "fees.takeawayOrders.differentOnlinePricing", value },
                    ]);
                  } else {
                    setFieldValue("fees.takeawayOrders.differentOnlinePricing", value);
                  }
                }}
                disabled={isComplete}
              />
            </Box>
            {formState?.fees?.takeawayOrders?.differentOnlinePricing && (
              <Box style={{ marginLeft: "20px", marginBottom: "30px" }}>
                <TextSelect
                  onChange={(value) => {
                    setTakeawayOnlineAmount(value);
                    updateTakeawayOnlineAmount(value);
                  }}
                  onBlur={() => {
                    updateTakeawayOnlineAmount(takeawayOnlineAmount);
                  }}
                  label="Revised amount online orders"
                  items={bookingPercentAmounts()}
                  value={takeawayOnlineAmount}
                  disabled={isComplete}
                  suffix="%"
                  minNumber={0}
                  maxNumber={10}
                />
              </Box>
            )}
          </>
        )}
      </Box>
      <Box style={{ marginBottom: "30px" }}>
        <SegmentedControl
          onSelect={(value) => {
            // If EC Pay, dine in and takeaway values must match
            if (isECPayRegion(formState?.businessInfo?.eatclubRegion)) {
              setFieldValues([
                { name: "fees.dineIn.pricingType", value },
                { name: "fees.takeawayOrders.pricingType", value },
                { name: "fees.dineIn.differentOnlinePricing", value: value === "percent" },
                {
                  name: "fees.takeawayOrders.differentOnlinePricing",
                  value: value === "percent",
                },
              ]);
            } else {
              setFieldValues([
                { name: "fees.takeawayOrders.pricingType", value },
                { name: "fees.takeawayOrders.differentOnlinePricing", value: value === "percent" },
              ]);
            }
          }}
          label="Pricing type"
          items={takeawayPricingTypes()}
          value={formState?.fees?.takeawayOrders?.pricingType}
          disabled={isComplete}
        />
      </Box>
      <Box style={{ marginBottom: "30px" }}>
        <SegmentedControl
          onSelect={(value) => setFieldValue("fees.takeawayOrders.agreement", value)}
          label="Agreement"
          items={agreements()}
          value={formState?.fees?.takeawayOrders?.agreement}
          disabled={isComplete}
        />
      </Box>
    </Modal>
  );
  const membershipModal = (
    <Modal
      title="Membership"
      showCloseButton
      showCloseText={false}
      isOpen={showMembershipModal}
      onClose={() => setShowMembershipModal(false)}
      onSubmit={() => setShowMembershipModal(false)} // TODO should only save when submitting?
      submitButtonLabel="Save and continue"
      fullWidth={false}
      style={{
        modal: { width: "550px" },
      }}
    >
      <Box style={{ marginBottom: "30px" }}>
        <TextField
          onChange={setMembershipAmount}
          fullWidth={false}
          showCloseButton
          showCloseText={false}
          onBlur={() => {
            setFieldValues([
              { name: "fees.membership.amount", value: trim(membershipAmount) },
              { name: "fees.membership.frequency", value: "monthly" },
            ]);
          }}
          label="Revised monthly fee"
          value={membershipAmount}
          maxNumber={10000}
          minNumber={0}
          type={TYPES.NUMBER}
          disabled={isComplete}
          prefix="$"
        />
      </Box>
    </Modal>
  );

  const setupModal = (
    <Modal
      title="Setup fee"
      showCloseButton
      showCloseText={false}
      isOpen={showSetupModal}
      onClose={() => setShowSetupModal(false)}
      onSubmit={() => setShowSetupModal(false)} // TODO should only save when submitting?
      submitButtonLabel="Save and continue"
      fullWidth={false}
      style={{
        modal: { width: "550px" },
      }}
    >
      <Box style={{ marginBottom: "30px" }}>
        <TextSelect
          onChange={(value) => {
            setSetupFee(value);
            setFieldValue("fees.setup.amount", value);
          }}
          onBlur={() => setFieldValue("fees.setup.amount", setupFee)}
          label="Revised amount"
          items={setupFeeAmounts()}
          value={setupFee}
          disabled={isComplete}
          prefix="$"
        />
      </Box>
    </Modal>
  );

  const orderDirectModal = (
    <Modal
      title="Order Direct - white label"
      showCloseButton
      showCloseText={false}
      isOpen={showOrderDirectModal}
      onClose={() => setShowOrderDirectModal(false)}
      onSubmit={() => setShowOrderDirectModal(false)} // TODO should only save when submitting?
      submitButtonLabel="Save and continue"
      fullWidth={false}
      style={{
        modal: { width: "550px" },
      }}
    >
      <Box style={{ marginBottom: "30px" }}>
        <TextSelect
          // TODO type as number, max as 100%
          onChange={(value) => {
            setWhiteLabelFee(value);
            setFieldValue("fees.whiteLabel.amount", value);
          }}
          onBlur={() => setFieldValue("fees.whiteLabel.amount", whiteLabelFee)}
          label="Transaction fee"
          items={whiteLabelTransactionFees()}
          value={whiteLabelFee}
          disabled={isComplete}
          suffix="%"
        />
      </Box>
    </Modal>
  );

  // TODO take a prop like "onFieldUpdate" that passes the field name and value to the parent form
  return (
    <>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Pricing</h1>
      </Box>
      <Box style={{ marginBottom: "55px" }}>
        <p className="paragraph-large">
          All fees are exclusive of GST.&nbsp;Annual fees are payable after 12 months.
        </p>
      </Box>
      {dineinModal}
      {takeawayOrdersModal}
      {orderDirectModal}
      {setupModal}
      {membershipModal}
      <Box style={{ marginBottom: "30px" }}>
        <table className="pricing-table">
          <thead>
            <tr>
              <th>Service type</th>
              <th>Agreement</th>
              <th>Fees</th>
            </tr>
          </thead>
          <tbody className="paragraph-regular">
            {!bookingsOnly && (
              <tr>
                <td aria-label="Empty">Setup</td>
                <td aria-label="Empty" />
                <td>
                  <Button type="table" onClick={() => setShowSetupModal(true)}>
                    {`${formatCurrency(formState?.fees?.setup?.amount, false)} ${getLabelForValue(
                      formState?.fees?.setup?.frequency,
                      setupPaymentFrequencies(),
                    ).toLowerCase()}`}
                  </Button>
                </td>
              </tr>
            )}
            {!bookingsOnly && (
              <tr>
                <td>Membership</td>
                <td aria-label="Empty" />
                {lockMembership ? (
                  <td>{`${formatCurrency(
                    formState?.fees?.membership?.amount,
                    false,
                  )} ${getLabelForValue("monthly", membershipFrequencies()).toLowerCase()}`}</td>
                ) : (
                  <td>
                    <Button type="table" onClick={() => setShowMembershipModal(true)}>
                      {`${formatCurrency(
                        formState?.fees?.membership?.amount,
                        false,
                      )} ${getLabelForValue("monthly", membershipFrequencies()).toLowerCase()}`}
                    </Button>
                  </td>
                )}
              </tr>
            )}
            {!bookingsOnly &&
              (formState?.services?.dineIn?.dineInVouchers ||
                formState?.services?.dineIn?.tableOrders) && (
                <tr>
                  <td>Dine-in*</td>
                  <td>
                    <Button type="table" onClick={() => setShowDineinFeeModal(true)}>
                      {getLabelForValue(formState.fees?.dineIn?.agreement, agreements())}
                    </Button>
                  </td>
                  <td>
                    {!formState?.fees?.dineIn?.differentOnlinePricing ? (
                      <Button type="table" onClick={() => setShowDineinFeeModal(true)}>
                        {getFeeDescription(
                          formState.fees.dineIn?.amount,
                          formState.fees.dineIn?.pricingType,
                        )}
                      </Button>
                    ) : (
                      <Button
                        style={{ marginTop: "5px" }}
                        type="table"
                        onClick={() => setShowDineinFeeModal(true)}
                      >
                        {`${formState.fees.dineIn?.onlineAmount}% per online order`}
                      </Button>
                    )}
                  </td>
                </tr>
              )}
            {!bookingsOnly && formState?.services?.takeaway?.takeawayVouchers && (
              <tr>
                <td>Takeaway*</td>
                <td>
                  <Button type="table" onClick={() => setShowTakeawayOrdersModal(true)}>
                    {getLabelForValue(formState.fees?.takeawayOrders?.agreement, agreements())}
                  </Button>
                </td>
                {formState?.fees?.takeawayOrders?.pricingType === "percent" ? (
                  <td>
                    {!formState?.fees?.takeawayOrders?.differentOnlinePricing ? (
                      <Button type="table" onClick={() => setShowTakeawayOrdersModal(true)}>
                        {getFeeDescription(
                          formState.fees.takeawayOrders?.amount,
                          formState.fees.takeawayOrders?.pricingType,
                        )}
                      </Button>
                    ) : (
                      <Button
                        style={{ marginTop: "5px" }}
                        type="table"
                        onClick={() => setShowTakeawayOrdersModal(true)}
                      >
                        {`${formState.fees.takeawayOrders?.onlineAmount}% per online order`}
                      </Button>
                    )}
                  </td>
                ) : (
                  <td>
                    <Button type="table" onClick={() => setShowTakeawayOrdersModal(true)}>
                      {getFeeDescription(
                        formState.fees.takeawayOrders?.amount,
                        formState.fees.takeawayOrders?.pricingType,
                      )}
                    </Button>

                    {formState?.fees?.takeawayOrders?.differentOnlinePricing && (
                      <Button
                        style={{ marginTop: "5px" }}
                        type="table"
                        onClick={() => setShowTakeawayOrdersModal(true)}
                      >
                        {`${formState.fees.takeawayOrders?.onlineAmount}% per online order`}
                      </Button>
                    )}
                  </td>
                )}
              </tr>
            )}
            {!bookingsOnly && useWhiteLabel && (
              <tr>
                <td>Order Direct - White label</td>
                <td aria-label="Empty" />
                <td>
                  <Button type="table" onClick={() => setShowOrderDirectModal(true)}>
                    {`${formState.fees.whiteLabel?.amount}% transaction fee`}
                  </Button>
                </td>
              </tr>
            )}
            {formState?.services?.bookings?.enabled && (
              <tr>
                <td>
                  EC Bookings:{" "}
                  {getLabelForValue(formState?.services?.bookings?.plan, eatclubBookingsPlans())}
                </td>
                <td aria-label="Empty" />
                <td>{getBookingsFee(formState?.services?.bookings?.plan)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Box style={{ marginBottom: "60px", maxWidth: "600px" }} className="paragraph-small">
        <Box style={{ marginBottom: "5px" }}>
          * All in-app orders are subject to a 1.75% transaction fee.
        </Box>
        <Box style={{ marginBottom: "5px" }}>
          ** All in-app orders placed below the minimum spend will not be subject to the booking fee
          but will instead be charged a flat 10% on the transaction
        </Box>
      </Box>
      <Box>
        <Box style={{ marginBottom: "25px" }}>
          <h4>Additional Notes</h4>
        </Box>
        <TextArea
          style={{ maxWidth: "700px", width: "100%" }}
          onBlur={() => setFieldValue("fees.pricingNotes", additionalNotes)}
          onChange={setAdditionalNotes}
          value={additionalNotes}
          disabled={isComplete}
        />
      </Box>
    </>
  );
};

Pricing.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

/* eslint-disable */

export const searchVenues = /* GraphQL */ `
  query SearchVenues($searchText: String, $inProgressOnly: Boolean) {
    searchVenues(searchText: $searchText, inProgressOnly: $inProgressOnly) {
      details
      objectId
      tag
      title
      type
    }
  }
`;

export const signupForId = /* GraphQL */ `
  query SignupForId($signupId: String) {
    signupForId(signupId: $signupId) {
      objectId
      restId
      businessName
      businessAddress1
      businessAddress2
      businessCity
      businessState
      businessPostcode
      businessABN
      businessDBA
      businessACN
      businessPhone
      businessWebsite
      region
      latitude
      longitude
      ownerFirstName
      ownerLastName
      ownerEmail
      ownerTitle
      ownerBirthday
      ownerAddress1
      ownerAddress2
      ownerCity
      ownerState
      ownerPostcode
      ownerHomeAddress
      ownerPhone
      bdmName
      bdmId
      restaurantType
      qtrOwner
      qtrOwnerOnly
      boardMember
      boardMemberOnly
      offlineDineIn
      offlineTakeaway
      inAppOrdering
      tableOrdering
      odTableOrdering
      odTakeaway
      odDelivery
      setupFee
      membershipFee
      dineInFee
      dineInFeeType
      dineInAgreement
      dineInOnlinePercent
      takeawayFee
      takeawayFeeType
      takeawayAgreement
      takeawayOnlinePercent
      odFee
      pricingNotes
      hours
      emailSettings {
        email
        category
      }
      typicalEta
      wifiAvailable
      tabletRequired
      pdfMenu
      menuImages
      deals {
        discount
        qty
        startDate
        timeLimited
        dineIn
        takeaway
        recurring
        recurringDays
        startTime
        endTime
      }
      odLogoLink
      odHeroLink
      odMenuImages
      odTableCount
      odPrimaryColor
      odSlug
      bankAccountName
      bankAccountNumber
      bankBSB
      loginUsername
      loginPassword
      signatureImageLink
      completionDate
      contractPdfLink
      ddBillingMethodId
      ddBillingMethodName
      ddBillingMandateLink
      ccBillingMethodId
      ccBillingMethodName
      preferredBillingMethod
      businessType
      ecBookings
      ecBookingsSlug
      ecBookingsOperationalEmail
      ccOverride
      bookingsPlatform
      bookingsPlatformExpiry
      posSystem
    }
  }
`;

export const staffForId = /* GraphQL */ `
  query StaffForId($staffId: String) {
    staffForId(staffId: $staffId) {
      Staff
    }
  }
`;

export const listBdms = /* GraphQL */ `
  query listBdms {
    listBdms {
      objectId
      name
      email
    }
  }
`;

export const partnerForEmail = /* GraphQL */ `
  query PartnerForEmail($email: String) {
    partnerForEmail(email: $email) {
      objectId
      businessName
      restId
      email
    }
  }
`;

export const getImageUploadUrl = /* GraphQL */ `
  query GetImageUploadUrl($signupId: String!, $fileName: String!) {
    getImageUploadUrl(signupId: $signupId, fileName: $fileName) {
      uploadUrl
      link
      thumbUploadUrl
      thumbLink
    }
  }
`;

export const getMenuUploadUrl = /* GraphQL */ `
  query GetMenuUploadUrl($signupId: String!, $fileName: String!) {
    getMenuUploadUrl(signupId: $signupId, fileName: $fileName) {
      uploadUrl
      link
      thumbUploadUrl
      thumbLink
    }
  }
`;

export const getPdfUploadUrl = /* GraphQL */ `
  query GetPdfUploadUrl($signupId: String!, $fileName: String!) {
    getPdfUploadUrl(signupId: $signupId, fileName: $fileName) {
      uploadUrl
      link
      thumbUploadUrl
      thumbLink
    }
  }
`;

export const getODLogoUploadUrl = /* GraphQL */ `
  query GetODLogoUploadUrl($signupId: String!, $fileName: String!) {
    getODLogoUploadUrl(signupId: $signupId, fileName: $fileName) {
      uploadUrl
      link
      thumbUploadUrl
      thumbLink
    }
  }
`;

export const getODHeroUploadUrl = /* GraphQL */ `
  query GetODHeroUploadUrl($signupId: String!, $fileName: String!) {
    getODHeroUploadUrl(signupId: $signupId, fileName: $fileName) {
      uploadUrl
      link
      thumbUploadUrl
      thumbLink
    }
  }
`;

export const getSignatureUploadUrl = /* GraphQL */ `
  query GetSignatureUploadUrl($signupId: String!, $fileName: String!) {
    getSignatureUploadUrl(signupId: $signupId, fileName: $fileName) {
      uploadUrl
      link
      thumbUploadUrl
      thumbLink
    }
  }
`;

export const lookupPaymentMethodName = /* GraphQL */ `
  query LookupPaymentMethodName($paymentMethodId: String!) {
    lookupPaymentMethodName(paymentMethodId: $paymentMethodId)
  }
`;

export const ecBookingsSlugExists = /* GraphQL */ `
  query ecBookingsSlugExists($slug: String!) {
    ecBookingsSlugExists(slug: $slug)
  }
`;

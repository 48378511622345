import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import { getLabelForValue, dealDiningOptions, dealAmounts } from "../../data/ValueLists";
import { formatTimeRange, dayRange, sortAlphabetical } from "../../helpers/Helpers";
import "./DealListStyles.css";

export const DealCard = ({ className, style, deal, onClick, allowEdit }) => (
  <Box className={className} style={style}>
    <Box
      className={`deal-list-card ${allowEdit ? "deal-list-card-clickable" : ""}`}
      onClick={onClick}
    >
      <Box className="deal-list-card-header">
        <Box className="deal-list-card-discount-pill">{deal.percentage || "0"}% off</Box>
        <Box className="deal-list-card-header-text">
          {deal?.diningOptions
            .map((option) => getLabelForValue(option, dealDiningOptions()))
            .sort(sortAlphabetical) // to keep Dine-in first
            .join(" & ")}
        </Box>
      </Box>
      <Box className="deal-list-card-body">
        <Box style={{ marginBottom: "5px" }}>
          {deal.timeType === "all_day"
            ? "All day"
            : `${formatTimeRange(deal.startTime, deal.endTime)}`}
        </Box>
        <Box style={{ marginBottom: "5px" }}>
          {deal?.repeatType === "repeat" ? `Repeats ${dayRange(deal?.repeatDays)}` : "No repeat"}
        </Box>
        <Box>
          {deal.amount === -1
            ? "No limit"
            : `${getLabelForValue(deal.amount, dealAmounts()) || "0"} deal${
                deal.amount !== 1 ? "s" : ""
              } available`}
        </Box>
      </Box>
    </Box>
  </Box>
);

DealCard.defaultProps = {
  className: "",
  style: {},
  allowEdit: false,
  deal: {},
  onClick: () => {},
};

DealCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  allowEdit: PropTypes.bool,
  deal: PropTypes.shape({}),
  onClick: PropTypes.func,
};

import { makeStyles } from "@mui/styles";
import { COLORS } from "../../theme";

const useStyles = makeStyles({
  slugContainer: {
    padding: "20px 32px",
    backgroundColor: COLORS.BACKGROUND_GRAY,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  slugTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
  },
  urlContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  slugInput: {
    maxWidth: "600px",
  },
  error: {
    color: "red",
    fontWeight: "500",
  },
});

export default useStyles;

import { Checkbox, RadioButtonGroup } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import { ServiceOption } from "../ServiceOption/ServiceOption";
import { eatclubBookingsPlans } from "../../data/ValueLists";
import useStyles from "./ServiceSelectionStyles";

export const ServiceSelection = ({
  isComplete,
  selectDineIn,
  selectTakeaway,
  selectODTakeaway,
  selectODTableOrdering,
  selectODDelivery,
  onSelectDineIn,
  onSelectTakeaway,
  onSelectODTakeaway,
  onSelectODTableOrdering,
  onSelectODDelivery,
  selectEatClubBookings,
  selectEatClubBookingsPlan,
  onSelectEatClubBookings,
  onSelectEatClubBookingsPlan,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <ServiceOption
        title="EatClub Marketplace"
        description="Create demand through dynamic pricing."
      >
        <Box>
          <Box style={{ marginBottom: "20px" }} className="paragraph-small bold">
            Features
          </Box>

          <Checkbox
            style={{ container: { marginBottom: "10px" } }}
            label="Dine-in"
            onChange={onSelectDineIn}
            selected={selectDineIn}
            disabled={isComplete}
          />

          <Checkbox
            style={{ container: { marginBottom: "10px" } }}
            label="Takeaway"
            onChange={onSelectTakeaway}
            selected={selectTakeaway}
            disabled={isComplete}
          />
        </Box>
      </ServiceOption>
      <ServiceOption title="EatClub Bookings" description="Drive bookings with digital bookings.">
        <Box>
          <Box style={{ marginBottom: "20px" }} className="paragraph-small bold">
            Features
          </Box>
          <Checkbox
            style={{ container: { marginBottom: "16px" } }}
            label="Use EatClub Bookings"
            onChange={onSelectEatClubBookings}
            selected={selectEatClubBookings}
            disabled={isComplete}
          />
          {selectEatClubBookings && (
            <RadioButtonGroup
              style={{
                // marginLeft: "20px",
                itemContainer: { gap: "4px", fontSize: "15px" },
                buttonLabel: { fontSize: "15px" },
                buttonCircle: {
                  width: "18px",
                  height: "18px",
                  maxWidth: "18px",
                  maxHeight: "18px",
                  minWidth: "18px",
                  minHeight: "18px",
                },
              }}
              items={eatclubBookingsPlans()}
              value={selectEatClubBookingsPlan}
              onSelect={onSelectEatClubBookingsPlan}
              disabled={isComplete}
            />
          )}
        </Box>
      </ServiceOption>
      <ServiceOption
        title="Order Direct"
        description="Online branded menu to generate digital orders."
      >
        <Box>
          <Box style={{ marginBottom: "20px" }} className="paragraph-small bold">
            Features
          </Box>
          <Checkbox
            style={{ container: { marginBottom: "10px" } }}
            label="QR code table ordering"
            onChange={onSelectODTableOrdering}
            selected={selectODTableOrdering}
            disabled={isComplete}
          />
          <Checkbox
            style={{ container: { marginBottom: "10px" } }}
            label="Takeaway"
            onChange={onSelectODTakeaway}
            selected={selectODTakeaway}
            disabled={isComplete}
          />
          <Checkbox
            label="Delivery (in-house)"
            onChange={onSelectODDelivery}
            selected={selectODDelivery}
            disabled={isComplete}
          />
        </Box>
      </ServiceOption>
    </Box>
  );
};

ServiceSelection.defaultProps = {
  isComplete: false,
  selectDineIn: false,
  selectTakeaway: false,
  selectODTakeaway: false,
  selectODTableOrdering: false,
  selectODDelivery: false,
  selectEatClubBookings: false,
  selectEatClubBookingsPlan: null,
  onSelectDineIn: () => {},
  onSelectTakeaway: () => {},
  onSelectODTakeaway: () => {},
  onSelectODTableOrdering: () => {},
  onSelectODDelivery: () => {},
  onSelectEatClubBookings: () => {},
  onSelectEatClubBookingsPlan: () => {},
};

ServiceSelection.propTypes = {
  isComplete: PropTypes.bool,
  selectDineIn: PropTypes.bool,
  selectTakeaway: PropTypes.bool,
  selectODTakeaway: PropTypes.bool,
  selectODTableOrdering: PropTypes.bool,
  selectODDelivery: PropTypes.bool,
  onSelectDineIn: PropTypes.func,
  onSelectTakeaway: PropTypes.func,
  onSelectODTakeaway: PropTypes.func,
  onSelectODTableOrdering: PropTypes.func,
  onSelectODDelivery: PropTypes.func,
  selectEatClubBookings: PropTypes.bool,
  selectEatClubBookingsPlan: PropTypes.string,
  onSelectEatClubBookings: PropTypes.func,
  onSelectEatClubBookingsPlan: PropTypes.func,
};

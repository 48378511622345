export const getLabelForValue = (value, list) =>
  list.find((item) => item.value === value)?.label || "";
export const getValueForLabel = (label, list) =>
  list.find((item) => item.label.toLowerCase() === label.toLowerCase())?.value || "";
export const getValues = (list) => list.map((listItem) => listItem?.value);
export const getLabels = (list) => list.map((listItem) => listItem?.label);

// Combo box lists should always just be a label and value

export const dineInPricingTypes = () => [
  { value: "booking", label: "Per booking" },
  { value: "diner", label: "Per diner" },
  { value: "percent", label: "Percentage" },
];

export const takeawayPricingTypes = () => [
  { label: "Per booking", value: "booking" },
  { label: "Per diner", value: "diner" },
  { label: "Percentage", value: "percent" },
];

export const whiteLabelPricingTypes = () => [
  { label: "Transaction fee", value: "transaction_fee" },
];

export const restaurantTypes = () => [
  { label: "Quick service", value: "qsr" },
  { label: "Sit down", value: "sit_down" },
];

export const agreements = () => [
  { label: "Exclusive", value: "exclusive" },
  { label: "Standard", value: "non_exclusive" },
];

export const predominantOrderingMethods = () => [
  { label: "Exclusive", value: "exclusive" },
  { label: "Standard", value: "standard" },
];

export const whiteLabelTransactionFees = () => [
  { label: "0", value: 0 },
  { label: "0.25", value: 0.25 },
  { label: "0.5", value: 0.5 },
  { label: "0.75", value: 0.75 },
  { label: "1", value: 1 },
  { label: "1.25", value: 1.25 },
  { label: "1.5", value: 1.5 },
  { label: "1.75", value: 1.75 },
  { label: "2", value: 2 },
  { label: "2.25", value: 2.25 },
  { label: "2.5", value: 2.5 },
  { label: "2.75", value: 2.75 },
  { label: "3", value: 3 },
  { label: "3.25", value: 3.25 },
  { label: "3.5", value: 3.5 },
  { label: "3.75", value: 3.75 },
  { label: "4", value: 4 },
  { label: "4.25", value: 4.25 },
  { label: "4.5", value: 4.5 },
  { label: "4.75", value: 4.75 },
  { label: "5", value: 5 },
];

export const setupPaymentFrequencies = () => [{ label: "One-time Only", value: "onetime" }];

export const membershipFrequencies = () => [{ label: "Monthly", value: "monthly" }];

export const membershipPaymentFrequencies = () => [
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" },
];

export const bookingAmounts = () => [
  { label: "0", value: 0 },
  { label: "0.50", value: 0.5 },
  { label: "1", value: 1 },
  { label: "1.50", value: 1.5 },
  { label: "2", value: 2 },
  { label: "2.50", value: 2.5 },
  { label: "3", value: 3 },
  { label: "3.50", value: 3.5 },
  { label: "4", value: 4 },
  { label: "4.50", value: 4.5 },
];

export const bookingPercentAmounts = () => [
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
];

export const onlineOrderAmounts = () => [
  { label: "0", value: 0 },
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
  { label: "20", value: 20 },
];

export const commissionAmounts = () => [
  { label: "0", value: 0 },
  { label: "5", value: 5 },
  { label: "10", value: 10 },
];

export const setupFeeAmounts = () => [
  { label: "0", value: 0 },
  { label: "49", value: 49 },
  { label: "99", value: 99 },
  { label: "149", value: 149 },
  { label: "199", value: 199 },
  { label: "249", value: 249 },
  { label: "299", value: 299 },
  { label: "399", value: 399 },
  { label: "499", value: 499 },
];

export const weekdays = () => [
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" },
];

export const weekdaysShort = () => [
  { value: "monday", label: "Mon" },
  { value: "tuesday", label: "Tue" },
  { value: "wednesday", label: "Wed" },
  { value: "thursday", label: "Thu" },
  { value: "friday", label: "Fri" },
  { value: "saturday", label: "Sat" },
  { value: "sunday", label: "Sun" },
];

export const onOff = () => [
  { label: "On", value: true },
  { label: "Off", value: false },
];

export const yesNo = () => [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const preparationTimes = () => [
  { label: "10 mins", value: 10 },
  { label: "20 mins", value: 20 },
  { label: "30 mins", value: 30 },
  { label: "40 mins", value: 40 },
  { label: "1 hr", value: 60 },
];

export const regions = () => [
  { label: "Queensland", value: "QLD" },
  { label: "New South Wales", value: "NSW" },
  { label: "Victoria", value: "VIC" },
  // { label: "Tasmania", value: "TAS" },
  { label: "Australian Capital Territory", value: "ACT" },
  // { label: "Jervis Bay Territory", value: "JBT" },
  { label: "South Australia", value: "SA" },
  { label: "Western Australia", value: "WA" },
  // { label: "Northern Territory", value: "NT" },
];

export const australianStates = [
  { label: "Queensland", value: "QLD" },
  { label: "New South Wales", value: "NSW" },
  { label: "Victoria", value: "VIC" },
  { label: "Tasmania", value: "TAS" },
  { label: "Australian Capital Territory", value: "ACT" },
  { label: "South Australia", value: "SA" },
  { label: "Western Australia", value: "WA" },
  { label: "Northern Territory", value: "NT" },
];

/*
  A region that users can select inside the app
 */
export const eatclubRegions = () => [
  { label: "Melbourne", value: "Melbourne" },
  { label: "Sydney", value: "Sydney" },
  { label: "Adelaide", value: "Adelaide" },
  { label: "Brisbane", value: "Brisbane" },
  { label: "Gold Coast", value: "Gold Coast" },
  { label: "Sunshine Coast", value: "Sunshine Coast" },
  { label: "Perth", value: "Perth" },
  { label: "Canberra", value: "Canberra" },
];

export const eatclubRegionsByState = () => ({
  QLD: [
    { label: "Brisbane", value: "Brisbane" },
    { label: "Gold Coast", value: "Gold Coast" },
    { label: "Sunshine Coast", value: "Sunshine Coast" },
  ],
  NSW: [{ label: "Sydney", value: "Sydney" }],
  SA: [{ label: "Adelaide", value: "Adelaide" }],
  VIC: [{ label: "Melbourne", value: "Melbourne" }],
  WA: [{ label: "Perth", value: "Perth" }],
  ACT: [{ label: "Canberra", value: "Canberra" }],
});

/**
 * Almost every region is ecpay now
 * @returns {string[]}
 */
export const ecPayRegions = () => [
  "Melbourne",
  "Sydney",
  "Adelaide",
  "Brisbane",
  // "Gold Coast",
  // "Sunshine Coast",
  "Perth",
  "Canberra",
];

export const eatClubRegionsForState = (state) => eatclubRegionsByState()?.[state] ?? state;

export const dealPercentages = () => [
  // { label: "60%", value: 60 },
  // { label: "55%", value: 55 },
  { label: "50%", value: 50 },
  { label: "45%", value: 45 },
  { label: "40%", value: 40 },
  { label: "35%", value: 35 },
  { label: "30%", value: 30 },
  { label: "25%", value: 25 },
  { label: "20%", value: 20 },
  { label: "15%", value: 15 },
  { label: "10%", value: 10 },
];

export const dealAmounts = () => [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "No limit", value: -1 },
];

export const dealTimeOptions = () => [
  { label: "All day", value: "all_day" },
  { label: "Time limited", value: "time_limited" },
];

export const dealRepeatOptions = () => [
  { label: "One-time", value: "onetime" },
  { label: "Repeat", value: "repeat" },
];

export const dealDiningOptions = () => [
  { label: "Dine-in", value: "dine_in" },
  { label: "Takeaway", value: "takeaway" },
];

export const signupTypes = () => [
  { label: "Hubspot", value: "hubspot" },
  { label: "EatClub venue", value: "ec_venue" },
  { label: "Signup in progress", value: "signup_in_progress" },
  { label: "Signup completed", value: "signup_completed" },
];

export const eatclubBookingsPlans = () => [
  { label: "Lite plan", value: "lite" },
  { label: "Premium plan", value: "premium" },
  { label: "Bookings only", value: "bookingsOnly" },
];

export const posSystems = [
  { label: "None", value: "none" },
  { label: "Abacus", value: "Abacus" },
  { label: "Lightspeed", value: "Lightspeed" },
  { label: "Square", value: "Square" },
  { label: "Bepoz", value: "Bepoz" },
  { label: "Eposnow", value: "Eposnow" },
  { label: "Fedelta", value: "Fedelta" },
  { label: "Finchcorp", value: "Finchcorp" },
  { label: "GaP Solutions", value: "GaP Solutions" },
  { label: "H&L", value: "H&L" },
  { label: "Idealpos", value: "Idealpos" },
  { label: "Impos", value: "Impos" },
  { label: "iPOS", value: "iPOS" },
  { label: "ivida", value: "ivida" },
  { label: "Lotuspos", value: "Lotuspos" },
  { label: "Micros Res", value: "Micros Res" },
  { label: "Micros Simphony", value: "Micros Simphony" },
  { label: "NCR Aloha", value: "NCR Aloha" },
  { label: "Oolio", value: "Oolio" },
  { label: "OrderMate", value: "OrderMate" },
  { label: "Raptor", value: "Raptor" },
  { label: "SplitAbility", value: "SplitAbility" },
  { label: "SwiftPOS", value: "SwiftPOS" },
  { label: "Starrtec", value: "Starrtec" },
  { label: "Triniteq", value: "Triniteq" },
  { label: "Uniwell", value: "Uniwell" },
  { label: "Other", value: "Other" },
];

export const bookingPlatforms = [
  { label: "None", value: "none" },
  { label: "EatClub Bookings", value: "EatClub Bookings" },
  { label: "Obee", value: "Obee" },
  { label: "SevenRooms", value: "SevenRooms" },
  { label: "NowBookIt", value: "NowBookIt" },
  { label: "OpenTable", value: "OpenTable" },
  { label: "Other", value: "Other" },
];

import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import { formStateType } from "../../../types";
import { OpeningHours } from "../../OpeningHours/OpeningHours";

export const OpeningHoursPage = ({ formState, setFieldValue, setFieldValues, isComplete }) => {
  const { openingHours } = formState;

  return (
    <>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Opening hours</h1>
      </Box>
      <Box style={{ marginBottom: "50px" }}>
        <p className="paragraph-large">Select opening hours for the week</p>
      </Box>
      <Box>
        <OpeningHours
          formState={formState}
          setFieldValue={setFieldValue}
          setFieldValues={setFieldValues}
          style={{ maxWidth: "580px" }}
          value={openingHours}
          disabled={isComplete}
        />
      </Box>
    </>
  );
};

OpeningHoursPage.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

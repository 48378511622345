import { Button } from "@eatclub-apps/ec-component-library";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  fetchRestaurantsByName,
  overrideCCForSignupAction,
  SEARCH_RESULT_TYPE,
} from "../graphql/api-client";
import { isSpecialBuddy, isEmpty } from "../helpers/Helpers";
import { RestaurantSelector } from "../components/RestaurantSelector/RestaurantSelector";

export const CreditCardOverride = () => {
  const [fetchedRestaurants, setFetchedRestaurants] = useState([]);
  const [loadingRestaurants, setLoadingRestaurants] = useState(false);
  const [selectedSignupId, setSelectedSignupId] = useState(null);
  const [overridden, setOverridden] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  const setPartner = (partner) => {
    setSelectedSignupId(partner.objectId);
  };

  const onType = (value) => {
    setLoadingRestaurants(true);
    setSelectedSignupId(null);
    setOverridden(false);

    (async () => {
      try {
        const { data: restaurants, error: responseError } = await fetchRestaurantsByName(
          value,
          true,
        );

        if (responseError) {
          setLoadingRestaurants(false);
          setError(responseError);
          return;
        }

        setFetchedRestaurants(
          restaurants.filter((restaurant) => restaurant.type === SEARCH_RESULT_TYPE.IN_PROGRESS),
        );
        setLoadingRestaurants(false);
      } catch (e) {
        setLoadingRestaurants(false);
        setError(e);
      }
    })();
  };

  if (!isSpecialBuddy()) {
    return <Box onClick={() => history.push("/")}>Page not found. Click here to go back</Box>;
  }

  return (
    <Box style={{ maxWidth: "800px", margin: "80px" }}>
      <Button type="text" onClick={() => history.push("/")} style={{ marginBottom: "30px" }}>
        Back
      </Button>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Credit card override</h1>
      </Box>
      <Box style={{ marginBottom: "55px" }}>
        <p className="paragraph-large">
          Please find the restaurant you wish to override the credit card payments for.
        </p>
      </Box>

      {error && (
        <Box style={{ marginBottom: "10px" }} className="error-text-medium">
          {error}
        </Box>
      )}

      <RestaurantSelector
        items={fetchedRestaurants}
        label="Partner name or ID"
        placeholder="Search existing partner name or ID"
        style={{ width: "100%", maxWidth: "480px", marginBottom: "32px" }}
        showCreatePartner={false}
        searchRestaurants={onType}
        onSelect={setPartner}
        // defaultValue={selectedRestaurant?.title}
        loading={loadingRestaurants}
      />

      <Box>
        {overridden ? (
          <Box>Done</Box>
        ) : (
          <Button
            text="Override credit card for this venue"
            type="secondary"
            disabled={isEmpty(selectedSignupId)}
            onClick={() => {
              overrideCCForSignupAction(selectedSignupId);

              // Quick and dirty way to show feedback after posting
              setOverridden(true);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

CreditCardOverride.propTypes = {};

export const httpGet = (url) => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", url, false); // false for synchronous request
  xmlHttp.send(null);
  return xmlHttp.responseText;
};

export const httpPost = (url) => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open("POST", url, false); // false for synchronous request
  xmlHttp.send(null);
  return xmlHttp.responseText;
};

export const decodeBase64Image = (imageData) => {
  const block = imageData.split(";");
  const contentType = block[0].split(":")[1];
  const realData = block[1].split(",")[1];
  const blob = b64toBlob(realData, contentType);

  return blob;
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

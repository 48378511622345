import { ColorPicker, TextField } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { formStateType } from "../../../types";
import { TYPES } from "../../../helpers/InputTypes";
import { trim } from "../../../helpers/Helpers";

export const WhiteLabelSetupPage = ({ formState, setFieldValue }) => {
  const [tableAmount, setTableAmount] = useState(formState.orderDirect.tableAmount);
  const [slug, setSlug] = useState(formState.orderDirect.slug);
  const [brandColor, setBrandColor] = useState(formState.orderDirect.brandColor);

  return (
    <Box style={{ maxWidth: "800px", width: "100%" }}>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Set up Order Direct - White Label</h1>
      </Box>
      <Box style={{ marginBottom: "55px" }}>
        <p className="paragraph-large">Design your digital menu experience</p>
      </Box>
      {formState.services.whiteLabel.tableOrdering && (
        <Box style={{ marginBottom: "60px" }}>
          <Box style={{ marginBottom: "20px" }}>
            <h3 className="brand-primary">How it works</h3>
          </Box>
          <Box style={{ marginBottom: "10px" }}>1. Every table is set up with QR codes</Box>
          <Box style={{ marginBottom: "10px" }}>
            2. All customers are informed to order via QR codes
          </Box>
          <Box style={{ marginBottom: "10px" }}>3. Staff are trained in QR code system</Box>
        </Box>
      )}
      <Box>
        <Box style={{ marginBottom: "20px" }}>
          <h3>Design</h3>
        </Box>
        {/*<Box className="two-column" style={{marginBottom: "30px"}}>*/}
        {/*  <FileUpload*/}
        {/*    label="Upload your logo"*/}
        {/*    onChange={(value) => setFieldValue("orderDirect.logo", value)}*/}
        {/*  />*/}
        {/*  <FileUpload*/}
        {/*    label="Upload hero image"*/}
        {/*    onChange={(value) => setFieldValue("orderDirect.heroImage", value)}*/}
        {/*  />*/}
        {/*</Box>*/}
        {/* <Box style={{marginBottom: "10px", maxWidth: "480px" }}> */}
        {/*   <TextField */}
        {/*     type={TYPES.URL} */}
        {/*     label="Link to menu images" */}
        {/*     placeholder="Dropbox, Google Drive, or other" */}
        {/*     onChange={(value) => */}
        {/*       setFieldValue("operations.menuImageLink", value) */}
        {/*     } */}
        {/*   /> */}
        {/* </Box> */}
        {formState.services.whiteLabel.tableOrdering && (
          <Box style={{ marginBottom: "20px", maxWidth: "480px" }}>
            <TextField
              type={TYPES.NUMBER}
              label="Number of tables that need QR codes"
              placeholder="e.g. 10"
              minNumber={0}
              value={tableAmount}
              onBlur={() => setFieldValue("orderDirect.tableAmount", trim(tableAmount))}
              onChange={setTableAmount}
            />
          </Box>
        )}
        <Box style={{ marginBottom: "20px", maxWidth: "480px" }}>
          <TextField
            type={TYPES.TEXT}
            label="URL slug"
            tooltip="The part of the Order Direct URL that links to this restaurant"
            placeholder="e.g. cams-cuisine"
            value={slug}
            onBlur={() => setFieldValue("orderDirect.slug", trim(slug))}
            onChange={setSlug}
          />
        </Box>
        <Box style={{ marginBottom: "20px", maxWidth: "480px" }}>
          <ColorPicker
            label="Select primary colour"
            placeholder="#F2F2F2"
            value={brandColor}
            onBlur={() => setFieldValue("orderDirect.brandColor", brandColor)}
            onChange={setBrandColor}
          />
        </Box>
      </Box>
    </Box>
  );
};

WhiteLabelSetupPage.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";

export const LabelledData = (props) => {
  const { label, value } = props;

  return (
    <Box className="labelled-data">
      <Box className="finalise-label">{label}</Box>
      <Box className="finalise-value">{value || "Not set"}</Box>
    </Box>
  );
};

LabelledData.defaultProps = {
  label: "",
  value: "",
};

LabelledData.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
};

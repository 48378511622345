import AppWithAuth from "./auth/AppWithAuth";
import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes/Routes";

function App() {
  return (
    <div className="App">
      <Router>
        <AppWithAuth>
          <Routes />
        </AppWithAuth>
      </Router>
    </div>
  );
}

export default App;

import React from "react";
import { Box } from "@mui/material";
import "../App.css";
import "./TermsConditions.css";

export const TermsConditions = () => {
  const styles = {
    bold: {
      fontWeight: "bold",
    },
  };

  return (
    <Box className="terms-conditions">
      <h1>Terms & Conditions</h1>
      <br />
      <p>
        These Terms, together with the Contract Details and the Glossary, form the Agreement under
        which EatClub provides its Services to the Restaurant on its Platform.
      </p>
      <br />

      {/* 1. EatClub's Obligations */}
      <p style={styles.bold}> 1. EatClub&apos;s Obligations</p>
      <br />
      <p style={styles.bold}> In providing the Services, EatClub will:</p>
      <p> i. list the Restaurant on the EatClub Platform;</p>
      <p> ii. provide the Restaurant access to dynamic pricing and deal making software;</p>
      <p>
        iii. attract Bookings from customers and pass the Booking details on to the Restaurant via
        the partner app notification, email or call.
      </p>
      <p>
        iv. receive and process Online Orders from Customers through the EatClub Platform and
        forward the orders to the Restaurant;
      </p>
      <p>
        v. notify the Customer that the Order can be picked-up or consumed on restaurant premises.
      </p>
      <br />

      {/* 2. Restaurant's Obligations */}
      <p style={styles.bold}> 2. Restaurant&apos;s Obligations</p>
      <br />
      <p style={styles.bold}> a) The Restaurant will:</p>
      <p>
        i. keep its EatClub profile up to date with the correct opening hours, menu information,
        images and contact information;
      </p>
      <p>
        ii. provide dine-in customers of EatClub a dining experience similar to that of a normal
        Restaurant Customer;
      </p>
      <p>
        iii. ensure they have a working EFTPOS machine at all times to receive payment from the
        Customer with the EatClub Card;
      </p>
      <p>
        iv. not exclude any full priced menu items, including drinks from their menu. Items that are
        already discounted, such as items on happy hour, two for one deals or daily specials, can be
        excluded from the Discount if communicated clearly in advance to the Customer.
      </p>
      <p>
        v. when receiving an Online Order, the Restaurant will either receive or provide an
        estimated preparation time for each Online Order. The Restaurant must endeavor to prepare
        and fulfil such Online Order within the specified timeframe;
      </p>
      <p> vi. use suitable packaging for any takeaway Online Orders;</p>
      <p>
        vii. inform EatClub immediately if the Restaurant is not able to prepare the Order within
        the indicated timeframe, is temporarily unable to receive, prepare and/or fulfil orders, and
        provide EatClub with an explanation as to why and a timeframe for re- commencement of such
        Services;
      </p>
      <p> viii. notify EatClub of any delays or cancellations of an Online Order;</p>
      <p>
        ix. be solely responsible and liable for any and all Customer queries, claims and/or
        complaints in respect of the Products; and
      </p>
      <p>
        x. during the Term and any Subsequent Term, obtain and maintain adequate insurance at all
        times to cover any potential liability that it may incur under this Agreement.
      </p>
      <p>
        xi. Take full responsibility for the timely removal of their deals, or request for EatClub
        to remove their deals, at times when they can not receive customers (e.g. unexpected
        closures, being fully booked). In cases where a deal is redeemed before deals are removed,
        the restaurant is expected to honour this Booking / Online Order at all times. When the
        venue is closed, EatClub may charge a Service Fee to cover compensation for the customer.
      </p>
      <br />
      <p style={styles.bold}> b) The Restaurant acknowledges that:</p>
      <p>
        i. no contract exists, or at any time will exist, between EatClub and the Customer for the
        supply and service of your Products;
      </p>
      <p>
        ii. upon confirmation by the Customer and processing of the Customer&apos;s Order or Booking
        by EatClub, a Customer Contract is formed between the Restaurant and the Customer. The
        Restaurant will inform EatClub of any special terms and conditions that form part of this
        Customer Contract;
      </p>
      <p>
        iii. From time to time, EatClub may also offer discounts, in any form, to encourage
        Customers to use its Platform. The cost of such discounts will be incurred by EatClub and
        the Restaurant will not be subject to an increase in the Service Fee payable or a reduction
        in the Remitted Fees received by it as a result of EatClub offering such discounts to
        Customers;
      </p>
      <p>
        iv. the sole and exclusive obligation to supply Orders to the Customer is owed by the
        Restaurant arising under the Customer Contract and EatClub has no responsibility for the
        fulfilment of Orders;
      </p>
      <p>
        v. the sole and exclusive obligation to supply Orders to the Customer is owed by the
        Restaurant arising under the Customer Contract and EatClub has no responsibility for the
        fulfilment of Orders;
      </p>
      <p>
        vi. the Restaurant is solely responsible for determining the availability and supply of
        Products in accordance with the Order; and
      </p>
      <p>
        vii. any Tech Equipment provided by EatClub to the Restaurant in order to perform its
        obligations or perform its Services under this Agreement remains the property of EatClub at
        all times.
      </p>
      <br />

      {/* 3. Fees, Float Amount, Tablet Return and Payment */}
      <p style={styles.bold}> 3. Fees, Float Amount, Tablet Return and Payment</p>
      <br />
      <p>
        a) The Order Price and Menu provided by the Restaurant to EatClub cannot be less favorable
        than the prices the Restaurant would charge if the same Products were ordered online at the
        Restaurant&apos;s website, served in store for dine in or take-away without the use of the
        EatClub app, by telephone, or by any other means.
      </p>
      <p>
        b) EatClub will charge the Set-up Fee, if applicable, by Direct Debit on the Commencement
        Date from the nominated account. If such payment is not successful for any reason, EatClub
        will deduct this amount from the funds remitted to the Restaurant in accordance with this
        clause 3.
      </p>
      <p>
        c) We may charge a pre-authorisation charge of $1, which is a temporary hold to validate any
        credit card details provided.
      </p>
      <p>
        d) We reserve the right to disable the Restaurants access to the platform in the event of
        any nonpayment.
      </p>
      <p>
        {" "}
        e) In case of an Online Order, EatClub will collect the Fees from the Customers payment;
      </p>
      <p>
        {" "}
        f) In case of a walk-in, the Customer will pay the full priced bill (i.e. the amount before
        the deal offered) at the Restaurant with their EatClub Card. The Restaurant will owe EatClub
        the Float Amount and Service Fee for this walk-in, which is settled on a weekly basis in the
        Settlement.
      </p>
      <p> g) Payments / Payouts:</p>
      <p>
        EatClub will send out a weekly Settlement to the Restaurant detailing all Online Orders,
        walk-ins, earnings, Service Fees, Membership Fees and Sign up Fees when applicable for the
        previous period (Monday to Sunday). EatClub will either collect Payment for any outstanding
        balance to EatClub via the selected payment method (Credit Card or Direct Debit) or Payout
        the Restaurant for any balance owed to the Restaurant.
      </p>
      <p> h) Tablet Return:</p>
      <p>
        In the event the Services are terminated, the venue is to return all tech equipment (tablet
        and charging components) by mail to EatClub within 14 days to the address specified by the
        EatClub representative. Failure to return will result in a tablet fee charge of $200.
      </p>
      <p> i) Monthly Membership Fee:</p>
      <p>
        Monthly subscription fees for the Standard and Branded plans are via direct debit or credit
        card payment only. Your free period will begin after commencement of this agreement and will
        end 7 days thereafter. From there, your Membership will commence and the Membership Fees
        will be charged in advance on a monthly cycle. These fees will be included on your weekly
        settlement.
      </p>
      <br />

      {/* 4. Changes, Cancellations, No-Shows and Refunds */}
      <p style={styles.bold}> 4. Changes, Cancellations, No-Shows and Refunds</p>
      <br />
      <p>
        a) For Online Ordering, the Restaurant will be able to indicate through the tablet when
        items are out of stock. If any changes are made to an Order after payment of an Order has
        been processed on the Platform, such as when Products become unavailable without these
        Products being turned off in the app, the Customer will be refunded by EatClub for the price
        difference if this is less than the initial Order. If the Customer has chosen an Alternate
        product from the same category of which the price is slightly higher, the Restaurant will
        cover this difference.
      </p>
      <p>
        b) If EatClub is required to refund any amount to a Customer, such as when there is an issue
        with an Online Order, the Restaurant acknowledges that the Gross Food Value for the Problem
        Order will be deducted from the amount payable to the Restaurant from EatClub for the
        relevant Payment Period, and the Restaurant will not be paid any amount for this Problem
        Order; or
      </p>
      <p>
        c) If EatClub has already remitted payment to the Restaurant for the Gross Food Value for a
        Problem Order, the Restaurant must reimburse EatClub for the Gross Food Value upon demand.
        Alternatively, EatClub may offset any amount owed by the Restaurant to EatClub in respect of
        a Problem Order under this clause against any amounts payable to the Restaurant from EatClub
        for the next Payment Period, provided EatClub gives notice to the Restaurant of the off-set
        in writing.
      </p>
      <p>
        d) In cases where a Customer visits the Restaurant at the time of their Booking or Online
        Order, but the venue is closed, EatClub reserves the right to charge a $5 Service Fee for
        the customer to cover the cost of compensation to the Customer.
      </p>
      <br />

      {/* 5. EatClub Bookings Terms & Conditions */}
      <p style={styles.bold}> 5. EatClub Bookings Terms & Conditions</p>
      <br />
      <p style={styles.bold}> a) Pricing Lite:</p>
      <p>
        EatClub Bookings Lite is provided for an additional $10/month on top of your EatClub
        membership while you are active on the EatClub platform.
      </p>
      <p style={styles.bold}> b) Pricing Premium:</p>
      <p>
        EatClub Bookings Premium is provided for an additional $50/month on top of your EatClub
        membership while you are active on the EatClub platform.
      </p>
      <p style={styles.bold}> c) Payments:</p>
      <p>
        Monthly subscription fees for the Lite and Premium plans are via direct debit or credit card
        payment only. Your billing period will start 7 days after you have been officially signed up
        to EatClub Bookings and can access the EatClub Bookings platform. Your Membership fees, as
        agreed to in the form, will be charged in advance on a monthly cycle.
      </p>
      <p style={styles.bold}> d) Inactive Account:</p>
      <p>
        If at any point while using the EatClub Bookings platform, you become inactive, both EatClub
        Bookings Lite and Premium will be charged at $149/month as per EatClub’s standard pricing
        policy. If you are paying $149/month, you are entitled to use the premium version of the
        Bookings platform if requested. Active means at least 3 deals per week posted onto the
        EatClub platform.
      </p>
      <p style={styles.bold}> e) Tablet:</p>
      <p>
        EatClub provides a tablet for use to process orders and Bookings from the EatClub
        Marketplace. This can be used to manage EatClub Bookings, However, any additional tablets
        required can be purchased at your own expense.
      </p>
      <p style={styles.bold}> f) Cancellation:</p>
      <p>
        There are no contract lock-ins. You must submit a cancellation request to your account
        manager before the start of a billing cycle to avoid paying your monthly membership fee for
        the month ahead.
      </p>
      <p style={styles.bold}> g) Customer Data:</p>
      <p>EatClub Bookings data will not be used for marketing in the wider EatClub ecosystem.</p>
      <p style={styles.bold}> h) Migration of data:</p>
      <p>
        Should you, in the event of canceling your EatClub Bookings membership, require an export of
        Bookings data to migrate to a new platform, a once off fee of $99 will apply. All exports
        will be provided in a .csv file and within 7 days of the request.
      </p>
      <br />
      <p>
        Full service agreement for EatClub Bookings:{" "}
        <a href="https://eccdn.com.au/terms/EC-SAAS-AGREEMENT.pdf" target="_blank" rel="noreferrer">
          https://eccdn.com.au/terms/EC-SAAS-AGREEMENT.pdf
        </a>
      </p>
      <br />

      {/* 6. Warranties and Indemnity */}
      <p style={styles.bold}> 6. Warranties and Indemnity</p>
      <br />
      <p>
        a) EatClub represents, warrants and undertakes that it will provide the Services with
        reasonable care and skill.
      </p>
      <p> b) The Restaurant represents, warrants and undertakes that:</p>
      <p> i. it will perform the Restaurant Services with all due care, skill and diligence.</p>
      <p>
        ii. all Products will be of excellent quality, prepared to best industry standard and
        compliant with all Applicable Laws and Regulations (including, without limitation, in
        respect of food safety, packaging and handling, and any food and beverage and/or health and
        safety legislation and/or regulations);
      </p>
      <p>
        iii. the premises in which the Products are produced will be compliant with all Applicable
        Laws and Regulations (including, without limitation, any food and beverage and/or health and
        safety legislation and/or regulations);
      </p>
      <p>
        iv. it has all necessary licenses, permissions and consents which may be required in order
        to perform the Restaurant Services and its obligations under this Agreement; and
      </p>
      <p>
        v. any of the marketing or promotional material that is provided by the Restaurant to
        EatClub for use on its Platform is in compliance with all Applicable Laws and Regulations
        relating to marketing and promotions, including the Competition and Consumer Act 2010 (Cth);
        and
      </p>
      <p>
        vi. if the Restaurant ceases business, closes operations for a material period or is
        otherwise unable to offer Products to Customers or to satisfy any obligation to Customers
        under the Customer Contract, the Restaurant will immediately inform EatClub.
      </p>
      <p>
        c) The Restaurant will indemnify EatClub, its affiliates, employees and agents against any
        loss suffered by EatClub arising from the actions or omissions of the Restaurant in
        connection with this Agreement including, without limitation, any breach by the Restaurant
        of the warranties in this clause 5.
      </p>
      <p>
        d) EatClub does not guarantee or warrant that the Services or its Platform, including any
        portals, software, hardware or other Services will be free from defects or malfunctions. If
        errors occur, EatClub will use its best endeavors to resolve these as quickly as possible
        and will have no liability with respect to any such errors, defects or malfunctions
        occurring under this clause.
      </p>
      <br />

      {/* 7. Marketing materials and intellectual property */}
      <p style={styles.bold}> 7. Marketing materials and intellectual property</p>
      <br />
      <p>
        a) The Restaurant agrees that the name of the Restaurant, the address, a brief slogan and
        one or more photos of the Restaurant may be published online by EatClub on the Platform, so
        that the Restaurant can be identified as a EatClub partner restaurant on the Platform.
      </p>
      <p>
        b) The Restaurant permits EatClub, at its cost, to advertise the Restaurant on its Platform,
        on Facebook, Google and any other relevant offline and online marketing channels.
      </p>
      <p>
        c) All intellectual property rights in such marketing materials and otherwise arising out of
        the Services shall be the exclusive property of EatClub, however both parties agree that
        this Agreement does not affect the ownership of intellectual property rights owned or
        created by either party, or a third party, prior to the Commencement Date or for purposes
        that are otherwise separate to this Agreement.
      </p>
      <p>
        d) Each party grants the other a non-exclusive, royalty-free, irrevocable license to use,
        copy, modify, reproduce and sub-license any intellectual property owned or provided by
        either party under this clause for the purposes of this Agreement.
      </p>
      <br />
      <p> e) In granting any license under this clause, the Restaurant warrants and agrees:</p>
      <p>
        i. that it has all rights and necessary consents and licenses for the purposes of this
        Agreement and that no third-party intellectual property rights are or will be infringed by
        either party in the provision of the Services or Restaurant Services;
      </p>
      <p>
        ii. if infringement occurs, it will as soon as reasonably practicable either procure the
        right to continue to use the infringing item, or replace or modify the infringing item so
        that the infringement is no longer occurring;
      </p>
      <p> and</p>
      <p>
        iii. it will be liable for and will indemnify EatClub for any loss, damage, or claim,
        including legal costs, arising from, or in connection with, an infringement of any
        person&apos;s rights under this clause.
      </p>
      <br />

      {/* 8. Term and Termination */}
      <p style={styles.bold}> 8. Term and Termination</p>
      <br />
      <p>
        a) This Agreement will commence on the Commencement Date and will continue unless terminated
        by either party following written notice.
      </p>
      <p> b) EatClub may terminate this Agreement without notice at any time and for any reason.</p>
      <p>c) The Restaurant may terminate this Agreement with written notice of at least 30 days.</p>
      <p>
        d) Either party may terminate this Agreement with immediate effect by notice in writing to
        the other party if the other party commits a material breach of this Agreement not capable
        of remedy, or in the case of a material breach capable of remedy, is not remedied within 5
        Business Days after written notice is given to the breaching party, specifying the breach.
      </p>
      <p> e) Upon termination by either party of this Agreement:</p>
      <p>
        i. the Restaurant is obliged to return all EatClub property in its possession immediately to
        EatClub;
      </p>
      <p>
        ii. the accrued rights, remedies, obligations and liabilities of the parties as at expiry or
        termination shall be unaffected, including the right to claim damages in respect of any
        breach of this Agreement which existed at or before the date of termination or expiry;
      </p>
      <p>
        iii. clauses which expressly or by implication survive termination shall continue in full
        force and effect.
      </p>
      <p>
        f) When service delivery ceases at the request of either party, the supplied tablet must be
        returned to EatClub. Failure to return the Tablet within 30 days in good working order may
        incur a tablet charge back fee of $200.
      </p>
      <br />

      {/* 9. Confidentiality */}
      <p style={styles.bold}> 9. Confidentiality</p>
      <br />
      <p>
        a) Each party, its employees, agents or subcontractors agrees to keep in strict confidence
        all Confidential Information of the other party which they may obtain under this Agreement.
        Disclosure by either party will only be made where it is needed for the purpose of
        discharging an obligation under this Agreement or where required by law, any governmental or
        regulatory authority or by a court of competent jurisdiction.
      </p>
      <p> b) This clause 9 shall survive termination of this Agreement.</p>
      <br />

      {/* 10. Limitation of Liability */}
      <p style={styles.bold}> 10. Limitation of Liability</p>
      <br />
      <p> a) Except for liability in relation to breach of any Non-Excludable Condition:</p>
      <p>
        i. EatClub will under no circumstances whatsoever be liable to the Restaurant, whether in
        contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss
        of profit, or any indirect or consequential loss arising under or in connection with this
        Agreement; and
      </p>
      <p>
        ii. EatClub&apos;s total liability to the Restaurant in respect of all other losses arising
        under or in connection with this Agreement, whether in contract, tort (including
        negligence), breach of statutory duty, or otherwise, shall in no circumstances exceed the
        Service Fees paid in the preceding Payment Period at the time of the event giving rise to
        such liability.
      </p>
      <p>
        b) EatClub&apos;s total liability to the Restaurant for a breach of any Non-Excludable
        Condition (other than a Non-Excludable Condition that cannot be limited by law) is limited,
        at EatClub&apos;s option, to any one of resupplying, replacing or repairing, or paying the
        cost of resupplying, replacing or repairing the services in respect of which the breach
        occurred, or supplying again or paying the cost of supplying again, the services in respect
        of which the breach occurred.
      </p>
      <p> c) This clause 10 shall survive termination of this Agreement.</p>
      <br />

      {/* Glossary */}
      <p style={styles.bold}>Glossary</p>
      <br />
      <p>
        &quot;Agreement&quot; means the Contract Details, Glossary and Terms & Conditions that form
        this agreement between EatClub and the Restaurant.
      </p>
      <br />
      <p>
        &quot;Applicable Laws and Regulations&quot; means all laws, statutes, regulations, edicts,
        by-laws, mandatory codes of conduct, standards and guidelines, whether local, national,
        international or otherwise existing from time to time to which that party is subject and
        which is relevant to that party&apos;s rights or obligations, including, without limitation,
        the Australia New Zealand Food Standards Code (including any revision or update of this Code
        that may be introduced from time to time).
      </p>
      <br />
      <p>
        &quot;Booking&quot; means a walk-in that is placed by a Customer and passed on to the
        Restaurant by EatClub to dine-in at the restaurant and paid with the EatClub Card.
      </p>
      <br />
      <p>
        &quot;Commencement Date&quot; means the date of signature of this Agreement by both parties.
      </p>
      <br />
      <p>
        &quot;Competitor&quot; means a competing business that provides services that are similar to
        those of EatClub.
      </p>
      <br />
      <p>
        &quot;Confidential Information&quot; means all technical or commercial know-how,
        specifications, inventions, processes or initiatives which are of a confidential nature, and
        includes the Terms & Conditions of this Agreement.
      </p>
      <br />
      <p>
        &quot;Contract Details&quot; means the details of the contract that are included in the
        first two pages of this Agreement.
      </p>
      <br />
      <p>
        &quot;Customer&quot; means a person who places a Booking or Online Order with a
        participating Restaurant using the EatClub Platform.
      </p>
      <br />
      <p>
        &quot;Customer Contract&quot; means the contract for the supply of an Order that is formed
        directly between the Restaurant and Customer, and is subject to any general terms and
        conditions of the Restaurant.
      </p>
      <br />
      <p>
        &quot;Discount&quot; means any discount that is offered to a Customer by a Restaurant (in
        case of a Booking, can include an additional 10% discount offered by EatClub).
      </p>
      <br />
      <p> &quot;EatClub&quot; means EatClub Australia Pty Ltd ACN 610 093 297.</p>
      <br />
      <p>
        &quot;EatClub Card&quot; means a digital prepaid card funded by EatClub, used by the
        Customer to pay the Restaurant bill in full at the Restaurant.
      </p>
      <br />
      <p> &quot;Fees&quot; means the Order Price, less any applicable Discount.</p>
      <br />
      <p>
        &quot;Float Amount&quot; means the dollar amount temporarily provided by EatClub to the
        venue that corresponds with the discount offered by the venue to the customer, relating to
        the Customer&apos;s dine-in Booking.
      </p>
      <br />
      <p>
        &quot;Gross Food Value&quot; means the aggregate of the amounts paid by Customers for
        Products (being equal to the Order Prices for a Restaurant for a given period less any
        Discounts applied).
      </p>
      <br />
      <p>
        &quot;Monthly Membership&quot; means the fee the Restaurant pays on a monthly basis being
        able to use the Platform to post Deals.
      </p>
      <br />
      <p>
        &quot;Non-Excludable Conditions&quot; means an implied condition, warranty or guarantee,
        including under the Australian Competition and Consumer Act 2010 (Cth) or any other
        applicable, equivalent legislation or rules of any State, Territory or jurisdiction which
        cannot be excluded, restricted or modified, the exclusion of which from these Terms and
        Conditions would contravene any statute or cause any part of these Terms and Conditions to
        be void.
      </p>
      <br />
      <p>
        &quot;Non-Preferred Partner&quot; means a Restaurant that is selected to be a Non-Preferred
        Partner in the Contract Details.
      </p>
      <br />
      <p>
        &quot;Online Order&quot; means an Order of the restaurant menu that is placed and paid by
        the customer through the EatClub App.
      </p>
      <br />
      <p>
        &quot;Order Price&quot; means the total amount payable by the Customer for an Online Order
        (inclusive of GST and any other applicable taxes) before any Discounts are applied.
      </p>
      <br />
      <p>
        &quot;Payment Period&quot; means the end of each relevant period, as specified by EatClub
        from time to time.
      </p>
      <br />
      <p>
        &quot;Platform&quot; means the EatClub websites and apps through which it provides its
        Services, as well as any other platform that may be introduced by EatClub from time to time.
      </p>
      <br />
      <p>
        &quot;Preferred Partner&quot; means a Restaurant that is selected to be a Preferred Partner
        in the Contract Details.
      </p>
      <br />
      <p>
        &quot;Problem Order&quot; occurs when a Restaurant fails to provide an Online Order or parts
        of an Online Order after payment has been processed, or the Products in an Order become
        unavailable and/or are not replaced or for any other reason or due to a Customer complaint
        or dispute. In case of a Booking, a Problem Order occurs when a customer has not received
        the advertised Discount in parts or full.
      </p>
      <br />
      <p>
        &quot;Processing Fee&quot; means the 1.75% processing and weekly payment fee charged by
        EatClub&apos;s payment partner Finch. The Processing Fee is charged over the Gross Food
        Value.
      </p>
      <br />
      <p>
        &quot;Products&quot; means the food, beverage and any other products available for Customers
        to order from the Restaurants on the Platform.
      </p>
      <br />
      <p>
        &quot;Policies&quot; means the policies posted by EatClub on the Platform, including but not
        limited to the Privacy Policy, and any other policies it may create from time to time.
      </p>
      <br />
      <p>
        &quot;Remitted Fees&quot; means the Order Price after Discount, less the Service Fee and
        Processing Fee (and as adjusted for any payments made to or from the Restaurant, including
        any applicable Compensation Fees, Tech Deposit, etc.).
      </p>
      <br />
      <p> &quot;Restaurant&quot; means the entity, listed in the Contract Details.</p>
      <br />
      <p>
        &quot;Restaurant Services&quot; means the services to be performed by the Restaurant, as
        stated in clause 2 of the Agreement.
      </p>
      <br />
      <p> &quot;Service Fee&quot; means the amount set out in the Contract Details.</p>
      <br />
      <p>
        &quot;Services&quot; means processing Bookings, Online Orders, and providing promotional and
        marketing services that EatClub provides to participating restaurants on its Platform.
      </p>
      <br />
      <p> &quot;Set-up Fee&quot; is the amount stated in the Contract Details.</p>
      <br />
      <p>
        {" "}
        &quot;Settlement&quot; means a weekly overview sent out by EatClub, detailing all earnings
        from Online Orders and Dine in walk-ins, Float Amount and Service fees. This either shows an
        amount to be paid out to the Restaurant or an amount owed to EatClub.
      </p>
      <br />
      <p> &quot;Tech Deposit&quot; is the amount stated in the Contract Details.</p>
      <br />
      <p>
        &quot;Tech Equipment&quot; means any technical equipment provided by EatClub to the
        Restaurant for use of its Services.
      </p>
      <br />
      <p>
        &quot;Voucher&quot; means the electronic voucher that is generated for a Customer after a
        Booking is placed, which shows time of Booking and Discount offered by restaurant.
      </p>
      <br />

      {/* Rules of Interpretation */}
      <p style={styles.bold}> Rules of Interpretation:</p>
      <br />
      <p> In this Agreement and all Policies, except where the context otherwise requires:</p>
      <p>
        (a) the singular includes the plural and vice versa, and a gender includes other genders;
      </p>
      <p>
        (b) another grammatical form of a defined word or expression has a corresponding meaning;
      </p>
      <p>
        (c) a reference to a document or instrument includes the document or instrument as novated,
        altered, supplemented or replaced from time to time;
      </p>
      <p> (d) a reference to A$, $A, dollar or $ is to Australian currency;</p>
      <p> (e) a reference to time is the time in New South Wales, Australia;</p>
      <p>
        (f) a reference to a person includes a natural person, partnership, a body corporate,
        association, governmental or local authority or agency or other entity;
      </p>
      <p>
        (g) a reference to a statute, ordinance, code or other law includes regulations and other
        instruments under it and consolidations, amendments, re-enactments or replacements of any of
        them;
      </p>
      <p>
        (h) the meaning of general words is not limited by specific examples introduced by
        including, for example or similar expressions;
      </p>
      <p> (i) headings are for ease of reference only and do not affect interpretation;</p>
      <p>
        (j) any agreement, representation, warranty or indemnity in favor of two or more parties
        (including where two or more persons are included in the same defined term) is for the
        benefit of them jointly and severally; and
      </p>
      <p>
        (k) a rule of construction does not apply to the disadvantage of a party because the party
        was responsible for the preparation of this agreement or any part of it
      </p>
    </Box>
  );
};

import {
  Button,
  RadioButtonGroup,
  SegmentedControl,
  TextField,
} from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { formStateType } from "../../../types";
import { preparationTimes, yesNo, getValues } from "../../../data/ValueLists";
import { TYPES } from "../../../helpers/InputTypes";
import { trim } from "../../../helpers/Helpers";

export const OperationalDetailsPage = ({ formState, setFieldValue, isComplete }) => {
  const customTimeUsed = !getValues(preparationTimes()).includes(
    formState.operations.preparationTime,
  );
  const [showCustomPreparationTime, setShowCustomPreparationTime] = useState(customTimeUsed);
  const [menuImageLink, setMenuImageLink] = useState(formState.operations.menuImageLink);
  const [preparationTime, setPreparationTime] = useState(formState.operations.preparationTime);

  return (
    <>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Operational details</h1>
      </Box>
      <Box style={{ marginBottom: "55px" }}>
        <p className="paragraph-large">
          These details will affect how you confirm orders and bookings
        </p>
      </Box>
      <Box>
        <Box>
          <Box style={{ marginBottom: "10px" }} className="operation-details-preparation-time">
            <SegmentedControl
              label="Estimated preparation time"
              items={preparationTimes()}
              value={formState.operations.preparationTime}
              onSelect={(value) => {
                setFieldValue("operations.preparationTime", value);
                setShowCustomPreparationTime(false);
              }}
              disabled={isComplete}
            />
            <Button
              style={{ marginTop: "45px" }}
              type="text"
              text="Enter other"
              className="brand-link-color"
              onClick={() => {
                setShowCustomPreparationTime(true);
                setFieldValue("operations.preparationTime", null);
              }}
              disabled={isComplete}
            />
          </Box>
          {showCustomPreparationTime && (
            <Box style={{ marginBottom: "10px" }}>
              <Box style={{ display: "inline-flex", columnGap: "20px" }}>
                <TextField
                  style={{ maxWidth: "100px" }}
                  value={preparationTime}
                  onChange={setPreparationTime}
                  onBlur={() => setFieldValue("operations.preparationTime", trim(preparationTime))}
                  maxNumber={180}
                  minNumber={0}
                  type={TYPES.NUMBER}
                  disabled={isComplete}
                />
                <Box style={{ marginTop: "10px" }}>mins</Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{ color: "#6E6E6D", maxWidth: "300px", fontSize: "14px", marginBottom: "40px" }}
        >
          This will automatically let the customer know the estimated time their food will be ready.
        </Box>
        <RadioButtonGroup
          style={{ maxWidth: "480px", marginBottom: "30px" }}
          label="Is WiFi available in the venue?"
          items={yesNo()}
          value={formState.operations.wifiAvailable}
          onSelect={(value) => setFieldValue("operations.wifiAvailable", value)}
          disabled={isComplete}
        />
        <RadioButtonGroup
          style={{ maxWidth: "480px", marginBottom: "30px" }}
          label="Is a tablet to be provided by EatClub?"
          items={yesNo()}
          value={formState.operations.tabletRequired}
          onSelect={(value) => setFieldValue("operations.tabletRequired", value)}
          disabled={isComplete}
        />
        {/*<FileUpload*/}
        {/*  style={{ maxWidth: "480px", marginBottom: "20px" }}*/}
        {/*  label="Upload the pdf menu"*/}
        {/*  onChange={(value) => setFieldValue("operations.pdfLink", value)}*/}
        {/*/>*/}
        <TextField
          style={{ maxWidth: "480px" }}
          label="Link to menu images"
          placeholder="Dropbox, Google Drive, etc."
          type={TYPES.URL}
          onChange={setMenuImageLink}
          onBlur={() => setFieldValue("operations.menuImageLink", trim(menuImageLink))}
          value={menuImageLink}
          disabled={isComplete}
        />
      </Box>
    </>
  );
};

OperationalDetailsPage.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

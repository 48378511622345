import { Button } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import UpArrowSVG from "../../assets/up_arrow.svg";
import DownArrowSVG from "../../assets/down_arrow.svg";
import { useFocus } from "../../helpers/Hooks";
import { detectClickOutside, isEmpty } from "../../helpers/Helpers";
import "./RestaurantSelectorStyles.css";

// TODO replace this with one from the component library
export const RestaurantSelector = ({
  className,
  label,
  placeholder,
  items,
  style,
  showCreatePartner,
  onSelect,
  searchRestaurants,
  defaultValue,
  loading,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const [previousSelectedItem, setPreviousSelectedItem] = useState(null); // Useful if you need to return to a previous value
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [inputRef, setInputFocus] = useFocus();

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [textValue, setTextValue] = useState(null);

  useEffect(() => {
    setInput(defaultValue);
  }, [defaultValue]);

  // TODO useEffect to search restaurants while typing. Probably need loading spinner
  // TODO take this function from elsewhere

  // TODO logic to determine whether it is a prospect / in progress

  // TODO if search returns no results, have a message that says so

  const isActive = document.activeElement === inputRef.current; // Whether the input is the active one
  const listRef = useRef(null);

  // Used for keyboard accessibility
  const onFocus = () => handleOpen();
  const onBlur = (event) => {
    // Don't blur if not active
    if (!isActive) {
      return;
    }

    console.log(event);

    if (isOpen) {
      handleClose();
    }
    document.activeElement.blur();
  };
  const [hoveredItem, setHoveredItem] = useState(null);

  const createNewPartner = () => {
    setOpen(false);
    showCreatePartner(true);
  };

  const handleOnSelect = (newSelectedItem) => {
    setOpen(false);
    setSelectedItem(items.find((item) => item.objectId === newSelectedItem.objectId));
    onSelect(newSelectedItem);
    // TODO set the restaurant on the form
  };

  const handleOpen = () => {
    setPreviousSelectedItem(selectedItem);
    setSelectedItem(null);
    setOpen(true);
  };

  const handleClose = () => {
    setHoveredItem(null);
    setOpen(false);
    setInputFocus(false);
  };

  if (!isActive && isOpen) {
    handleClose();
  }

  // const handleOnClose = () => {
  //   // If clicked out without setting anything, return to the previously selected item
  //   if (!selectedItem && previousSelectedItem) {
  //     setSelectedItem(previousSelectedItem);
  //   }
  //   setOpen(false);
  // };

  const handleOnType = (value) => {
    setInput(value);

    // TODO search API for value
    // TODO throttle so search only happens about a second after typing stops

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        searchRestaurants(value);
      }, 300),
    );
  };

  const wrapperRef = useRef(null);
  detectClickOutside(wrapperRef, handleClose);

  // TODO should probably be in useEffect
  // Return items that have a matching name or ID. Limit to 5 items
  const getFilteredItems = () => {
    if (!items || !input) {
      return [];
    }

    return items.slice(0, 5);
  };

  const filteredItems = getFilteredItems();
  const noItemsFound = input && !filteredItems.length;

  // TODO make this a hook
  const handleKeyPress = (event) => {
    // Return early if this is not the active element
    if (!isActive) {
      return;
    }

    // Prevent cursor from moving to start or end of input
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }

    if (isOpen) {
      const itemHeight = 54;
      if (event.key === "ArrowDown") {
        if (hoveredItem === null) {
          setHoveredItem(0);
        } else {
          const nextItem = hoveredItem + 1;
          if (nextItem < filteredItems.length) {
            setHoveredItem(nextItem);

            listRef?.current?.scrollTo({
              top: hoveredItem * itemHeight,
              behavior: "smooth",
            });
          }
        }
      } else if (event.key === "ArrowUp") {
        const previousItem = hoveredItem - 1;
        if (previousItem >= 0) {
          setHoveredItem(previousItem);

          listRef?.current?.scrollTo({
            top: (hoveredItem - 1) * itemHeight,
            behavior: "smooth",
          });
        }
      }

      // If press enter, select the hovered item
      if (event.key === "Enter") {
        // If no hovered item, but only one filtered option, select that
        console.log(hoveredItem, filteredItems);
        if (hoveredItem === null && filteredItems.length === 1) {
          handleOnSelect(filteredItems[0]);
        } else if (hoveredItem !== null) {
          handleOnSelect(filteredItems[hoveredItem]);
        }
      }

      // Blur on escape without selecting
      if (event.key === "Escape") {
        onBlur();
      }
    } else {
      // Down arrow should open dropdown if it's closed
      if (event.key === "ArrowDown") {
        handleOpen();
        setHoveredItem(0);
      }
    }
  };

  // Add keyboard event listener to input
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [hoveredItem, isOpen]);

  const removeNullWordsFromString = (string) => {
    if (isEmpty(string)) {
      return string;
    }

    return string
      .split(" ")
      .filter((word) => word !== "null")
      .join(" ");
  };

  return (
    <Box className={`restaurant-selector-outer-container ${className}`} style={style}>
      {label && (
        <Box className="paragraph-small" style={{ marginBottom: "10px" }}>
          {label}
        </Box>
      )}

      <Box ref={wrapperRef} className="restaurant-selector-container">
        <Box
          className={`restaurant-selector ${isOpen && "restaurant-selector-open"}`}
          onClick={handleOpen}
        >
          <Box className="restaurant-selector-content">
            <Box className="text-field-inner">
              <input
                ref={inputRef}
                onFocus={onFocus}
                onBlur={onBlur}
                autoCorrect="none"
                autoCapitalize="words"
                autoComplete="new-password"
                className="restaurant-selector-text-field-input"
                style={{ maxWidth: "calc(100% - 40px)" }}
                type="text"
                value={selectedItem?.title || input}
                placeholder={placeholder}
                onChange={(e) => handleOnType(e.target.value)}
              />
            </Box>
            <Box
              onMouseDown={(e) => {
                e.preventDefault();
                if (isOpen) {
                  handleClose();
                } else {
                  setInputFocus();
                  handleOpen();
                }
              }}
            >
              {isOpen ? (
                <UpArrowSVG style={{ marginBottom: "2px" }} />
              ) : (
                <DownArrowSVG style={{ marginBottom: "1px" }} />
              )}
            </Box>
          </Box>
        </Box>
        <Box
          className={`restaurant-selector-menu ${isOpen ? "visible" : "hidden"}`}
          onMouseDown={(e) => e.preventDefault()}
        >
          <>
            {!items && loading && input !== "" && (
              <Box style={{ textAlign: "center", padding: "20px" }}>Loading...</Box>
            )}
            {!loading && noItemsFound && (
              <Box style={{ textAlign: "center", padding: "20px" }}>
                No partners found with that name or ID
              </Box>
            )}
            {input === "" && (
              <Box style={{ textAlign: "center", padding: "20px" }}>
                Start typing to search for restaurants
              </Box>
            )}
            {!loading &&
              filteredItems.map((item, i) => (
                <Box key={item.objectId}>
                  <Box
                    className={`restaurant-selector-menu-item ${
                      i === hoveredItem ? "restaurant-selector-menu-item-hovered" : ""
                    }`}
                    onMouseDown={() => handleOnSelect(item)}
                    onMouseEnter={() => setHoveredItem(i)}
                  >
                    <Box
                      className="restaurant-selector-menu-item-text"
                      style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
                    >
                      <Box>
                        <Box style={{ marginBottom: "8px" }} className="paragraph-small">
                          {item.title}
                        </Box>
                        <Box
                          className="paragraph-xsmall"
                          style={{ color: "#6E6E6D", display: "flex" }}
                        >
                          <span>{removeNullWordsFromString(item.details)}</span>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          className="paragraph-small"
                          style={{
                            color: "#313131",
                            marginBottom: "10px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                        >
                          {item?.type === "SIGNUP_COMPLETED" && (
                            <Box
                              style={{
                                padding: "5px 10px",
                                backgroundColor: "#BCEE95",
                                borderRadius: "4px",
                              }}
                            >
                              Completed
                            </Box>
                          )}
                          {item?.type === "SIGNUP_INPROGRESS" && (
                            <Box
                              style={{
                                padding: "5px 10px",
                                backgroundColor: "#DCDCDB",
                                borderRadius: "4px",
                              }}
                            >
                              In progress
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {item.status && (
                      <Box className="restaurant-selector-menu-item-status">
                        <Box className="paragraph-small">{item.status}</Box>
                      </Box>
                    )}
                  </Box>

                  <Box
                    className={`restaurant-selector-spacer ${
                      i + 1 < items.length && "restaurant-selector-spacer-margin"
                    }`}
                  />
                </Box>
              ))}
          </>

          {showCreatePartner && (
            <Box
              className="restaurant-selector-create-new-item"
              onMouseEnter={() => setHoveredItem(null)}
            >
              <Button
                className="restaurant-selector-create-button"
                type="secondary"
                text="Create new partner"
                onClick={createNewPartner}
                disabled={!isOpen}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

RestaurantSelector.defaultProps = {
  className: "",
  style: "",
  InputProps: {},
  label: "",
  placeholder: "",
  items: [],
  defaultValue: null,
  showCreatePartner: true,
  onSelect: () => {},
  searchRestaurants: () => {},
};

RestaurantSelector.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.shape({}),
  showCreatePartner: PropTypes.func,
  onSelect: PropTypes.func,
  searchRestaurants: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      suburb: PropTypes.string,
      region: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
};

import throttle from "lodash.throttle";
import { useCallback, useEffect, useState } from "react";

/**
 * Runs the autocomplete prediction for the given text
 */
export const googleAutocomplete = async (text) =>
  new Promise((resolve, reject) => {
    if (!window?.google?.maps?.places) {
      return reject(new Error("Places script has not loaded"));
    }

    if (!text) {
      return reject(new Error("Need valid text input"));
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: text,
          componentRestrictions: { country: "au" },
          fields: ["address_components"],
          // types: ["street_address", "bakery", "cafe", "meal_delivery", "meal_takeaway"],
        },
        resolve,
      );
    } catch (e) {
      reject(e);
    }

    return null;
  });

/**
 * Gets the geocode for the place.
 * Used to break down the street number, suburb, etc.
 */
export const geocodeByPlaceId = (placeId) => {
  const geocoder = new window.google.maps.Geocoder();
  const { OK } = window.google.maps.GeocoderStatus;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results[0] || {});
    });
  });
};

/**
 * Hook for places autocomplete, with throttling
 */
export const usePlacesAutocomplete = (text = "", timeout = 400) => {
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(false);

  const doSearch = async (searchText) => {
    try {
      if (!searchText) {
        setLoading(false);
        return;
      }

      setLoading(true);
      const nextPredictions = await googleAutocomplete(searchText);
      setPredictions(nextPredictions || []);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const throttleChangeHandler = useCallback(throttle(doSearch, timeout), [timeout]);

  useEffect(() => {
    throttleChangeHandler(text);
  }, [text]);

  return [predictions, loading];
};

import { Button, ComboBox, SegmentedControl } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import "./OpeningHoursStyles.css";

export const OpeningHoursDay = ({ className, style, day, getTimes, onChange, value, disabled }) => {
  const { isOpen, shifts } = value;
  const maxShifts = 2; // Limit to 2

  const removeShift = (shiftID) => {
    const updatedShifts = [...shifts].filter((shift) => shift.id !== shiftID);
    onChange("shifts", updatedShifts);
  };

  const addShift = () => {
    if (shifts.length >= 2) {
      return;
    }

    onChange("shifts", [...shifts, { start: null, end: null, id: 1 }]);
  };

  const onHoursChanged = (shiftIndex, type, newValue) => {
    if (shiftIndex < 0 || shiftIndex >= shifts.length) {
      return;
    }

    const newShifts = [...shifts];
    newShifts[shiftIndex][type] = newValue;

    onChange("shifts", newShifts);
  };

  const setOpen = (newOpen) => {
    onChange("isOpen", newOpen);
  };

  return (
    <Box className={`opening-hours-day-container ${className}`} style={style}>
      <Box style={{ marginBottom: "20px" }} className="opening-hours-day">
        <h3>{day}</h3>
        <SegmentedControl
          items={[
            { label: "Open", value: true },
            { label: "Closed", value: false },
          ]}
          value={isOpen}
          onSelect={(openValue) => setOpen(openValue)}
          disabled={disabled}
        />
      </Box>
      <Box className="opening-hours-times">
        {isOpen &&
          shifts.map((shift, i) => (
            <Box
              key={shift?.id}
              style={{ marginBottom: "20px" }}
              className="opening-hours-time-row"
            >
              <Box className="opening-hours-time-pickers">
                <ComboBox
                  className="opening-hours-time-picker"
                  label="From"
                  items={getTimes()}
                  placeholder="Start time"
                  onSelect={(newValue) => onHoursChanged(i, "start", newValue)}
                  value={shifts[i]?.start}
                  clearOnOpen
                  disabled={disabled}
                />
                <ComboBox
                  className="opening-hours-time-picker"
                  label="To"
                  items={getTimes()}
                  placeholder="End time"
                  onSelect={(newValue) => onHoursChanged(i, "end", newValue)}
                  value={shifts[i]?.end}
                  clearOnOpen
                  disabled={disabled}
                />
              </Box>
              {shifts.length < maxShifts && (
                <Button
                  type="text"
                  text="Split shift"
                  className="split-remove-button brand-link-color"
                  onClick={addShift}
                  disabled={disabled}
                />
              )}
              {i > 0 && (
                <Button
                  type="text"
                  text="Remove"
                  className="split-remove-button brand-link-color"
                  onClick={() => removeShift(shift?.id)}
                  disabled={disabled}
                />
              )}
            </Box>
          ))}
        <Box className="opening-hours-divider" />
      </Box>
    </Box>
  );
};

OpeningHoursDay.defaultProps = {
  className: "",
  style: {},
  InputProps: {},
  getTimes: () => {},
  onChange: () => {},
  disabled: false,
};

OpeningHoursDay.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  day: PropTypes.string.isRequired,
  getTimes: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    isOpen: PropTypes.bool,
    shifts: PropTypes.shape([]),
  }).isRequired,
  disabled: PropTypes.bool,
};

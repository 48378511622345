import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, LogMessages, InfoBox } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import validator from "validator";
import { Box, TextField as MuiTextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  AuBankAccountElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { isEmpty } from "../../helpers/Helpers";
import { COLORS } from "../../theme";
import useStyles from "./SettlementDetailsWrapperStyles";
import { DDRServiceAgreement } from "../DDRServiceAgreement/DDRServiceAgreement";

const FilledField = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Box className={classes.filledField}>
      <Box className={classes.filledlabel}>{label}</Box>
      <Box className={classes.filledValue}>{value || "Empty"}</Box>
    </Box>
  );
};

FilledField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

/* eslint-disable */
const StripeInput = ({ component: Component, disabled, inputRef, placeholder, ...props }) => {
  const elementRef = useRef();

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      options={{
        disabled,
        showIcon: true,
        placeholder,
        disableLink: true,
        style: {
          base: {
            fontSize: "16px",
            fontFamily: "Gordita, 'Open Sans', 'Helvetica', 'sans-serif'",
            color: COLORS.CHARCOAL,
            "::placeholder": {
              fontWeight: "lighter",
              color: "#b3b3b3",
            },
          },
        },
      }}
      {...props}
    />
  );
};

const TextField = styled((props) => <MuiTextField fullWidth variant="outlined" {...props} />)(
  ({ theme }) => ({
    "& .MuiFilledInput-root": {
      overflow: "hidden",
      borderRadius: 6,
      backgroundColor: "#FCFAF8",
      // transition: theme.transitions.create([
      //   "border-color",
      //   "background-color",
      //   "box-shadow",
      // ]),
      "&:hover": {
        backgroundColor: COLORS.WHITE,
      },
      "&.Mui-focused": {
        backgroundColor: COLORS.WHITE,
        // borderColor: COLORS.BLACK_SELECT,
      },
    },
  }),
);

const SettlementTextField = ({
  disabled,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  required,
  inputProps,
  clearError,
}) => (
  <Box
    style={{
      display: "flex",
      gap: "6px",
      flexDirection: "column",
      width: "100%",
    }}
  >
    <Box>{label}</Box>
    <TextField
      required={required}
      error={!!error}
      placeholder={placeholder}
      value={value || ""}
      onChange={(e) => {
        if (!!clearError) {
          clearError();
        }

        if (!!onChange) {
          onChange(e.target.value);
        }
      }}
      InputProps={inputProps}
      onBlur={onBlur}
      helperText={error}
      disabled={disabled}
      autoComplete={"off"}
    />
  </Box>
);

// TODO make more generic (e.g. text as prop)
export const SettlementDetails = ({
  header,
  venueName,
  bankAccountName,
  setBankAccountName,
  bankBSB,
  setBankBSB,
  bankAccountNumber,
  setBankAccountNumber,
  ddBillingMethodId,
  setDdBillingMethodId,
  ddBillingMethodName,
  setDdBillingMethodName,
  ddBillingMethodAction,
  ccBillingMethodId,
  setCcBillingMethodId,
  ccBillingMethodName,
  setCcBillingMethodName, // actually the credit card
  ccBillingMethodAction,
  ddCapture,
  preferredMethod,
  setPreferredMethod,
  updateDetails,
  overrideCC,
  setOverrideCC,
  bdm,
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const [messages, setMessages] = useState([]);
  const [openDDR, setOpenDDR] = useState(false);

  const [tdAccName, setTdAccName] = useState(bankAccountName);
  const [tdAccNameError, setTdAccNameError] = useState("");
  const [tdBsb, setTdBsb] = useState(bankBSB);
  const [tdBsbError, setTdBsbError] = useState("");
  const [tdAccNum, setTdAccNum] = useState(bankAccountNumber);
  const [tdAccNumError, setTdAccNumError] = useState("");

  const EMPTY_FIELD = "EMPTY_FIELD";

  const [ccName, setCcName] = useState("");
  const [ccNameError, setCcNameError] = useState("");
  const [ccNumErrors, setCcNumErrors] = useState(EMPTY_FIELD);
  const [ccExpiryErrors, setCcExpiryErrors] = useState(EMPTY_FIELD);
  const [ccCodeErrors, setCcCodeErrors] = useState(EMPTY_FIELD);

  const [ddAccName, setDdAccName] = useState("");
  const [ddAccNameError, setDdAccNameError] = useState("");
  const [ddAccEmail, setDdAccEmail] = useState("");
  const [ddAccEmailError, setDdAccEmailError] = useState("");
  const [ddDetailsErrors, setDdDetailsErrors] = useState(EMPTY_FIELD);

  const [editTd, setEditTd] = useState(!bankAccountName && !bankBSB && !bankAccountNumber);
  const [editCc, setEditCc] = useState(!ccBillingMethodId && !ccBillingMethodName);
  const [editDd, setEditDd] = useState(!ddBillingMethodId && !ddBillingMethodName);

  const [updatingCc, setUpdatingCc] = useState(false);
  const [updatingDd, setUpdatingDd] = useState(false);

  const requiredErrorMsg = "This field is required.";

  useEffect(() => {
    if (editCc) {
      setCcNumErrors(EMPTY_FIELD);
      setCcExpiryErrors(EMPTY_FIELD);
      setCcCodeErrors(EMPTY_FIELD);
    }
  }, [editCc]);

  useEffect(() => {
    if (editDd) {
      setDdDetailsErrors(EMPTY_FIELD);
    }
  }, [editDd]);

  const saveTd = () => {
    let valid = true;

    if (tdAccName === "" || tdAccName === null) {
      setTdAccNameError(requiredErrorMsg);

      valid = false;
    }

    if (tdBsb === "" || tdBsb === null) {
      setTdBsbError(requiredErrorMsg);

      valid = false;
    }

    if (tdAccNum === "" || tdAccNum === null) {
      setTdAccNumError(requiredErrorMsg);

      valid = false;
    }

    if (valid) {
      setBankAccountName(tdAccName);
      setBankBSB(tdBsb);
      setBankAccountNumber(tdAccNum);

      setEditTd(false);
    }
  };

  const cancelTd = () => {
    setTdAccName(bankAccountName);
    setTdBsb(bankBSB);
    setTdAccNum(bankAccountNumber);

    setEditTd(false);
  };

  const saveCc = async () => {
    try {
      setUpdatingCc(true);
      let valid = true;

      if (!stripe || !elements) {
        throw new Error("Stripe is undefined.");
      }

      if (ccName === "" || ccName === null) {
        setCcNameError(requiredErrorMsg);

        valid = false;
      }

      if (ccNumErrors !== "" && ccNumErrors !== null) {
        valid = false;
      }

      if (ccNumErrors === EMPTY_FIELD) {
        setCcNumErrors(requiredErrorMsg);

        valid = false;
      }

      if (ccExpiryErrors !== "" && ccExpiryErrors !== null) {
        valid = false;
      }

      if (ccExpiryErrors === EMPTY_FIELD) {
        setCcExpiryErrors(requiredErrorMsg);

        valid = false;
      }

      if (ccCodeErrors !== "" && ccCodeErrors !== null) {
        valid = false;
      }

      if (ccCodeErrors === EMPTY_FIELD) {
        setCcCodeErrors(requiredErrorMsg);

        valid = false;
      }

      if (valid) {
        const response = await ccBillingMethodAction(
          ccName,
          stripe,
          elements.getElement(CardNumberElement),
        );

        if (!response) {
          throw new Error("Failed to save credit card details");
        }

        const { billingMethodId, billingMethodName } = response;

        console.log("cc response", response, billingMethodId, billingMethodName, preferredMethod);

        let newPaymentDetailsFields = [
          { name: "paymentDetails.creditCardStripeId", value: billingMethodId },
          { name: "paymentDetails.creditCardName", value: billingMethodName },
        ];

        // Set the flag to say we're capturing cc details
        if (isEmpty(preferredMethod) || preferredMethod === "cc") {
          newPaymentDetailsFields = [
            ...newPaymentDetailsFields,
            { name: "paymentDetails.creditCardCapture", value: true },
          ];
        }

        if (preferredMethod === "" || (preferredMethod === "dd" && !ddCapture)) {
          newPaymentDetailsFields = [
            ...newPaymentDetailsFields,
            { name: "paymentDetails.preferredMethod", value: "cc" },
          ];
        }

        // Update all at once to prevent race conditions
        updateDetails(newPaymentDetailsFields);

        setEditCc(false);
      }
    } catch (error) {
      setMessages([
        ...messages,
        {
          id: `SAVE_CC_${Date.now()}`,
          message: error.message,
          severity: "error",
        },
      ]);
    } finally {
      setUpdatingCc(false);
    }
  };

  const cancelCc = () => {
    updateDetails([
      { name: "paymentDetails.creditCardStripeId", value: ccBillingMethodId },
      { name: "paymentDetails.creditCardName", value: ccBillingMethodName },
    ]);

    setEditCc(false);
  };

  const saveDd = async () => {
    try {
      setUpdatingDd(true);
      let valid = true;

      if (!stripe || !elements) {
        throw new Error("Stripe is undefined.");
      }

      if (ddAccName === "" || ddAccName === null) {
        setDdAccNameError(requiredErrorMsg);

        valid = false;
      }

      if (ddAccEmail === "" || ddAccEmail === null) {
        setDdAccEmailError(requiredErrorMsg);

        valid = false;
      } else if (!validator.isEmail(ddAccEmail)) {
        setDdAccEmailError("This is not a valid email address.");

        valid = false;
      }

      if (ddDetailsErrors !== "" && ddDetailsErrors !== null) {
        valid = false;
      }

      if (ddDetailsErrors === EMPTY_FIELD) {
        setDdDetailsErrors(requiredErrorMsg);

        valid = false;
      }

      if (valid) {
        const { billingMethodId, billingMethodName } = await ddBillingMethodAction(
          ddAccName,
          ddAccEmail,
          stripe,
          elements.getElement(AuBankAccountElement),
        );

        setDdBillingMethodId(billingMethodId);
        setDdBillingMethodName(billingMethodName);

        if (preferredMethod === "") {
          setPreferredMethod("dd");
        }

        setEditDd(false);
      }
    } catch (error) {
      setMessages([
        ...messages,
        {
          id: `SAVE_DD_${Date.now()}`,
          message: error.message,
          severity: "error",
        },
      ]);
    } finally {
      setUpdatingDd(false);
    }
  };

  const cancelDd = () => {
    setDdBillingMethodId(ddBillingMethodId);
    setDdBillingMethodName(ddBillingMethodName);

    setEditDd(false);
  };

  const renderTdCapture = () => {
    if (!editTd) {
      return (
        <Box className={classes.filledContainer}>
          <FilledField label="Account name" value={tdAccName} />
          <FilledField label="BSB" value={tdBsb} />
          <FilledField label="Account number" value={tdAccNum} />

          <Box style={{ fontSize: "12px" }}>
            ** Bank details may be used as a backup for to EatClub and credit card provided doesn’t
            work.
          </Box>
        </Box>
      );
    }
    return (
      <Box className={classes.fieldsContainer}>
        <SettlementTextField
          label="Account name"
          placeholder="Account name"
          required
          value={tdAccName}
          onChange={setTdAccName}
          error={tdAccNameError}
          clearError={() => setTdAccNameError("")}
        />
        <Box style={{ display: "flex", gap: "12px" }}>
          <SettlementTextField
            label="BSB"
            placeholder="BSB"
            required
            value={tdBsb}
            onChange={setTdBsb}
            error={tdBsbError}
            clearError={() => setTdBsbError("")}
          />
          <SettlementTextField
            label="Account number"
            placeholder="Account number"
            required
            value={tdAccNum}
            onChange={setTdAccNum}
            error={tdAccNumError}
            clearError={() => setTdAccNumError("")}
          />
        </Box>

        <Box style={{ fontSize: "12px" }}>
          * Bank details may be used as a backup for to EatClub and credit card provided doesn’t
          work.
        </Box>
        {editTd && (
          <Box className={classes.btnConatiner}>
            <Button type="tertiary" onClick={cancelTd}>
              Cancel
            </Button>
            <Button onClick={saveTd}>Save</Button>
          </Box>
        )}
      </Box>
    );
  };

  const renderCcCapture = () => {
    if (!editCc) {
      return (
        <Box className={classes.filledContainer}>
          <FilledField
            label="Cardholder name"
            value={ccBillingMethodId ? ccName ?? "****" : "Empty"}
          />
          <FilledField
            label="Credit card number"
            value={ccBillingMethodId ? ccBillingMethodName : "Empty"}
          />
          <FilledField label="Expiry date" value={ccBillingMethodId ? "****" : "Empty"} />
          <FilledField label="Security code" value={ccBillingMethodId ? "****" : "Empty"} />
        </Box>
      );
    }
    return (
      <Box className={classes.fieldsContainer}>
        <SettlementTextField
          label="Cardholder name"
          placeholder="Cardholder name"
          required
          value={ccName}
          onChange={setCcName}
          error={ccNameError}
          clearError={() => setCcNameError("")}
          disabled={updatingCc}
        />
        <SettlementTextField
          label="Credit card number"
          placeholder="Credit card number"
          required
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
              onChange: (e) => setCcNumErrors(e.error?.message || ""),
            },
          }}
          error={ccNumErrors === EMPTY_FIELD ? "" : ccNumErrors}
          disabled={updatingCc}
        />
        <Box style={{ display: "flex", gap: "12px" }}>
          <SettlementTextField
            label="Expiry date"
            placeholder="MM/YY"
            required
            inputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement,
                onChange: (e) => setCcExpiryErrors(e.error?.message || ""),
              },
            }}
            error={ccExpiryErrors === EMPTY_FIELD ? "" : ccExpiryErrors}
            disabled={updatingCc}
          />
          <SettlementTextField
            label="Security code"
            placeholder="E.g. 043"
            required
            inputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
                onChange: (e) => setCcCodeErrors(e.error?.message || ""),
              },
            }}
            error={ccCodeErrors === EMPTY_FIELD ? "" : ccCodeErrors}
            disabled={updatingCc}
          />
        </Box>
        {editCc && (
          <Box className={classes.btnConatiner}>
            <Button type="tertiary" onClick={cancelCc} disabled={updatingCc}>
              Cancel
            </Button>
            <Button onClick={saveCc} loading={updatingCc} disabled={updatingCc}>
              Save
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  const standardAuthorizationText =
    "By providing your bank account details, you agree to this Direct Debit Request and the Direct Debit Request service agreement, and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Stripe”) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of EatClub (the “Merchant”) for any amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorised signatory on the account listed above.";

  const renderDdCapture = () => {
    if (ddCapture) {
      if (!editDd) {
        return (
          <>
            <Box className={classes.filledContainer} style={{ marginBottom: "24px" }}>
              <FilledField label="Account name" value={ddBillingMethodId ? "****" : "Empty"} />
              <FilledField label="Account email" value={ddBillingMethodId ? "****" : "Empty"} />
              <FilledField label="BSB" value={ddBillingMethodId ? "****" : "Empty"} />
              <FilledField
                label="Account number"
                value={ddBillingMethodId ? ddBillingMethodName : "Empty"}
              />
            </Box>
            <Button
              type="text"
              onClick={() => setOpenDDR(true)}
              style={{
                container: {
                  fontSize: "14px",
                  marginTop: "8px",
                },
              }}
            >
              View BECS Direct Debit Request Service Agreement
            </Button>
          </>
        );
      }
      return (
        <>
          <Box className={classes.fieldsContainer} style={{ marginBottom: "30px" }}>
            <SettlementTextField
              label="Account name"
              placeholder="Account name"
              required
              value={ddAccName}
              onChange={setDdAccName}
              error={ddAccNameError}
              clearError={() => setDdAccNameError("")}
              disabled={updatingDd}
            />
            <SettlementTextField
              label="Contact email"
              placeholder="Contact email"
              required
              value={ddAccEmail}
              onChange={setDdAccEmail}
              error={ddAccEmailError}
              clearError={() => setDdAccEmailError("")}
              disabled={updatingDd}
            />
            <SettlementTextField
              label="Account details"
              required
              inputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: AuBankAccountElement,
                  onChange: (e) => setDdDetailsErrors(e.error?.message || ""),
                },
              }}
              error={ddDetailsErrors === EMPTY_FIELD ? "" : ddDetailsErrors}
              disabled={updatingDd}
            />
            {editDd && (
              <Box className={classes.btnConatiner}>
                <Button type="tertiary" onClick={cancelDd} disabled={updatingDd}>
                  Cancel
                </Button>
                <Button onClick={saveDd} loading={updatingDd} disabled={updatingDd}>
                  Save
                </Button>
              </Box>
            )}
          </Box>
          {/*<InfoBox info={standardAuthorizationText} />*/}
          <Button
            type="text"
            onClick={() => setOpenDDR(true)}
            style={{
              container: {
                fontSize: "14px",
                marginTop: "8px",
              },
            }}
          >
            View BECS Direct Debit Request Service Agreement
          </Button>
        </>
      );
    }
    return <></>;
  };

  // TODO log messages should be decoupled from this component and handled by the app

  return (
    <Box className={classes.container}>
      {header && (
        <Box className={classes.headerContainer}>
          <Box className={classes.header}>{header}</Box>
        </Box>
      )}
      {/*<Box className={classes.desc}>*/}
      {/*  At the end of each settlement period, money will be transferred or charged from the*/}
      {/*  following accounts.*/}
      {/*</Box>*/}

      <Box className={classes.sectionContainer}>
        <Box className={classes.sectionHeader}>Collection: Credit / Debit card details</Box>
        <Box className={classes.mediumText}>
          These details are used to collect money owed to EatClub through the weekly settlement
          process.
        </Box>
      </Box>
      <Box className={classes.cdContainer}>
        <Box className={classes.chkboxContainer}>
          <Box className={classes.sectionHeaderContainer}>
            {!editCc && (
              <Box className={classes.editBtn} onClick={() => setEditCc(true)}>
                Edit
              </Box>
            )}
          </Box>
        </Box>
        {renderCcCapture()}

        <Box className={classes.sectionContainer}>
          <Box className={classes.sectionHeaderContainer}>
            <Box className={classes.sectionHeader}>Payout: Bank transfer</Box>
            {!editTd && (
              <Box className={classes.editBtn} onClick={() => setEditTd(true)}>
                Edit
              </Box>
            )}
          </Box>
          <Box className={classes.mediumText}>
            These will be used to transfer money owing to the venue through the weekly settlement
            process. The details will also be set up to create a Direct-Debit, which will be used in
            the event of the card details failing.
          </Box>
        </Box>
        {renderTdCapture()}
      </Box>

      {/*{formData?.ccOverride && (*/}
      {/*  <Box className={classes.prefContainer}>*/}
      {/*    <FilledField*/}
      {/*      label="Override credit card details"*/}
      {/*      value={*/}
      {/*        <Checkbox*/}
      {/*          color={"#313131"}*/}
      {/*          label="Prevent requiring CC details and use bank details instead"*/}
      {/*          onChange={setOverrideCC}*/}
      {/*          selected={overrideCC}*/}
      {/*        />*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*)}*/}

      {/*{ccCapture && (*/}
      {/*  <>*/}
      {/*    <Box className={classes.prefContainer}>*/}
      {/*      <FilledField*/}
      {/*        label="Preferred method"*/}
      {/*        value={*/}
      {/*          <SegmentedControl*/}
      {/*            value={preferredMethod}*/}
      {/*            items={[*/}
      {/*              { label: "Credit card", value: "cc" },*/}
      {/*              { label: "Direct debit", value: "dd" },*/}
      {/*            ]}*/}
      {/*            onSelect={setPreferredMethod}*/}
      {/*          />*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </Box>*/}
      {/*  </>*/}
      {/*)}*/}

      <Button
        type="text"
        onClick={() => setOpenDDR(true)}
        style={{
          container: {
            fontSize: "14px",
            marginBottom: "8px",
          },
        }}
      >
        View BECS Direct Debit Request Service Agreement
      </Button>
      <InfoBox info={standardAuthorizationText} />
      <DDRServiceAgreement isOpen={openDDR} onClose={() => setOpenDDR(false)} />
      <LogMessages
        messages={messages}
        removeMessage={(id) =>
          setMessages([...messages.filter((foundMessage) => foundMessage.id !== id)])
        }
      />
    </Box>
  );
};

SettlementDetails.propTypes = {
  header: PropTypes.string.isRequired,
  venueName: PropTypes.string.isRequired,
  bankAccountName: PropTypes.string.isRequired,
  setBankAccountName: PropTypes.func.isRequired,
  bankBSB: PropTypes.string.isRequired,
  setBankBSB: PropTypes.func.isRequired,
  bankAccountNumber: PropTypes.string.isRequired,
  setBankAccountNumber: PropTypes.func.isRequired,
  ddBillingMethodId: PropTypes.string.isRequired,
  setDdBillingMethodId: PropTypes.func.isRequired,
  ddBillingMethodName: PropTypes.string.isRequired,
  setDdBillingMethodName: PropTypes.func.isRequired,
  ddBillingMethodAction: PropTypes.func.isRequired,
  ccBillingMethodId: PropTypes.string.isRequired,
  setCcBillingMethodId: PropTypes.func.isRequired,
  ccBillingMethodName: PropTypes.string.isRequired,
  setCcBillingMethodName: PropTypes.func.isRequired,
  ccBillingMethodAction: PropTypes.func.isRequired,
  ccCapture: PropTypes.bool.isRequired,
  setCcCapture: PropTypes.func.isRequired,
  ddCapture: PropTypes.bool.isRequired,
  setDdCapture: PropTypes.func.isRequired,
  preferredMethod: PropTypes.string.isRequired,
  setPreferredMethod: PropTypes.func.isRequired,
};

export const SettlementDetailsWrapper = ({
  header,
  stripeId,
  venueName,
  bankAccountName,
  setBankAccountName,
  bankBSB,
  setBankBSB,
  bankAccountNumber,
  setBankAccountNumber,
  ddBillingMethodId,
  setDdBillingMethodId,
  ddBillingMethodName,
  setDdBillingMethodName,
  ddBillingMethodAction,
  ccBillingMethodId,
  setCcBillingMethodId,
  ccBillingMethodName,
  setCcBillingMethodName,
  ccBillingMethodAction,
  ccCapture,
  setCcCapture,
  ddCapture,
  setDdCapture,
  preferredMethod,
  setPreferredMethod,
  setOverrideCC,
  overrideCC,
  bdm,
  updateDetails,
}) => {
  const stripe = loadStripe(stripeId);

  return (
    <Elements stripe={stripe}>
      <SettlementDetails
        header={header}
        venueName={venueName}
        bankAccountName={bankAccountName}
        setBankAccountName={setBankAccountName}
        bankBSB={bankBSB}
        setBankBSB={setBankBSB}
        bankAccountNumber={bankAccountNumber}
        setBankAccountNumber={setBankAccountNumber}
        ddBillingMethodId={ddBillingMethodId}
        setDdBillingMethodId={setDdBillingMethodId}
        ddBillingMethodName={ddBillingMethodName}
        setDdBillingMethodName={setDdBillingMethodName}
        ddBillingMethodAction={ddBillingMethodAction}
        ccBillingMethodId={ccBillingMethodId}
        setCcBillingMethodId={setCcBillingMethodId}
        ccBillingMethodName={ccBillingMethodName}
        setCcBillingMethodName={setCcBillingMethodName}
        ccBillingMethodAction={ccBillingMethodAction}
        ccCapture={ccCapture}
        setCcCapture={setCcCapture}
        ddCapture={ddCapture}
        setDdCapture={setDdCapture}
        preferredMethod={preferredMethod}
        setPreferredMethod={setPreferredMethod}
        bdm={bdm}
        setOverrideCC={setOverrideCC}
        overrideCC={overrideCC}
        updateDetails={updateDetails}
      />
    </Elements>
  );
};

SettlementDetailsWrapper.defaultProps = {
  header: "3. Provide your settlement details",
  venueName: "[Venue name]",
  bankAccountName: "",
  setBankAccountName: () => {},
  bankBSB: "",
  setBankBSB: () => {},
  bankAccountNumber: "",
  setBankAccountNumber: () => {},
  ddBillingMethodId: "",
  setDdBillingMethodId: () => {},
  ddBillingMethodName: "",
  setDdBillingMethodName: () => {},
  ddBillingMethodAction: () => {},
  ccBillingMethodId: "",
  setCcBillingMethodId: () => {},
  ccBillingMethodName: "",
  setCcBillingMethodName: () => {},
  ccBillingMethodAction: () => {},
  ccCapture: false,
  setCcCapture: () => {},
  ddCapture: false,
  setDdCapture: () => {},
  preferredMethod: "",
  setPreferredMethod: () => {},
  bdm: null,
};

SettlementDetailsWrapper.propTypes = {
  stripeId: PropTypes.string.isRequired,
  header: PropTypes.string,
  venueName: PropTypes.string,
  bankAccountName: PropTypes.string,
  setBankAccountName: PropTypes.func,
  bankBSB: PropTypes.string,
  setBankBSB: PropTypes.func,
  bankAccountNumber: PropTypes.string,
  setBankAccountNumber: PropTypes.func,
  ddBillingMethodId: PropTypes.string,
  setDdBillingMethodId: PropTypes.func,
  ddBillingMethodName: PropTypes.string,
  setDdBillingMethodName: PropTypes.func,
  ddBillingMethodAction: PropTypes.func,
  ccBillingMethodId: PropTypes.string,
  setCcBillingMethodId: PropTypes.func,
  ccBillingMethodName: PropTypes.string,
  setCcBillingMethodName: PropTypes.func,
  ccBillingMethodAction: PropTypes.func,
  ccCapture: PropTypes.bool,
  setCcCapture: PropTypes.func,
  ddCapture: PropTypes.bool,
  setDdCapture: PropTypes.func,
  preferredMethod: PropTypes.string,
  setPreferredMethod: PropTypes.func,
  bdm: PropTypes.string, // ID of bdm
};

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Paper, FormHelperText, Box } from "@mui/material";
import { TextField, Button, Utils } from "@eatclub-apps/ec-component-library";
import { Auth } from "aws-amplify";
import { COLORS } from "../theme";
import LogoImage from "../assets/logoEatClub.svg";

const CustomSignIn = ({ authSignedIn }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [signinLoading, setSigninLoading] = useState(false);
  const [signinError, setSigninError] = useState("");

  const styles = {
    paper: {
      maxWidth: "500px",
      margin: "80px auto 0",
      padding: "40px",
      backgroundColor: COLORS.CLOUD,
    },
    form: {
      margin: "0 auto",
    },
    textfield: {
      marginBottom: "30px",
    },
    logo: {
      width: "200px",
      display: "block",
      margin: "0 auto",
      marginBottom: "24px",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      return;
    }

    if (signinLoading) {
      console.error("Already signing in");
      return;
    }

    setSigninLoading(true);

    try {
      const user = await Auth.signIn(email.trim(), password);
      Utils.devLog("success", "user", user);
    } catch (error) {
      Utils.devLog("error", "user", error);
      setSigninError(error.message);
    } finally {
      setSigninLoading(false);
    }
  };

  if (authSignedIn) {
    return null;
  }

  return (
    <Paper style={styles.paper}>
      <form noValidate onSubmit={handleSubmit} style={styles.form} id="loginForm">
        <Box style={{ maxWidth: "200px", marginLeft: "auto", marginRight: "auto" }}>
          <LogoImage />
        </Box>
        <TextField
          label="Email"
          value={email}
          type="email"
          name="email"
          style={styles.textfield}
          onChange={(value) => setEmail(value)}
          onBlur={(e) => setEmail(e.target.value)}
          onEnter={(e) => {
            setEmail(e.target.value);
            handleSubmit(e);
          }}
          autoComplete
        />

        <TextField
          type="password"
          name="password"
          label="Password"
          value={password}
          style={styles.textfield}
          onChange={(value) => setPassword(value)}
          onBlur={(e) => setPassword(e.target.value)}
          onEnter={(e) => {
            setPassword(e.target.value);
            handleSubmit(e);
          }}
          autoComplete
        />

        {Boolean(signinError) && (
          <FormHelperText error style={{ marginBottom: "1rem", marginTop: 0 }}>
            {signinError}
          </FormHelperText>
        )}
        <Button
          text="Sign in"
          disabled={signinLoading}
          loading={signinLoading}
          fullWidth
          onClick={handleSubmit}
        />
      </form>
    </Paper>
  );
};

CustomSignIn.defaultProps = {
  authSignedIn: false,
};

CustomSignIn.propTypes = {
  authSignedIn: PropTypes.bool,
};

export default CustomSignIn;

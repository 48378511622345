import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import "../signupForm/SignupForm.css";
import { Button } from "@eatclub-apps/ec-component-library";

export const SignupFooter = (props) => {
  const { onNext, onPrevious, nextButtonText, backButtonText, isValid } = props;

  return (
    <Box className="footer">
      <Box className="container">
        <Box className="footer-content">
          {backButtonText && (
            <Button
              className="footer-button"
              style={{ marginRight: "auto" }}
              onClick={onPrevious}
              text="Back"
              type="secondary"
              resetFocus
            />
          )}
          {nextButtonText && (
            <Button
              className="footer-button"
              style={{ marginLeft: "auto" }}
              onClick={() => onNext()}
              text={nextButtonText}
              type="primary"
              disabled={!isValid}
              resetFocus
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

SignupFooter.defaultProps = {
  onNext: () => {},
  onPrevious: () => {},
  nextButtonText: "",
  backButtonText: "",
  isValid: true,
};

SignupFooter.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  nextButtonText: PropTypes.string,
  backButtonText: PropTypes.string,
  isValid: PropTypes.bool,
};

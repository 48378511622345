import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

export const AppLayout = (props) => {
  const { children } = props;

  return <Box className="app-container">{children}</Box>;
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "70px",
    maxWidth: "600px",
  },
  headerContainer: {
    borderBottom: `1px solid #313131`,
    paddingBottom: "6px",
    marginBottom: "12px",
  },
  header: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  editBtn: {
    fontSize: "18px",
    color: "#5A9488",
    "&:hover": {
      cursor: "pointer",
    },
  },
  desc: {
    fontSize: "18px",
    marginBottom: "30px",
  },
  sectionContainer: {
    marginBottom: "28px",
  },
  sectionHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  mediumText: {
    fontSize: "18px",
  },
  cdContainer: {
    marginBottom: "30px",
  },
  chkboxContainer: {
    marginBottom: "10px",
  },
  filledContainer: {
    marginBottom: "50px",
  },
  filledField: {
    display: "flex",
    gap: "14px",
    padding: "6px 0",
    fontSize: "18px",
    alignItems: "center",
  },
  filledlabel: {
    color: "#939393",
    flexShrink: 0,
    width: "170px",
  },
  fieldsContainer: {
    marginBottom: "50px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  prefContainer: {
    borderTop: "1px solid #CFCFCF",
    paddingBottom: "24px",
    paddingTop: "24px",
  },
  sectionHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnConatiner: {
    display: "flex",
    gap: "16px",
    justifyContent: "flex-end",
  },
  filledValue: {
    width: "100%",
  },
}));

export default useStyles;

import PropTypes from "prop-types";

// TODO replace this with updated form state object
export const formStateType = PropTypes.shape({
  newSignup: PropTypes.bool,
  hubspotDealIdEatClub: PropTypes.string,
  hubspotDealIdOrderDirect: PropTypes.string,
  restaurantId: PropTypes.string,
  signedBy: PropTypes.string,
  useEatClub: PropTypes.bool,
  useIAO: PropTypes.bool,
  useTakeawayVouchers: PropTypes.bool,
  differentTakeawayPricing: PropTypes.bool,
  dineinPricingType: PropTypes.string,
  dineinExclusive: PropTypes.bool,
  dineinFee: PropTypes.number,
  takeawayPricingType: PropTypes.string,
  takeawayExclusive: PropTypes.bool,
  takeawayFee: PropTypes.number,
  paymentFrequency: PropTypes.string,
  paymentAmount: PropTypes.number,
  aaaVenue: PropTypes.string,
  inbound: PropTypes.string,
  paymentMethod: PropTypes.string,
  partOfGroup: PropTypes.string,
  nameOfGroup: PropTypes.string,
});

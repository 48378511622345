import { ComboBox } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { formStateType } from "../../../types";
import {
  createCcSetupIntentAction,
  createDdSetupIntentAction,
  lookupPaymentMethodNameAction,
} from "../../../graphql/api-client";
import { useQuery } from "../../../helpers/Hooks";
import { COLORS } from "../../../theme";
import { SettlementDetailsWrapper } from "../../SettlementDetailsWrapper/SettlementDetailsWrapper";

export const PaymentDetailsPage = ({
  formState,
  setFieldValue,
  setFieldValues,
  saveFormData,
  isComplete,
}) => {
  // If we got a response from stripe, handle it
  const queryParams = useQuery();
  const error = queryParams.get("error");
  const errorMessage = queryParams.get("errorMessage");

  // Hacky solution to prevent race condition when both values are set at once
  let creditCardTempId = null;
  const setCreditCardName = (newValue) => {
    setFieldValues([
      { name: "paymentDetails.creditCardStripeId", value: creditCardTempId },
      { name: "paymentDetails.creditCardName", value: newValue },
    ]);
  };

  // Hacky solution to prevent race condition when both values are set at once
  let debitTempId = null;
  const setDebitName = (newValue) => {
    setFieldValues([
      { name: "paymentDetails.directDebitStripeId", value: debitTempId },
      { name: "paymentDetails.directDebitName", value: newValue },
    ]);
  };

  // Hacky solution to prevent race condition when both values are set at once
  let accountNameTemp = null;
  let bankBsbTemp = null;
  const setBankAccountNumber = (newValue) => {
    setFieldValues([
      { name: "paymentDetails.bankAccountName", value: accountNameTemp },
      { name: "paymentDetails.bsb", value: bankBsbTemp },
      { name: "paymentDetails.bankAccountNumber", value: newValue },
    ]);
  };

  const [signupErrorMessage, setSignupErrorMessage] = useState(errorMessage);

  const createCreditCardSetupIntent = async (name, stripe, cardElement) => {
    const billingMethodId = await createCcSetupIntentAction(
      name,
      stripe,
      cardElement,
      formState?.signupId,
    );
    const billingMethodName = await lookupPaymentMethodNameAction(billingMethodId);

    return { billingMethodId, billingMethodName };
  };

  const createDirectDebitSetupIntent = async (name, email, stripe, auBankAccountElement) => {
    const billingMethodId = await createDdSetupIntentAction(
      name,
      email,
      stripe,
      auBankAccountElement,
      formState?.signupId,
    );
    const billingMethodName = await lookupPaymentMethodNameAction(billingMethodId);

    return { billingMethodId, billingMethodName };
  };

  useEffect(() => {
    if (error || errorMessage) {
      // TODO alert cameron and allen
      console.log("error", error);
      setSignupErrorMessage(errorMessage);
    }
  }, [error, errorMessage]);

  return (
    <Box style={{ maxWidth: "980px" }}>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Settlement details</h1>
      </Box>
      <Box style={{ marginBottom: "55px" }}>
        <p className="paragraph-large">Update settlement details</p>
      </Box>
      {/*<Box style={{marginBottom: "60px"}} maxWidth="600px">*/}
      {/*  <Box style={{marginBottom: "20px"}}>*/}
      {/*    <h3 className="brand-primary">How payment works</h3>*/}
      {/*  </Box>*/}
      {/*  <ul>*/}
      {/*    <li>*/}
      {/*      For online orders, the customer will make a payment via the app. Our service fees are*/}
      {/*      then deducted from your weekly payout.*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      For offline bookings, we send a monthly invoice and direct debit the amount from the*/}
      {/*      account provided below.*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      All of these details are included in your weekly or monthly reporting and accessible via*/}
      {/*      your partner portal.*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</Box>*/}
      <SettlementDetailsWrapper
        header=""
        stripeId={import.meta.env.VITE_STRIPE_API_KEY} // TODO the component itself should be able to read this
        venueName={formState?.businessInfo?.tradingName}
        bankAccountName={formState.paymentDetails?.bankAccountName}
        setBankAccountName={(value) => {
          accountNameTemp = value;
        }}
        bankBSB={formState.paymentDetails?.bsb}
        setBankBSB={(value) => {
          bankBsbTemp = value;
        }}
        bankAccountNumber={formState.paymentDetails?.bankAccountNumber}
        setBankAccountNumber={setBankAccountNumber}
        ddBillingMethodId={formState.paymentDetails?.directDebitStripeId}
        setDdBillingMethodId={(value) => {
          debitTempId = value;
        }}
        ddBillingMethodName={formState.paymentDetails?.directDebitName}
        setDdBillingMethodName={setDebitName}
        ddBillingMethodAction={createDirectDebitSetupIntent} // on save direct debit
        ccBillingMethodId={formState.paymentDetails?.creditCardStripeId}
        setCcBillingMethodId={(value) => {
          creditCardTempId = value;
        }}
        ccBillingMethodName={formState.paymentDetails?.creditCardName}
        setCcBillingMethodName={setCreditCardName}
        ccBillingMethodAction={createCreditCardSetupIntent} // on save credit card
        ddCapture={formState.paymentDetails?.directDebitCapture}
        preferredMethod={formState.paymentDetails?.preferredMethod}
        setPreferredMethod={(value) => setFieldValue("paymentDetails.preferredMethod", value)}
        overrideCC={formState.overrideCC}
        bdm={formState.bdm}
        updateDetails={(newValues) => setFieldValues(newValues)}
      />

      <Box
        style={{
          borderBottom: `1px solid ${COLORS.CHARCOAL}`,
          paddingBottom: "12px",
          marginBottom: "12px",
        }}
      >
        <Box style={{ fontSize: "20px", fontWeight: 600 }}>
          Additional information required for stripe
        </Box>
      </Box>

      <ComboBox
        style={{ marginTop: "20px" }}
        label="Business type"
        placeholder="Select business type"
        sortAlphabetical
        items={[
          { label: "Company", value: "company" },
          { label: "Individual", value: "individual" },
          // { label: "Not for profit", value: "non_profit" },
        ]}
        onSelect={(newValue) => setFieldValue("businessInfo.businessType", newValue)}
        value={formState?.businessInfo?.businessType}
        clearOnOpen
        disabled={isComplete}
      />
    </Box>
  );
};

PaymentDetailsPage.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

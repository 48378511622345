import { ComboBox, RadioButtonGroup, TextField, Button } from "@eatclub-apps/ec-component-library";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { getApproximateGeolocationForAddress } from "../../../integrations/google-maps/GoogleMapsClient";
import { formStateType } from "../../../types";
import {
  restaurantTypes,
  regions,
  eatClubRegionsForState,
  bookingPlatforms,
  posSystems,
  australianStates,
} from "../../../data/ValueLists";
import { listBDMsRequest } from "../../../graphql/api-client";
import { TYPES } from "../../../helpers/InputTypes";
import { isECPayRegion, trim } from "../../../helpers/Helpers";
import { AddressSearch } from "../../AddressSearch/AddressSearch";

export const CreateRestaurant = ({ formState, setFieldValue, setFieldValues, isComplete }) => {
  // TODO take a prop like "onFieldUpdate" that passes the field name and value to the parent form

  // TODO if partner ID set, don't show create partner button

  const [findingGeolocation, setFindingGeolocation] = useState(false);
  const [bdmList, setBdmList] = useState([]);
  const [loadingBdms, setLoadingBdms] = useState(true);

  // Field values
  const [businessLegalName, setBusinessLegalName] = useState(formState?.businessInfo?.legalName);
  const [businessABN, setBusinessABN] = useState(formState?.businessInfo?.abn);
  const [businessACN, setBusinessACN] = useState(formState?.businessInfo?.acn);
  const [businessTradingName, setBusinessTradingName] = useState(
    formState?.businessInfo?.tradingName,
  );
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState(
    formState?.businessInfo?.phoneNumber,
  );
  const [businessWebsite, setBusinessWebsite] = useState(formState?.businessInfo?.website);
  const [businessAddressLine1, setBusinessAddressLine1] = useState(
    formState?.businessInfo?.addressLine1,
  );
  const [businessAddressLine2, setBusinessAddressLine2] = useState(
    formState?.businessInfo?.addressLine2,
  );
  const [businessCity, setBusinessCity] = useState(formState?.businessInfo?.city);
  const [businessRegion, setBusinessRegion] = useState(formState?.businessInfo?.region);
  const [businessPostcode, setBusinessPostcode] = useState(formState?.businessInfo?.postcode);
  const [eatclubRegion, setEatclubRegion] = useState(formState?.businessInfo?.eatclubRegion);
  const [businessLatitude, setBusinessLatitude] = useState(formState?.businessInfo?.latitude);
  const [businessLongitude, setBusinessLongitude] = useState(formState?.businessInfo?.longitude);

  const [accountHolderFirstName, setAccountHolderFirstName] = useState(
    formState?.accountHolder?.firstName,
  );
  const [accountHolderLastName, setAccountHolderLastName] = useState(
    formState?.accountHolder?.lastName,
  );
  const [accountHolderEmail, setAccountHolderEmail] = useState(formState?.accountHolder?.email);
  const [accountHolderJobTitle, setAccountHolderJobTitle] = useState(
    formState?.accountHolder?.jobTitle,
  );
  const [accountHolderDateOfBirth, setAccountHolderDateOfBirth] = useState(
    formState?.accountHolder?.dateOfBirth,
  );
  const [accountHolderPhoneNumber, setAccountHolderPhoneNumber] = useState(
    formState?.accountHolder?.phoneNumber,
  );
  const [accountHolderAddressLine1, setAccountHolderAddressLine1] = useState(
    formState?.accountHolder?.addressLine1,
  );
  const [accountHolderAddressLine2, setAccountHolderAddressLine2] = useState(
    formState?.accountHolder?.addressLine2,
  );
  const [accountHolderCity, setAccountHolderCity] = useState(formState?.accountHolder?.city);
  const [accountHolderRegion, setAccountHolderRegion] = useState(formState?.accountHolder?.region);
  const [accountHolderPostcode, setAccountHolderPostcode] = useState(
    formState?.accountHolder?.postcode,
  );

  const [customBookingSystem, setCustomBookingSystem] = useState(formState?.customBookingSystem);
  const [bookingsPlatformExpiry, setBookingsPlatformExpiry] = useState(
    formState?.bookingsPlatformExpiry,
  );
  const [customPos, setCustomPos] = useState(formState?.customPos);

  const [useCustomVenueAddress, setUseCustomVenueAddress] = useState(false);

  useEffect(() => {
    (async () => {
      const bdms = await listBDMsRequest();

      if (bdms.data) {
        setLoadingBdms(false);
        const newBdmList = bdms?.data?.map((bdm) => ({
          value: bdm.objectId,
          label: bdm.name,
        }));
        setBdmList(newBdmList);
      }
    })();
  }, []);

  const setPricingType = (pricingType) => {
    // TODO a lot of these values should be enums or come from a value list

    // Don't change the order type for ec pay regions
    if (pricingType === "qsr") {
      if (isECPayRegion(eatclubRegion)) {
        setFieldValues([
          { name: "fees.dineIn.amount", value: 2 },
          { name: "fees.takeawayOrders.amount", value: 2 },
          { name: "restaurantType", value: pricingType },
        ]);
      } else {
        setFieldValues([
          { name: "fees.dineIn.amount", value: 2 },
          { name: "fees.dineIn.pricingType", value: "diner" },
          { name: "fees.takeawayOrders.amount", value: 2 },
          { name: "fees.takeawayOrders.pricingType", value: "percent" },
          { name: "restaurantType", value: pricingType },
        ]);
      }
    } else if (pricingType === "sit_down") {
      if (isECPayRegion(eatclubRegion)) {
        setFieldValues([
          { name: "fees.dineIn.amount", value: 4 },
          { name: "fees.takeawayOrders.amount", value: 4 },
          { name: "restaurantType", value: pricingType },
        ]);
      } else {
        setFieldValues([
          { name: "fees.dineIn.amount", value: 4 },
          { name: "fees.dineIn.pricingType", value: "booking" },
          { name: "fees.takeawayOrders.amount", value: 4 },
          { name: "fees.takeawayOrders.pricingType", value: "percent" },
          { name: "restaurantType", value: pricingType },
        ]);
      }
    }
  };

  const setBDM = (value) => {
    setFieldValue("bdm", value);

    // Update local storage too
    localStorage.setItem("lastBDM", JSON.stringify(value));
  };

  // Put the last used BDM at the top for convenience
  // const lastBDM = null;
  const lastBDM = JSON.parse(localStorage.getItem("lastBDM"));
  const sortBDMs = (a, b) => {
    // Move last used BDM to the top
    if (lastBDM && (a.value === lastBDM || b.value === lastBDM)) {
      return a.value === lastBDM ? -1 : 1;
    }

    // Otherwise just sort alphabetically
    return a?.label > b?.label ? 1 : -1;
  };

  const setNewRegion = async (state, region, newAddressString = null, otherFieldValues = []) => {
    console.log("setting region", state, region);
    setEatclubRegion(region);
    if (region === null) {
      return;
    }

    let geolocation = {};
    const addressString =
      newAddressString ??
      [businessAddressLine1, businessAddressLine2, businessCity, businessRegion, "Australia"]
        .filter((item) => !!item)
        .join(", ");

    try {
      setFindingGeolocation(true);
      console.log("finding geolocation", addressString);
      geolocation = await getApproximateGeolocationForAddress(addressString);
      if (geolocation) {
        console.log("lat/lng", geolocation?.lat, geolocation?.lng);
        setFieldValues([
          ...otherFieldValues,
          { name: "businessInfo.latitude", value: geolocation?.lat },
          { name: "businessInfo.longitude", value: geolocation?.lng },
        ]);
      } else {
        console.error("failed to get a geolocation");
      }
    } catch (error) {
      console.log("%cMaps Error: ", "color: hotpink", error);
    } finally {
      setFindingGeolocation(false);
    }

    if (isECPayRegion(region)) {
      setFieldValues([
        ...otherFieldValues,
        { name: "businessInfo.region", value: state },
        { name: "businessInfo.eatclubRegion", value: region },
        { name: "fees.dineIn.pricingType", value: "percent" },
        { name: "fees.dineIn.differentOnlinePricing", value: true },
        { name: "fees.dineIn.onlineAmount", value: 10 },
        { name: "fees.takeawayOrders.pricingType", value: "percent" },
        { name: "fees.takeawayOrders.differentOnlinePricing", value: true },
        { name: "fees.takeawayOrders.onlineAmount", value: 10 },
        { name: "businessInfo.latitude", value: geolocation?.lat },
        { name: "businessInfo.longitude", value: geolocation?.lng },
      ]);
    } else {
      setFieldValues([
        ...otherFieldValues,
        { name: "businessInfo.region", value: state },
        { name: "businessInfo.eatclubRegion", value: region },
        { name: "fees.dineIn.pricingType", value: "booking" },
        { name: "fees.dineIn.differentOnlinePricing", value: false },
        { name: "businessInfo.latitude", value: geolocation?.lat },
        { name: "businessInfo.longitude", value: geolocation?.lng },
      ]);
    }
  };

  const updateVenueAddress = ({ address, suburb, state, postcode, country }) => {
    console.log("updating address", address, suburb, state, postcode);
    setBusinessAddressLine1(address);
    setBusinessCity(suburb);
    // setBusinessRegion(state);
    setBusinessPostcode(postcode);
    setBusinessRegion(state);

    const newFieldValues = [
      { name: "businessInfo.addressLine1", value: address },
      { name: "businessInfo.addressLine2", value: "" },
      { name: "businessInfo.city", value: suburb },
      { name: "businessInfo.postcode", value: postcode },
      { name: "businessInfo.region", value: state },
    ];

    setFieldValues(newFieldValues);

    const newAddressString = [address, suburb, state, postcode, "Australia"]
      .filter((item) => !!item)
      .join(", ");

    const regionsForState = state ? eatClubRegionsForState(state) : [];
    if (regionsForState.length === 1) {
      setNewRegion(state, regionsForState[0].value, newAddressString, newFieldValues);
    } else {
      setNewRegion(state, null, newAddressString, newFieldValues);
    }
  };

  return (
    <Box style={{ maxWidth: "980px" }}>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Partner sign up</h1>
      </Box>
      <Box style={{ marginBottom: "55px" }}>
        <p className="paragraph-large">
          These details will also be used to set up Stripe and Hubspot accounts.
        </p>
      </Box>
      <Box style={{ marginBottom: "40px" }}>
        <h3 className="brand-primary">Add new partner</h3>
      </Box>
      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "30px" }}>
          <h3>The Business</h3>
        </Box>
        <Box className="two-column">
          <TextField
            label="Legal business name"
            value={businessLegalName}
            onBlur={() => setFieldValue("businessInfo.legalName", trim(businessLegalName))}
            onChange={setBusinessLegalName}
            disabled={isComplete}
            className="text-field-component"
            // style={{width: 'calc(100% - 10px)', minWidth: '300px'}}
          />
          <TextField
            label="Trading name*"
            value={businessTradingName}
            onBlur={() => setFieldValue("businessInfo.tradingName", trim(businessTradingName))}
            onChange={setBusinessTradingName}
            disabled={isComplete}
          />
          <TextField
            label="ABN"
            value={businessABN}
            onBlur={() => setFieldValue("businessInfo.abn", trim(businessABN))}
            onChange={setBusinessABN}
            type={TYPES.NUMBER}
            maxCharacters={11}
            validation="numbers_only"
            disabled={isComplete}
            // style={{width: 'calc(100% - 10px)', minWidth: '300px'}}
          />
          <TextField
            label="ACN"
            value={businessACN}
            onBlur={() => setFieldValue("businessInfo.acn", trim(businessACN))}
            onChange={setBusinessACN}
            type={TYPES.NUMBER}
            maxCharacters={9}
            validation="numbers_only"
            disabled={isComplete}
            // style={{width: 'calc(100% - 10px)', minWidth: '300px'}}
          />
          <TextField
            label="Business phone number"
            value={businessPhoneNumber}
            onBlur={() => setFieldValue("businessInfo.phoneNumber", trim(businessPhoneNumber))}
            onChange={setBusinessPhoneNumber}
            disabled={isComplete}
          />
          <TextField
            label="Business website"
            value={businessWebsite}
            onBlur={() => setFieldValue("businessInfo.website", trim(businessWebsite))}
            onChange={setBusinessWebsite}
            disabled={isComplete}
            maxCharacters={255}
          />

          {!useCustomVenueAddress ? (
            <AddressSearch
              // error={businessAddressSearchError}
              // clearError={() => setBusinessAddressSearchError(null)}
              label="Business address"
              setValues={updateVenueAddress}
              onShowCustomAddress={setUseCustomVenueAddress}
              values={{
                address: businessAddressLine1,
                suburb: businessCity,
                state: businessRegion,
                postcode: businessPostcode,
                // country: "au",
              }}
            />
          ) : (
            <>
              <TextField
                label="Business address line 1"
                value={businessAddressLine1}
                onBlur={() =>
                  setFieldValue("businessInfo.addressLine1", trim(businessAddressLine1))
                }
                onChange={setBusinessAddressLine1}
                disabled={isComplete}
              />
              <TextField
                label="Business address line 2"
                value={businessAddressLine2}
                onBlur={() =>
                  setFieldValue("businessInfo.addressLine2", trim(businessAddressLine2))
                }
                onChange={setBusinessAddressLine2}
                disabled={isComplete}
              />
              <TextField
                label="Business suburb"
                value={businessCity}
                onBlur={() => setFieldValue("businessInfo.city", trim(businessCity))}
                onChange={setBusinessCity}
                disabled={isComplete}
              />
              <TextField
                label="Business postcode"
                value={businessPostcode}
                onBlur={() => setFieldValue("businessInfo.postcode", trim(businessPostcode))}
                onChange={setBusinessPostcode}
                disabled={isComplete}
                maxCharacters={4}
                validation="numbers_only"
              />
              <ComboBox
                label="Business state"
                placeholder="Select a state"
                sortAlphabetical
                items={regions()}
                value={businessRegion}
                disabled={isComplete}
                onSelect={(value) => {
                  // Auto set eatclub region if we can
                  const regionsForState = value ? eatClubRegionsForState(value) : [];
                  if (regionsForState.length === 1) {
                    setBusinessRegion(value);
                    setNewRegion(value, regionsForState[0].value);
                  } else {
                    setBusinessRegion(value);
                    setNewRegion(value);
                  }
                }}
                clearOnOpen
              />
            </>
          )}

          <ComboBox
            label="EatClub region"
            placeholder="Select a region"
            tooltip="Which region EatClub customers have to be set to to view the restaurant. (e.g. Brisbane vs Gold Coast)"
            sortAlphabetical
            items={businessRegion ? eatClubRegionsForState(businessRegion) : []}
            value={eatclubRegion}
            onSelect={(value) => setNewRegion(businessRegion, value)}
            disabled={
              !businessRegion || eatClubRegionsForState(businessRegion)?.length <= 1 || isComplete
            }
            clearOnOpen
          />

          {useCustomVenueAddress && (
            <Button
              type="text"
              text="Use automatic address lookup"
              onClick={() => setUseCustomVenueAddress(false)}
              // style={theme.components.textButton}
            />
          )}
          {/*<TextField*/}
          {/*  label="Latitude"*/}
          {/*  type={TYPES.TEXT} // Since we need to allow for negative numbers*/}
          {/*  minNumber={-90}*/}
          {/*  maxNumber={90}*/}
          {/*  value={businessLatitude}*/}
          {/*  onBlur={() => setFieldValue("businessInfo.latitude", parseFloat(businessLatitude))}*/}
          {/*  onChange={setBusinessLatitude}*/}
          {/*  disabled={findingGeolocation || isComplete}*/}
          {/*/>*/}
          {/*<TextField*/}
          {/*  label="Longitude"*/}
          {/*  type={TYPES.TEXT} // Since we need to allow for negative numbers*/}
          {/*  minNumber={-180}*/}
          {/*  maxNumber={180}*/}
          {/*  value={businessLongitude}*/}
          {/*  onBlur={() => setFieldValue("businessInfo.longitude", parseFloat(businessLongitude))}*/}
          {/*  onChange={setBusinessLongitude}*/}
          {/*  disabled={findingGeolocation || isComplete}*/}
          {/*/>*/}
        </Box>
      </Box>

      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "30px" }}>
          <h3>Signing authority</h3>
        </Box>
        <Box className="two-column" style={{ display: "flex" }}>
          <TextField
            label="First name"
            value={accountHolderFirstName}
            onBlur={() => setFieldValue("accountHolder.firstName", trim(accountHolderFirstName))}
            onChange={setAccountHolderFirstName}
            disabled={isComplete}
          />
          <TextField
            label="Last name"
            value={accountHolderLastName}
            onBlur={() => setFieldValue("accountHolder.lastName", trim(accountHolderLastName))}
            onChange={setAccountHolderLastName}
            autoCorrect={true}
            disabled={isComplete}
          />
          <TextField
            label="Email"
            value={accountHolderEmail}
            onBlur={() =>
              setFieldValues([
                { name: "accountHolder.email", value: trim(accountHolderEmail) },
                { name: "username", value: trim(accountHolderEmail) },
              ])
            }
            onChange={setAccountHolderEmail}
            type={TYPES.EMAIL}
            disabled={isComplete}
          />
          <TextField
            label="Job title"
            value={accountHolderJobTitle}
            onBlur={() => setFieldValue("accountHolder.jobTitle", trim(accountHolderJobTitle))}
            onChange={setAccountHolderJobTitle}
            autoCapitalize="sentences" // TODO make this an enum
            disabled={isComplete}
          />
          <TextField
            label="Date of birth"
            value={accountHolderDateOfBirth}
            onBlur={() =>
              setFieldValue("accountHolder.dateOfBirth", trim(accountHolderDateOfBirth))
            }
            onChange={setAccountHolderDateOfBirth}
            placeholder="DD/MM/YYYY"
            disabled={isComplete}
            format={(inputValue) => {
              // TODO this is messy. Validate differently

              if (formState?.accountHolder?.dateOfBirth?.length > inputValue?.length) {
                return inputValue;
              }

              let newValue = inputValue;

              // auto-add slashes
              if (newValue?.length === 2 || newValue?.length === 5) {
                newValue += "/";
              }
              return newValue;
            }}
            maxCharacters={10} // including slashes
          />
          <TextField
            label="Phone number"
            value={accountHolderPhoneNumber}
            onBlur={() =>
              setFieldValue("accountHolder.phoneNumber", trim(accountHolderPhoneNumber))
            }
            onChange={setAccountHolderPhoneNumber}
            disabled={isComplete}
          />
          <TextField
            label="Address line 1"
            value={accountHolderAddressLine1}
            onBlur={() =>
              setFieldValue("accountHolder.addressLine1", trim(accountHolderAddressLine1))
            }
            onChange={setAccountHolderAddressLine1}
            disabled={isComplete}
          />
          <TextField
            label="Address line 2"
            value={accountHolderAddressLine2}
            onBlur={() =>
              setFieldValue("accountHolder.addressLine2", trim(accountHolderAddressLine2))
            }
            onChange={setAccountHolderAddressLine2}
            disabled={isComplete}
          />
          <TextField
            label="Suburb"
            value={accountHolderCity}
            onBlur={() => setFieldValue("accountHolder.city", trim(accountHolderCity))}
            onChange={setAccountHolderCity}
            disabled={isComplete}
          />
          <TextField
            label="Postcode"
            value={accountHolderPostcode}
            onBlur={() => setFieldValue("accountHolder.postcode", trim(accountHolderPostcode))}
            onChange={setAccountHolderPostcode}
            disabled={isComplete}
            maxCharacters={4}
            validation="numbers_only"
          />
          <ComboBox
            label="State"
            placeholder="Select a state"
            sortAlphabetical
            items={australianStates}
            value={accountHolderRegion}
            disabled={isComplete}
            onSelect={(value) => {
              setAccountHolderRegion(value);
              setFieldValue("accountHolder.region", value);
            }}
            clearOnOpen
          />
        </Box>
      </Box>
      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "30px" }}>
          <h3>EatClub sales and support</h3>
        </Box>
        <Box className="two-column" style={{ display: "flex" }}>
          <ComboBox
            label="Business development manager"
            placeholder="Select BDM"
            items={bdmList}
            // sortAlphabetical
            sortFunction={sortBDMs}
            value={formState?.bdm}
            onSelect={setBDM}
            disabled={loadingBdms || isComplete}
          />
        </Box>
      </Box>

      <Box style={{ marginBottom: "50px", display: "flex", flexDirection: "column", gap: "30px" }}>
        <h3>Additional questions</h3>

        <RadioButtonGroup
          style={{ label: { marginBottom: "20px" } }}
          label="What is the restaurant type?"
          items={restaurantTypes()}
          onSelect={setPricingType}
          value={formState.restaurantType}
          disabled={isComplete}
        />

        <ComboBox
          label="POS system"
          placeholder="Select POS system"
          items={posSystems}
          itemsWhenNone={[{ label: "Other", value: "Other" }]}
          // sortAlphabetical
          value={formState?.posSystem}
          onSelect={(value) => setFieldValue("posSystem", value)}
          disabled={isComplete}
        />

        {formState?.posSystem === "Other" && (
          <TextField
            style={{ maxWidth: "500px" }}
            label="Please enter the name of the POS system"
            value={customPos}
            onBlur={() => setFieldValue("customPos", trim(customPos))}
            onChange={setCustomPos}
            disabled={isComplete}
          />
        )}

        <ComboBox
          label="Current booking system"
          placeholder="Select booking system"
          items={bookingPlatforms}
          itemsWhenNone={[{ label: "Other", value: "Other" }]}
          // sortAlphabetical
          value={formState?.bookingSystem}
          onSelect={(value) => setFieldValue("bookingSystem", value)}
          disabled={isComplete}
        />

        {formState?.bookingSystem === "Other" && (
          <TextField
            style={{ maxWidth: "500px" }}
            label="Please enter the name of the booking system"
            value={customBookingSystem}
            onBlur={() => setFieldValue("customBookingSystem", trim(customBookingSystem))}
            onChange={setCustomBookingSystem}
            disabled={isComplete}
          />
        )}

        <TextField
          label="Booking system expiry"
          value={bookingsPlatformExpiry}
          onBlur={() => setFieldValue("bookingsPlatformExpiry", trim(bookingsPlatformExpiry))}
          onChange={setBookingsPlatformExpiry}
          style={{ maxWidth: "300px" }}
          placeholder="DD/MM/YYYY"
          disabled={isComplete}
          format={(inputValue) => {
            // TODO this is messy. Validate differently

            if (formState?.owner?.dateOfBirth?.length > inputValue?.length) {
              return inputValue;
            }

            let newValue = inputValue;

            // auto-add slashes
            if (newValue?.length === 2 || newValue?.length === 5) {
              newValue += "/";
            }
            return newValue;
          }}
          maxCharacters={10} // including slashes
        />
      </Box>
    </Box>
  );
};

CreateRestaurant.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
};

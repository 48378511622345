const awsmobile = {
  aws_appsync_graphqlEndpoint: import.meta.env.VITE_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: import.meta.env.VITE_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: import.meta.env.VITE_AWS_APPSYNC_AUTHENTICATIONTYPE,
  aws_appsync_apiKey: import.meta.env.VITE_AWS_APPSYNC_APIKEY,
  aws_user_pools_id: import.meta.env.VITE_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export default awsmobile;

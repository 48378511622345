import API from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Utils } from "@eatclub-apps/ec-component-library";
import {
  getLabelForValue,
  weekdaysShort,
  getValueForLabel,
  bookingPlatforms,
  posSystems,
} from "../data/ValueLists";
import {
  createCCSetupIntent,
  createDDSetupIntent,
  overrideCCForSignup,
  saveSignup,
} from "./mutations";
import {
  searchVenues,
  signupForId,
  listBdms,
  getImageUploadUrl,
  getSignatureUploadUrl,
  partnerForEmail,
  lookupPaymentMethodName,
  ecBookingsSlugExists,
} from "./queries";
import {
  convertDateFormat,
  getAppVersion,
  isECPayRegion,
  obscureString,
  parsePhoneNumber,
  parseURL,
  isEmpty,
} from "../helpers/Helpers";

export const SEARCH_RESULT_TYPE = {
  HUBSPOT: "HUBSPOT",
  ECVENUE: "ECVENUE",
  IN_PROGRESS: "SIGNUP_INPROGRESS",
  COMPLETED: "SIGNUP_COMPLETED",
};

export const ApiClient = () => {};

/**
 * An extra layer over the request to try to handle
 * global cases such as user not being logged in.
 */
const makeGraphQLOperation = async (operation, data) => {
  try {
    // Need to have a user id
    let userId = localStorage.getItem("userId");
    if (!userId) {
      Auth.currentAuthenticatedUser({ bypassCache: true }).then((response) => {
        userId = response?.attributes["custom:objectId"];
        localStorage.setItem("userId", userId);
      });
    }

    const response = await API.graphql(
      {
        query: operation,
        variables: data,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      },
      {
        "user-id": userId,
        "app-version": getAppVersion(),
      },
    );

    Utils.devLog("success", "API Response:", response);
    console.log("%cSUCCESS API Response:", "color: lawngreen", response);

    return {
      data: response.data,
      error: null,
    };
  } catch (error) {
    const errorMessage = error.message || error?.errors[0]?.message;
    Utils.devLog("error", "Error:", errorMessage);
    console.log("%cERROR: ", "color: hotpink", errorMessage);

    // TODO show error in snackbar

    return {
      data: null,
      error: errorMessage,
    };
  }
};

export const fetchRestaurantsByName = async (name, inProgressOnly = false) => {
  const response = await makeGraphQLOperation(searchVenues, {
    searchText: name,
    inProgressOnly,
  });

  return {
    data: response.data?.searchVenues || [],
    error: response.error,
  };
};

export const getPartnerSignupForId = async (id) => {
  const response = await makeGraphQLOperation(signupForId, { signupId: id });

  let mappedSignup = {};
  if (response.data?.signupForId) {
    mappedSignup = mapApiDataToFrontendData(response.data?.signupForId);
  }

  return {
    data: mappedSignup,
    error: response.error,
  };
};

export const saveSignupApplication = async (data) => {
  const response = await makeGraphQLOperation(saveSignup, data);

  return {
    data: response.data?.saveSignup,
    error: response.error,
  };
};

export const listBDMsRequest = async (data) => {
  const response = await makeGraphQLOperation(listBdms, data);

  return {
    data: response.data?.listBdms,
    error: response.error,
  };
};

export const getPartnerForEmail = async (data) => {
  const response = await makeGraphQLOperation(partnerForEmail, data);

  return {
    data: response.data?.partnerForEmail,
    error: response.error,
  };
};

export const checkPartnerExists = async (data) => {
  const response = await makeGraphQLOperation(partnerForEmail, data);

  console.log("partner response", response);

  // if partner does exist, return it

  // Yikes
  return response?.error !== "Partner not found.";
};

export const checkSlugExists = async (data) => {
  const response = await makeGraphQLOperation(ecBookingsSlugExists, data);

  // console.log("slug check response", response, response?.data?.ecBookingsSlugExists);
  return JSON.parse(response?.data?.ecBookingsSlugExists)?.exists;
};

export const uploadImage = async (signupId, image, filename, type, contentType = "png") => {
  // Create filename
  const fileNameForUpload = `${signupId}_${filename}_${Date.now()}.${contentType}`;

  const urlData = {
    signupId,
    fileName: fileNameForUpload,
  };

  let response;
  let data;
  switch (type) {
    case "signature":
      response = await makeGraphQLOperation(getSignatureUploadUrl, urlData);
      data = response?.data?.getSignatureUploadUrl;
      break;
    default:
      response = await makeGraphQLOperation(getImageUploadUrl, urlData);
      data = response?.data?.getImageUploadUrl;
      break;
  }

  let error = response?.error;

  console.log("re", response);

  // Upload the image to the URL
  if (!error && data?.uploadUrl) {
    try {
      await axios({
        headers: {
          accept: "image/*",
          "Content-Type": "image/*",
        },
        method: "put",
        url: data?.uploadUrl,
        data: image,
      }).catch((imageUploadError) => {
        console.log("image error", imageUploadError);
        error = imageUploadError;
      });
    } catch (imageUploadError) {
      console.log("image failed to upload", imageUploadError);
      error = imageUploadError;
    }
  }

  return {
    data,
    error,
  };
};

export const lookupPaymentMethodNameAction = async (paymentMethodId) => {
  const response = await makeGraphQLOperation(lookupPaymentMethodName, { paymentMethodId });
  Utils.devLog("success", "fetch payment method name", response.data.lookupPaymentMethodName);

  const parsedResponse = JSON.parse(response.data.lookupPaymentMethodName);
  const { paymentMethodName } = parsedResponse;

  return paymentMethodName;
};

export const createCcSetupIntentAction = async (name, stripe, cardElement, signupId) => {
  const response = await makeGraphQLOperation(createCCSetupIntent, { signupId });

  if (response?.error) {
    throw new Error(response?.error);
  }

  const parsedResponse = JSON.parse(response.data?.createCCSetupIntent);
  const clientSecret = parsedResponse?.secret;

  const result = await stripe.confirmCardSetup(clientSecret, {
    payment_method: {
      card: cardElement,
      billing_details: {
        name,
      },
    },
  });

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result?.setupIntent?.payment_method;
};

export const createDdSetupIntentAction = async (
  name,
  email,
  stripe,
  auBankAccountElement,
  signupId,
) => {
  const response = await makeGraphQLOperation(createDDSetupIntent, { signupId });

  if (response?.error) {
    throw new Error(response?.error);
  }

  const parsedResponse = JSON.parse(response.data?.createDDSetupIntent);
  const clientSecret = parsedResponse?.secret;

  const result = await stripe.confirmAuBecsDebitSetup(clientSecret, {
    payment_method: {
      au_becs_debit: auBankAccountElement,
      billing_details: {
        name,
        email,
      },
    },
  });

  if (result.error) {
    throw new Error(result.error.message);
  }

  return result?.setupIntent?.payment_method;
};

export const overrideCCForSignupAction = async (signupId) => {
  const response = await makeGraphQLOperation(overrideCCForSignup, { signupId });

  if (response?.error) {
    throw new Error(response?.error);
  }

  return response?.data?.overrideCCForSignup;
};

const shiftsForDay = (apiHours, day) => {
  console.log();

  let shifts = [];

  const open = apiHours[`${day}Open`];
  const close = apiHours[`${day}Close`];
  const openEx = apiHours[`${day}OpenEx`];
  const closeEx = apiHours[`${day}CloseEx`];
  const hasOpen = open !== -1;
  const hasClose = close !== -1;
  const hasOpenEx = openEx !== -1;
  const hasCloseEx = closeEx !== -1;

  if (!hasOpen && !hasClose && !hasOpenEx && !hasCloseEx) {
    // Closed
    return {
      isOpen: false,
      shifts: [{ start: null, end: null, id: 0 }],
    };
  }

  // Check for a split shift
  if (hasOpenEx || hasCloseEx) {
    // We have split
    shifts = [
      {
        start: hasOpen ? open : null,
        end: hasOpenEx ? openEx : null,
        id: 0,
      },
      {
        start: hasCloseEx ? closeEx : null,
        end: hasClose ? close : null,
        id: 1,
      },
    ];
  } else {
    // No split
    shifts = [
      {
        start: hasOpen ? open : null,
        end: hasClose ? close : null,
        id: 0,
      },
    ];
  }

  return {
    isOpen: true,
    shifts,
  };
};

// TODO remove export
export const dayShiftsToAPIFormat = (dayHours, day) => {
  const hours = {};
  hours[`${day}Open`] = -1;
  hours[`${day}Close`] = -1;
  hours[`${day}OpenEx`] = -1;
  hours[`${day}CloseEx`] = -1;

  // If not open
  if (dayHours.isOpen === false) {
    return hours;
  }

  if (dayHours.shifts.length !== 2) {
    // If only one shift, simple start and end
    hours[`${day}Open`] = dayHours.shifts[0]?.start ?? -1;
    hours[`${day}Close`] = dayHours.shifts[0]?.end ?? -1;
  } else {
    // If two shifts, add the split
    hours[`${day}Open`] = dayHours.shifts[0]?.start ?? -1;
    hours[`${day}OpenEx`] = dayHours.shifts[0]?.end ?? -1;
    hours[`${day}CloseEx`] = dayHours.shifts[1]?.start ?? -1;
    hours[`${day}Close`] = dayHours.shifts[1]?.end ?? -1;
  }

  return hours;
};

export const emailSettingsFromAPIFormat = (emailSettings) => {
  const converted = {
    marketing: {
      isUsed: false,
      emailAddresses: [],
    },
    orders: {
      isUsed: false,
      emailAddresses: [],
    },
    invoices: {
      isUsed: false,
      emailAddresses: [],
    },
    oppos: {
      isUsed: false,
      emailAddresses: [],
    },
  };
  emailSettings.forEach((emailSetting) => {
    const category = stringFromAPIEnum(emailSetting?.category);
    converted[category].isUsed = true;
    converted[category].emailAddresses = [
      ...converted[category]?.emailAddresses,
      emailSetting.email,
    ];
  });
  console.log("converted", converted);

  return converted;
};

const dealsToAPIFormat = (deals) =>
  deals.map((deal) => {
    let diningOptions = [];
    if (deal.dineIn) {
      diningOptions = [...diningOptions, "dine_in"];
    }
    if (deal.takeaway) {
      diningOptions = [...diningOptions, "takeaway"];
    }

    return {
      percentage: parseInt(deal.discount, 10),
      amount: parseInt(deal.qty, 10),
      timeType: deal.timeLimited ? "time_limited" : "all_day",
      startDate: deal.startDate,
      startTime: deal?.timeLimited === "all_day" ? 0 : deal.startTime,
      endTime: deal?.timeLimited === "all_day" ? 1440 : deal.endTime,
      repeatType: deal.recurring ? "repeat" : "onetime",
      repeatDays: deal.recurringDays.map((day) => getValueForLabel(day, weekdaysShort())),
      diningOptions,
    };
  });

const mapApiDataToFrontendData = (apiData) => {
  console.log("mapping data from API", apiData);

  const hours = JSON.parse(apiData.hours);

  const openingHours = {
    monday: shiftsForDay(hours, "mon"),
    tuesday: shiftsForDay(hours, "tue"),
    wednesday: shiftsForDay(hours, "wed"),
    thursday: shiftsForDay(hours, "thu"),
    friday: shiftsForDay(hours, "fri"),
    saturday: shiftsForDay(hours, "sat"),
    sunday: shiftsForDay(hours, "sun"),
  };

  const getBookingsPlatform = () => {
    if (isEmpty(apiData?.bookingsPlatform)) {
      return null;
    }

    if (getLabelForValue(apiData?.bookingsPlatform, bookingPlatforms) === "") {
      return "Other";
    }

    return apiData?.bookingsPlatform;
  };

  const getPosSystem = () => {
    if (isEmpty(apiData?.posSystem)) {
      return null;
    }

    if (getLabelForValue(apiData?.posSystem, posSystems) === "") {
      return "Other";
    }

    return apiData?.posSystem;
  };

  // Pricing notes:
  // For EC Pay venues, type is percent if amount is 0 and different online pricing is used
  // For non ec pay venues, type cannot be percent
  const ecPayRegion = isECPayRegion(apiData?.region);

  return {
    businessInfo: {
      id: apiData.restId,
      businessType: apiData?.businessType,
      legalName: apiData.businessName,
      abn: apiData.businessABN,
      acn: apiData.businessACN,
      tradingName: apiData.businessDBA,
      phoneNumber: apiData.businessPhone,
      addressLine1: apiData.businessAddress1,
      addressLine2: apiData.businessAddress2,
      city: apiData.businessCity,
      region: apiData.businessState,
      postcode: apiData.businessPostcode,
      eatclubRegion: apiData.region,
      latitude: apiData.latitude,
      longitude: apiData.longitude,
      website: apiData?.businessWebsite,
    },
    accountHolder: {
      firstName: apiData?.ownerFirstName,
      lastName: apiData?.ownerLastName,
      dateOfBirth: convertDateFormat(apiData.ownerBirthday, "YYYY-MM-DD", "DD/MM/YYYY"),
      addressLine1: apiData?.ownerAddress1,
      addressLine2: apiData?.ownerAddress2,
      city: apiData?.ownerCity,
      region: apiData?.ownerState,
      postcode: apiData?.ownerPostcode,
      email: apiData.ownerEmail,
      jobTitle: apiData.ownerTitle,
      homeAddress: apiData.ownerHomeAddress,
      phoneNumber: apiData.ownerPhone,
    },
    bdm: apiData?.bdmId,
    restaurantType: stringFromAPIEnum(apiData.restaurantType), // Sit down or QSR. Make sure this is used to set default pricing
    services: {
      bookings: {
        enabled: apiData?.ecBookings,
        plan: apiData?.ecBookings || "lite",
        slug: apiData?.ecBookingsSlug,
        email: apiData?.ecBookingsOperationalEmail || "",
      },
      takeaway: {
        takeawayVouchers: apiData.offlineTakeaway,
        takeawayIAO: apiData.inAppOrdering, // TODO should be separate
      },
      dineIn: {
        dineInVouchers: apiData.offlineDineIn,
        tableOrders: apiData.inAppOrdering, // TODO should be separate
      },
      whiteLabel: {
        tableOrdering: apiData.odTableOrdering,
        takeaway: apiData.odTakeaway,
        delivery: apiData.odDelivery,
      },
    },
    fees: {
      dineIn: {
        amount:
          apiData?.dineInFee === -1
            ? 4 // A nice default in case the user changes it
            : convertCentsToDollars(apiData?.dineInFee),
        pricingType:
          apiData?.dineInOnlinePercent !== -1 && apiData?.dineInFee === -1
            ? "percent"
            : stringFromAPIEnum(apiData?.dineInFeeType) || "booking",
        agreement: stringFromAPIEnum(apiData?.dineInAgreement),
        differentOnlinePricing: apiData?.dineInOnlinePercent !== -1,
        onlineAmount: apiData?.dineInOnlinePercent === -1 ? 10 : apiData?.dineInOnlinePercent,
      },
      takeawayOrders: {
        amount:
          apiData?.takeawayFee === -1
            ? 4 // A nice default in case the user changes it
            : convertCentsToDollars(apiData?.takeawayFee),
        pricingType:
          apiData?.takeawayOnlinePercent !== -1 && apiData?.takeawayFee === -1
            ? "percent"
            : stringFromAPIEnum(apiData?.dineInFeeType) || "booking",
        agreement: stringFromAPIEnum(apiData?.takeawayAgreement),
        differentOnlinePricing: apiData?.takeawayOnlinePercent !== -1,
        onlineAmount: apiData?.takeawayOnlinePercent === -1 ? 10 : apiData?.takeawayOnlinePercent,
      },
      whiteLabel: {
        amount: convertCentsToDollars(apiData?.odFee),
        frequency: "monthly",
      },
      setup: {
        amount: convertCentsToDollars(apiData?.setupFee),
        frequency: "onetime",
      },
      membership: {
        amount: convertCentsToDollars(apiData?.membershipFee),
      },
      pricingNotes: apiData.pricingNotes,
    },
    openingHours,
    communication: emailSettingsFromAPIFormat(apiData.emailSettings),
    operations: {
      preparationTime: apiData.typicalEta,
      wifiAvailable: apiData.wifiAvailable,
      tabletRequired: apiData.tabletRequired,
      pdfLink: apiData.pdfMenu,
      menuImageLink: apiData.menuImages,
    },
    deals: dealsToAPIFormat(apiData.deals),
    orderDirect: {
      logo: apiData.odLogoLink,
      heroImage: apiData.odHeroLink,
      menuImageLink: apiData.odMenuImages,
      tableAmount: apiData.odTableCount,
      brandColor: apiData.odPrimaryColor,
      slug: apiData.odSlug,
    },
    // marketing: {
    //   emailFeature: false,
    //   emailMention: false,
    //   facebookPost: false,
    //   pushNotifications: false,
    //   fullPackage: false,
    // },
    paymentDetails: {
      // DANGER
      bankAccountName: apiData.bankAccountName,
      bsb: apiData.bankBSB,
      bankAccountNumber: apiData.bankAccountNumber,
      creditCardCapture: !isEmpty(apiData?.ccBillingMethodId),
      directDebitCapture: !isEmpty(apiData?.ddBillingMethodId),
      creditCardName: apiData?.ccBillingMethodName,
      directDebitName: apiData?.ddBillingMethodName,
      creditCardStripeId: apiData?.ccBillingMethodId,
      directDebitStripeId: apiData?.ddBillingMethodId,
      preferredMethod: apiData?.preferredBillingMethod,
    },
    ccOverride: apiData?.ccOverride || false,
    username: apiData.loginUsername,
    password: null,
    signature: apiData?.signatureImageLink,
    signupId: apiData.objectId,
    completionDate: apiData?.completionDate,
    contractPdfLink: apiData?.contractPdfLink,
    bookingSystem: getBookingsPlatform(),
    bookingsPlatformExpiry: convertDateFormat(
      apiData.bookingsPlatformExpiry,
      "YYYY-MM-DD",
      "DD/MM/YYYY",
    ),
    posSystem: getPosSystem(),
    customPos: getBookingsPlatform() === "Other" ? apiData.posSystem : null,
    customBookingSystem: getBookingsPlatform() === "Other" ? apiData.bookingsPlatform : null,
  };
};

const convertHoursToApiFormat = (openingHours) => ({
  ...dayShiftsToAPIFormat(openingHours?.monday, "mon"),
  ...dayShiftsToAPIFormat(openingHours?.tuesday, "tue"),
  ...dayShiftsToAPIFormat(openingHours?.wednesday, "wed"),
  ...dayShiftsToAPIFormat(openingHours?.thursday, "thu"),
  ...dayShiftsToAPIFormat(openingHours?.friday, "fri"),
  ...dayShiftsToAPIFormat(openingHours?.saturday, "sat"),
  ...dayShiftsToAPIFormat(openingHours?.sunday, "sun"),
});

const convertDollarsToCents = (dollarValue) => dollarValue * 100;
const convertCentsToDollars = (centValue) => centValue / 100;

const APIEnumFromString = (str) => {
  const enums = {
    qsr: "QSR",
    sit_down: "SITDOWN",
    exclusive: "EXCLUSIVE",
    non_exclusive: "STANDARD",
    marketing: "MARKETING",
    orders: "ORDERS",
    invoices: "INVOICES",
    oppos: "OPPOS",
    hubspot: "HUBSPOT",
    ecVenue: "ECVENUE",
    signupInProgress: "SIGNUP_INPROGRESS",
    signupCompleted: "SIGNUP_COMPLETED",
    booking: "BOOKING",
    diner: "DINER",
    transaction_fee: "TRANSACTIONFEE",
  };

  return enums?.[str];
};

const stringFromAPIEnum = (str) => {
  const enums = {
    QSR: "qsr",
    SITDOWN: "sit_down",
    EXCLUSIVE: "exclusive",
    STANDARD: "non_exclusive",
    MARKETING: "marketing",
    ORDERS: "orders",
    INVOICES: "invoices",
    OPPOS: "oppos",
    HUBSPOT: "hubspot",
    ECVENUE: "ecVenue",
    SIGNUP_INPROGRESS: "signupInProgress",
    SIGNUP_COMPLETED: "signupCompleted",
    BOOKING: "booking",
    DINER: "diner",
    TRANSACTIONFEE: "transaction_fee",
  };

  return enums?.[str];
};

/**
 * Map the form data from the format used on the frontend to that of the backend.
 * TODO this should be somewhere closer to the api client
 */
export const mapFormDataForAPI = (data) => {
  console.log("mapping data", data);

  const bookingsOnly =
    data?.services?.bookings?.enabled && data?.services?.bookings?.plan === "bookingsOnly";

  const ecPayRegion = isECPayRegion(data?.businessInfo?.region);

  // console.log("updated hours:", convertHoursToApiFormat(data?.openingHours));

  const mappedData = {
    objectId: data?.signupId,
    restId: data?.businessInfo?.id,
    businessName: data?.businessInfo?.legalName,
    businessAddress1: data?.businessInfo?.addressLine1,
    businessAddress2: data?.businessInfo?.addressLine2,
    businessCity: data?.businessInfo?.city,
    businessState: data?.businessInfo?.region,
    businessPostcode: data?.businessInfo?.postcode,
    businessDBA: data?.businessInfo?.tradingName,
    businessABN: data?.businessInfo?.abn,
    businessACN: data?.businessInfo?.acn,
    businessPhone: parsePhoneNumber(data?.businessInfo?.phoneNumber),
    businessWebsite: parseURL(data?.businessInfo?.website),
    businessType: data?.businessInfo?.businessType,
    region: data?.businessInfo?.eatclubRegion,
    latitude: parseFloat(data?.businessInfo?.latitude),
    longitude: parseFloat(data?.businessInfo?.longitude),
    ownerFirstName: data?.accountHolder?.firstName,
    ownerLastName: data?.accountHolder?.lastName,
    ownerEmail: data?.accountHolder?.email,
    ownerTitle: data?.accountHolder?.jobTitle,
    ownerBirthday: convertDateFormat(data?.accountHolder.dateOfBirth, "DD/MM/YYYY", "YYYY-MM-DD"),
    ownerAddress1: data?.accountHolder?.addressLine1,
    ownerAddress2: data?.accountHolder?.addressLine2,
    ownerCity: data?.accountHolder?.city,
    ownerState: data?.accountHolder?.region,
    ownerPostcode: data?.accountHolder?.postcode,
    ownerHomeAddress: data?.accountHolder?.homeAddress,
    ownerPhone: parsePhoneNumber(data?.accountHolder?.phoneNumber),
    // bdmName: data?.bdm,
    bdmId: data?.bdm,
    restaurantType: APIEnumFromString(data?.restaurantType),
    qtrOwner: data?.paymentDetails?.ownTwentyFivePercent,
    qtrOwnerOnly: data?.paymentDetails?.onlyTwentyFivePercentOwner,
    boardMember: data?.paymentDetails?.memberOfGoverningBoard,
    boardMemberOnly: data?.paymentDetails?.onlyMemberOfGoverningBoard,
    offlineDineIn: data?.services?.dineIn.dineInVouchers,
    offlineTakeaway: data?.services?.takeaway?.takeawayVouchers,
    inAppOrdering: data?.services?.dineIn?.tableOrders,
    tableOrdering: data?.services?.dineIn.tableOrdering,
    odTableOrdering: data?.services?.whiteLabel.tableOrdering,
    odTakeaway: data?.services?.whiteLabel.takeaway,
    odDelivery: data?.services?.whiteLabel.delivery,
    setupFee: bookingsOnly ? 0 : convertDollarsToCents(data?.fees?.setup?.amount),
    membershipFee: convertDollarsToCents(data?.fees?.membership?.amount),
    dineInFee:
      data?.fees?.dineIn?.pricingType === "percent"
        ? -1
        : convertDollarsToCents(data?.fees?.dineIn?.amount),
    dineInFeeType:
      data?.fees?.dineIn?.pricingType === "percent"
        ? "BOOKING"
        : APIEnumFromString(data?.fees?.dineIn?.pricingType),
    dineInAgreement: APIEnumFromString(data?.fees?.dineIn?.agreement),
    dineInOnlinePercent: data?.fees?.dineIn?.differentOnlinePricing
      ? data?.fees?.dineIn?.onlineAmount
      : -1,
    takeawayFee:
      data?.fees?.takeawayOrders?.pricingType === "percent"
        ? -1
        : convertDollarsToCents(data?.fees?.takeawayOrders?.amount),
    takeawayFeeType:
      data?.fees?.takeawayOrders?.pricingType === "percent"
        ? "BOOKING"
        : APIEnumFromString(data?.fees?.takeawayOrders?.pricingType),
    takeawayAgreement: APIEnumFromString(data?.fees?.takeawayOrders?.agreement),
    takeawayOnlinePercent: data?.fees?.takeawayOrders?.differentOnlinePricing
      ? data?.fees?.takeawayOrders?.onlineAmount
      : -1,
    odFee: convertDollarsToCents(data?.fees?.whiteLabel?.amount),
    pricingNotes: data?.fees?.pricingNotes,
    hours: JSON.stringify(convertHoursToApiFormat(data?.openingHours)),
    emailSettings: ["marketing", "orders", "invoices", "oppos"].flatMap((category) =>
      data?.communication[category]?.isUsed
        ? data?.communication[category]?.emailAddresses.map((emailAddress) => ({
            category: APIEnumFromString(category),
            email: emailAddress,
          }))
        : [],
    ),
    typicalEta: data?.operations?.preparationTime,
    wifiAvailable: data?.operations?.wifiAvailable,
    tabletRequired: data?.operations?.tabletRequired,
    pdfMenu: data?.operations?.pdfLink,
    menuImages: data?.operations?.menuImageLink,
    deals: data?.deals?.map((deal) => ({
      dineIn: deal.diningOptions.includes("dine_in"),
      takeaway: deal.diningOptions.includes("takeaway"),
      timeLimited: deal.timeType === "time_limited",
      discount: deal.percentage,
      startTime: deal.startTime,
      endTime: deal.endTime,
      qty: deal.amount,
      recurring: deal.repeatType === "repeat",
      recurringDays: deal.repeatDays.map((day) =>
        getLabelForValue(day, weekdaysShort()).toLowerCase(),
      ),
      startDate: deal.startDate,
    })),
    odLogoLink: data?.orderDirect?.logo,
    odHeroLink: data?.orderDirect?.heroImage,
    odMenuImages: data?.orderDirect?.menuImageLink,
    odTableCount: data?.orderDirect?.tableAmount,
    odPrimaryColor: data?.orderDirect?.brandColor,
    odSlug: data?.orderDirect?.slug,
    bankAccountName: data?.paymentDetails?.bankAccountName
      ? data?.paymentDetails?.bankAccountName.replace(/\s/g, "")
      : null,
    bankAccountNumber: data?.paymentDetails?.bankAccountNumber,
    bankBSB: data?.paymentDetails?.bsb,
    loginUsername: data?.username,
    loginPassword: obscureString(data?.password),
    signatureImageLink: data?.signature,
    completeSubmission: false,
    ccBillingMethodName: data?.paymentDetails?.creditCardName,
    ddBillingMethodName: data?.paymentDetails?.directDebitCapture
      ? data?.paymentDetails?.directDebitName
      : null,
    ccBillingMethodId: data?.paymentDetails?.creditCardStripeId,
    ddBillingMethodId: data?.paymentDetails?.directDebitCapture
      ? data?.paymentDetails?.directDebitStripeId
      : null,
    preferredBillingMethod: data?.paymentDetails?.preferredMethod,
    ecBookingsSlug: data?.services?.bookings?.enabled ? data?.services?.bookings?.slug : null,
    ecBookingsOperationalEmail: data?.services?.bookings?.enabled
      ? data?.services?.bookings?.email
      : null,
    ecBookings: data?.services?.bookings?.enabled ? data?.services?.bookings?.plan : null,
    posSystem: data?.posSystem === "Other" ? data?.customPos : data?.posSystem ?? null,
    bookingsPlatform:
      data?.bookingSystem === "Other" ? data?.customBookingSystem : data?.bookingSystem ?? null,
    bookingsPlatformExpiry: convertDateFormat(
      data?.bookingsPlatformExpiry,
      "DD/MM/YYYY",
      "YYYY-MM-DD",
    ),
  };

  // Remove whitelabel fields if not using them
  if (
    !data.services.whiteLabel.tableOrdering &&
    !data.services.whiteLabel.takeaway &&
    !data.services.whiteLabel.delivery
  ) {
    delete mappedData.odSlug;
    delete mappedData.odLogoLink;
    delete mappedData.odHeroLink;
    delete mappedData.odMenuImages;
    delete mappedData.odTableCount;
    delete mappedData.odPrimaryColor;
    mappedData.odFee = 0;
  }

  console.log("sending data", mappedData);
  return mappedData;
};

import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import { DealCard } from "./DealCard";
import "./DealListStyles.css";

export const DealGrid = ({ className, style, label, deals }) => (
  <Box className={`deal-grid-container ${className}`} style={style}>
    {label && (
      <Box className="paragraph-large brand-primary" style={{ marginBottom: "20px" }}>
        {label}
      </Box>
    )}
    <Box style={{ display: "inline-flex", flexWrap: "wrap", gap: "20px" }}>
      {deals.map((deal, index) => (
        <Box>
          <DealCard deal={deal} />
        </Box>
      ))}
    </Box>
  </Box>
);

DealGrid.defaultProps = {
  className: "",
  style: {},
  label: "",
  deals: [],
};

DealGrid.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  label: PropTypes.string,
  deals: PropTypes.arrayOf(PropTypes.shape({})),
};

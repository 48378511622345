import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import "../signupForm/SignupForm.css";

export const ServiceOption = ({ onSelect, title, description, children, style }) => (
  <Box className="select-card-wrapper" onClick={onSelect} style={style}>
    <Box className="select-card">
      <Box className="select-card-top">
        <Box style={{ marginBottom: "20px" }}>
          <h3>{title}</h3>
        </Box>
        <Box style={{ marginBottom: "25px" }} className="paragraph-regular">
          {description}
        </Box>
      </Box>
      {children}
    </Box>
  </Box>
);

ServiceOption.defaultProps = {
  onSelect: undefined,
  title: "",
  description: "",
  style: {},
};

ServiceOption.propTypes = {
  onSelect: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  style: PropTypes.shape({}),
};

import {
  Button,
  ComboBox,
  DatePicker,
  SegmentedControl,
  ToggleGroup,
  Utils,
} from "@eatclub-apps/ec-component-library";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { formStateType } from "../../../types";
import {
  dealAmounts,
  dealRepeatOptions,
  dealTimeOptions,
  dealPercentages,
  dealDiningOptions,
  weekdaysShort,
  getLabelForValue,
  weekdays,
} from "../../../data/ValueLists";
import CriticalWarningSVG from "../../../assets/critical_warning.svg";
import { getTimes } from "../../../helpers/Times";
import { DealList } from "../../DealList/DealList";
import { formatTimeFromInt } from "../../../helpers/Helpers";

export const DealsPage = ({ formState, setFieldValue, isComplete }) => {
  // TODO check that resetting the form doesn't make this buggy, and that state resets

  const [pageState, setPageState] = useState(
    !isComplete && formState.deals.length === 0 ? "create" : "view",
  ); // create, view, edit
  const [editingIndex, setEditingIndex] = useState(0); // Which deal in the list is being edited
  const [validationErrors, setValidationErrors] = useState([]);

  const getDefaultDealValues = () => ({
    percentage: null,
    amount: 1,
    timeType: "all_day",
    startDate: dayjs().format("YYYY-MM-DD"),
    startTime: null, // TODO if all day, set to opening hours of that day. Logic is in partner portal
    endTime: null,
    repeatType: "onetime",
    repeatDays: [],
    repeatDay: null, // repeat days breaks out into up to 7 deals
    diningOptions: [],
    diningOption: null, // Dining options break out into 2 different deals
  });

  const [newDeal, setNewDeal] = useState(getDefaultDealValues);

  const createNewDeal = () => {
    setNewDeal(getDefaultDealValues);
    setPageState("create");
  };

  const updateDeal = (field, value) => {
    if (
      field === "percentage" ||
      field === "diningOptions" ||
      field === "repeatType" ||
      field === "repeatDays" ||
      field === "timeType"
    ) {
      setValidationErrors([]);
    }

    const updatedDeal = { ...newDeal };
    updatedDeal[field] = value;
    setNewDeal(updatedDeal);
  };

  const shiftStrings = (shifts) =>
    shifts
      .map((shift) => {
        if (shift?.start === null || shift?.end === null) {
          return "";
        }

        return `${Utils.Times.formatTimeFromInt(shift?.start)} - ${Utils.Times.formatTimeFromInt(shift?.end)}`;
      })
      .filter((string) => string !== "")
      .join(" and ");

  const saveDeal = () => {
    let errors = [];
    if (newDeal.percentage === null) {
      errors = [...errors, "Deal percentage must be selected"];
    }
    // console.log("new deal", newDeal);
    if (newDeal.diningOptions.length === 0) {
      errors = [...errors, "Deal must be available for dine-in or takeaway"];
    }

    if (newDeal.repeatType === "repeat" && newDeal.repeatDays.length === 0) {
      errors = [...errors, "At least one repeat day must be selected"];
    }

    // Validate start and end time
    console.log("new deal...", newDeal);
    if (
      newDeal.timeType === "time_limited" &&
      (newDeal.startTime === null || newDeal.endTime === null)
    ) {
      errors = [...errors, "Start time and end time must both be chosen for time limited deals"];
    }

    // Time only moves forwards in this reality
    if (newDeal.timeType === "time_limited" && newDeal.startTime >= newDeal.endTime) {
      errors = [...errors, "Deal must end after it starts."];
    }

    // Check the restaurant is open during deal hours
    if (newDeal.timeType === "time_limited") {
      if (newDeal.repeatType === "repeat") {
        newDeal.repeatDays.forEach((repeatDay) => {
          // If closed
          if (!formState?.openingHours[repeatDay]?.isOpen) {
            errors = [
              ...errors,
              `A deal cannot be posted on a day where the restaurant is closed. - ${getLabelForValue(
                repeatDay,
                weekdays(),
              )}`,
            ];
          } else {
            // Ensure deal starts and ends during a single shift
            const validShifts = formState?.openingHours[repeatDay]?.shifts.filter(
              (shift) => shift?.start <= newDeal?.startTime && shift?.end >= newDeal?.endTime,
            );

            if (validShifts.length === 0) {
              errors = [
                ...errors,
                `The deal for ${getLabelForValue(
                  repeatDay,
                  weekdays(),
                )} is during closed hours for that day. Opening hours: ${shiftStrings(
                  formState?.openingHours[repeatDay]?.shifts,
                )}`,
              ];
            }
          }
        });
      } else {
        // Get day that deal is for
        const dayOfDeal = dayjs(newDeal.startDate).format("dddd").toLowerCase();

        // Ensure deal starts and ends during a single shift
        const validShifts = formState?.openingHours[dayOfDeal]?.shifts.filter(
          (shift) => shift?.start <= newDeal?.startTime && shift?.end >= newDeal?.endTime,
        );

        if (validShifts.length === 0) {
          errors = [
            ...errors,
            `The deal is during closed hours for that day. Opening hours: ${shiftStrings(
              formState?.openingHours[dayOfDeal]?.shifts,
            )}`,
          ];
        }
      }
    }

    setValidationErrors(errors);

    // Abort saving the deal if we have any errors
    if (errors.length !== 0) {
      return;
    }

    // Add new deal
    if (pageState === "create") {
      setFieldValue("deals", [...formState.deals, newDeal]);
    } else if (pageState === "edit") {
      // Update existing deal
      setFieldValue(`deals.${editingIndex}`, newDeal);
    }

    // Show the deals screen
    setPageState("view");
  };

  // TODO filter start and end time pickers to restaurant opening hours

  // TODO different views should be in different files

  const renderCreate = () => (
    <Box>
      <Box style={{ marginBottom: "20px" }}>
        <h3 className="brand-primary">{pageState === "create" ? "Add a deal" : "Edit deal"}</h3>
      </Box>
      <ComboBox
        style={{ marginBottom: "20px" }}
        label="1. Deal offered"
        placeholder="Select a deal"
        items={dealPercentages()}
        value={newDeal.percentage}
        onSelect={(value) => updateDeal("percentage", value)}
      />
      <SegmentedControl
        style={{ marginBottom: "20px" }}
        label="2. Number of deals"
        items={dealAmounts()}
        value={newDeal.amount}
        onSelect={(value) => updateDeal("amount", value)}
      />
      <DatePicker
        style={{ marginBottom: "20px" }}
        label="3. Start date"
        value={newDeal.startDate}
        onSelect={(value) => updateDeal("startDate", value)}
        disablePast
      />
      <Box>
        <SegmentedControl
          style={{ marginBottom: "20px" }}
          label="4. Time the deal is on offer"
          items={dealTimeOptions()}
          value={newDeal.timeType}
          onSelect={(value) => updateDeal("timeType", value)}
        />
        {newDeal?.timeType === "time_limited" && (
          <Box
            style={{ maxWidth: "600px", marginBottom: "20px", marginLeft: "20px" }}
            className="two-column"
          >
            <ComboBox
              items={getTimes()}
              label="Start time"
              value={newDeal.startTime}
              placeholder="Pick a start time"
              onSelect={(value) => updateDeal("startTime", value)}
            />
            <ComboBox
              items={getTimes()}
              label="End time"
              value={newDeal.endTime}
              placeholder="Pick an end time"
              onSelect={(value) => updateDeal("endTime", value)}
            />
          </Box>
        )}
      </Box>
      <Box style={{ marginBottom: "20px" }}>
        <SegmentedControl
          label="5. One-time or repeat"
          items={dealRepeatOptions()}
          value={newDeal.repeatType}
          onSelect={(value) => updateDeal("repeatType", value)}
        />
        {newDeal.repeatType === "repeat" && (
          <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
            <ToggleGroup
              showCheckmark={false}
              items={weekdaysShort()}
              value={newDeal.repeatDays}
              onSelect={(value) => updateDeal("repeatDays", value)}
              singleChoice={false}
              style={{
                selectedOption: {
                  color: "#FFFFFF",
                  backgroundColor: "#E54439",
                  borderColor: "#E54439",
                },
              }}
            />
          </Box>
        )}
      </Box>
      <Box style={{ marginBottom: "60px" }}>
        <ToggleGroup
          showCheckmark={false}
          items={dealDiningOptions()}
          label="6. Available for"
          value={newDeal.diningOptions}
          singleChoice={false}
          onSelect={(value) => updateDeal("diningOptions", value)}
          style={{
            selectedOption: {
              color: "#FFFFFF",
              backgroundColor: "#E54439",
              borderColor: "#E54439",
            },
          }}
        />
      </Box>
      {validationErrors.length !== 0 && (
        <Box className="error-box">
          <Box style={{ display: "inline-flex", columnGap: "5px" }}>
            <CriticalWarningSVG
              style={{
                // position: "absolute",
                // marginLeft: "3px",
                marginTop: "8px",
                height: "24px",
              }}
            />
            <Box style={{ marginBottom: "15px" }} className="error-text">
              Cannot {pageState === "create" ? "create" : "update"} the deal
            </Box>
          </Box>
          <Box style={{ marginBottom: "20px" }} className="paragraph-regular">
            Please fix the following errors to {pageState === "create" ? "create" : "update"} the
            deal:
          </Box>
          {validationErrors.map((error, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box style={{ marginBottom: "10px" }} className="paragraph-small" key={i}>
              {error}
            </Box>
          ))}
        </Box>
      )}
      <Button
        type="secondary"
        text={pageState === "create" ? "Save deal" : "Update deal"}
        onClick={saveDeal}
      />
    </Box>
  );

  const renderView = () => (
    <Box style={{ marginTop: "50px" }}>
      <DealList
        label={`${formState.deals.length} deal${formState.deals.length === 1 ? "" : "s"} created`}
        deals={formState.deals}
        onClick={(index) => {
          setPageState("edit");
          setNewDeal(formState.deals[index]);
          setEditingIndex(index);
        }}
        allowEdit={!isComplete}
      />
      {!isComplete && <Button type="secondary" text="Add a new deal" onClick={createNewDeal} />}
    </Box>
  );

  return (
    <Box className="deals-page" style={{ maxWidth: "980px" }}>
      <Box style={{ marginBottom: "50px" }}>
        <Box style={{ marginBottom: "20px" }}>
          <h1>Dynamic pricing deals</h1>
        </Box>
        <Box style={{ marginBottom: "50px" }} className="paragraph-large">
          Strategically price your menu to maximise customers and revenue.
        </Box>
        {(pageState === "create" || pageState === "edit") && !isComplete && renderCreate()}
        {pageState === "view" && renderView()}
      </Box>
    </Box>
  );
};

DealsPage.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Button, TextField } from "@eatclub-apps/ec-component-library";
import { formStateType } from "../../types";
import { checkSlugExists } from "../../graphql/api-client";
import { formatAsSlug, isEmpty } from "../../helpers/Helpers";
import { COLORS } from "../../theme";
import useStyles from "./SlugInputStyles";

export const SlugInput = ({ formState, setFieldValue, isComplete }) => {
  const classes = useStyles();

  const [slug, setSlug] = useState(
    formState?.services?.bookings?.slug || formatAsSlug(formState.businessInfo.tradingName),
  );
  const [error, setError] = useState(null);

  const checkSlug = async () => {
    if (isEmpty(slug.trim())) {
      setError("You must set a URL for the booking widget");
      return;
    }

    const existingSlug = await checkSlugExists({ slug: slug.trim() });
    if (existingSlug) {
      setError("URL is not available. Please try something different");
    } else {
      setError("URL is available :)");
    }
  };

  return (
    <Box className={classes.slugContainer}>
      <Box className={classes.slugTitle}>URL for the venue’s booking widget</Box>
      <Box className={classes.urlContainer}>
        <Box className={classes.slugInput}>
          <TextField
            inputPrefix="ecbookings.com/"
            value={slug}
            onChange={setSlug}
            clearError={() => setError(null)}
            onBlur={async () => {
              await checkSlug();
              setFieldValue("services.bookings.slug", slug.trim());
            }}
            disabled={isComplete}
            onEnter={checkSlug}
            style={{ inputPrefix: { color: COLORS.SMOKE }, width: "600px" }}
          />
        </Box>
        <Button
          style={{}}
          onClick={checkSlug}
          text="Check URL"
          type="secondary"
          disabled={isComplete}
        />
      </Box>
      <Box>{error}</Box>
    </Box>
  );
};

SlugInput.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isComplete: PropTypes.bool.isRequired,
};

// eslint-disable react/require-default-props

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Box, TextField, Button } from "@eatclub-apps/ec-component-library";
import "../signupForm/SignupForm.css";
import { getLongAddress, isEmpty, joinWords, loadScript } from "../../helpers/Helpers";
import useStyles from "./AddressSearchStyles";
import LocationSVG from "../../assets/icon_location.svg";
import { geocodeByPlaceId, usePlacesAutocomplete } from "./usePlacesAutocomplete";

/**
 * Searches an address using Google Places API
 * Allows typing in a custom address instead
 */
export const AddressSearch = ({
  label = "Address",
  placeholder = "Search address",
  error = null,
  values = {},
  setValues = () => {},
  disallowCustomAddress = false,
  onShowCustomAddress = () => {}, // Button to show a custom address instead of this
  clearError = () => {},
}) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState(null); // The value that's been physically typed in
  const [filledValue, setFilledValue] = useState(null); // The value that's been filled from the address

  const [focused, setFocused] = useState(false); // Whether we're focused on the input

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Which item is hovered over (or using keyboard)
  const [predictions] = usePlacesAutocomplete(inputValue);
  const hasHadPredictions = useRef(false); // To stop a "no results found" before first predictions

  const ref = useRef(null);

  /**
   * Dynamically load the script, to avoid needing to manually place it in the
   * index.html file (meaning we only load it if this component is being used)
   */
  const loadPlacesScript = () => {
    const googlePlacesUrl = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_PLACES_API_KEY}&libraries=places`;

    const checkLoaded = () => !!window?.google?.maps?.places;

    loadScript(googlePlacesUrl, checkLoaded);
  };

  /**
   * Initial render
   */
  useEffect(() => {
    loadPlacesScript();

    // Set the address to the details provided
    setFilledValue(getLongAddress(values));
  }, []);

  // Set flag when first predictions come back
  useEffect(() => {
    if (!isEmpty(predictions)) {
      hasHadPredictions.current = true;
    }
  }, [predictions]);

  /**
   * When the input changes, try to show suggestions
   */
  const onChange = async (newValue) => {
    setInputValue(newValue);
    // setFilledValue(null);

    if (isEmpty(newValue)) {
      setShowSuggestions(false);
      setSelectedItem(null);
      return;
    }

    setShowSuggestions(true);
  };

  /**
   * When the address is selected (e.g. clicked from the list)
   */
  async function selectAddress(placeIndex) {
    if (placeIndex < 0 || placeIndex >= predictions.length) {
      console.error("Tried to select an index not in list");
      return;
    }

    const place = predictions[placeIndex];
    setFilledValue(place?.description);

    const addressDetails = await geocodeByPlaceId(place?.place_id);
    const addressComponents = addressDetails?.address_components || [];

    const findComponent = (name, preferLong = false) => {
      const foundComponent = addressComponents.find((component) => component?.types.includes(name));

      if (preferLong) {
        return foundComponent?.long_name;
      }

      return foundComponent?.short_name;
    };

    const details = {
      address: joinWords([findComponent("street_number"), findComponent("route")]),
      suburb: findComponent("locality"),
      region: findComponent("administrative_area_level_2"), // e.g. Moreton Bay Region
      state: findComponent("administrative_area_level_1"),
      postcode: findComponent("postal_code"),
      country: findComponent("country"),
    };

    clearError();
    setValues(details);
  }

  /**
   * Clear all the values, e.g. if we "delete" the address
   */
  const clearValues = () => {
    setValues({
      address: null,
      suburb: null,
      region: null,
      state: null,
      postcode: null,
      country: null,
    });
  };

  /**
   * Keyboard accessibility
   */
  const handleKeyPress = (event) => {
    // Return early if this is not the active element
    if (document.activeElement !== ref.current) {
      return;
    }

    // Prevent cursor from moving to start or end of input
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }

    if (showSuggestions) {
      if (event.key === "ArrowDown") {
        if (selectedItem === null) {
          setSelectedItem(0);
        } else {
          const nextItem = selectedItem + 1;
          if (nextItem < predictions.length) {
            setSelectedItem(nextItem);
          }
        }
      } else if (event.key === "ArrowUp") {
        if (selectedItem === null) {
          setSelectedItem(0);
        } else {
          const previousItem = selectedItem - 1;
          if (previousItem >= 0) {
            setSelectedItem(previousItem);
          }
        }
      }

      // If press enter, select the hovered item
      if (event.key === "Enter") {
        // If no hovered item, but only one option, select that
        if (selectedItem === null && predictions.length === 1) {
          selectAddress(0);
        } else if (selectedItem !== null) {
          selectAddress(selectedItem);
        }
      }
    } else if (event.key === "ArrowDown") {
      setSelectedItem(0);
    }
  };

  // Add keyboard event listener to input
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.textFieldContainer}>
        <TextField
          inputRef={ref}
          onChange={onChange}
          placeholder={placeholder}
          value={focused ? inputValue : filledValue || inputValue}
          label={label}
          error={error}
          autoCapitalize="words"
          onBlur={() => {
            if (isEmpty(inputValue)) {
              setFilledValue(null);
              clearValues();
            }
            setShowSuggestions(false);
            setSelectedItem(null);
            setFocused(false);
          }}
          onFocus={() => {
            setFocused(true);
            if (!isEmpty(predictions)) {
              setShowSuggestions(true);
            }
          }}
        />
        {!disallowCustomAddress && (
          <Box className={classes.buttonContainer}>
            <Button
              type="text"
              text="Enter address manually"
              onClick={() => onShowCustomAddress(!disallowCustomAddress)}
              // style={theme.components.textButton}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.resultsWrapper}>
        {hasHadPredictions.current && showSuggestions && !isEmpty(inputValue) && (
          <Box className={classes.resultsBox}>
            {/*{loading && isEmpty(predictions) && <Box>Loading...</Box>}*/}
            {isEmpty(predictions) && (
              <Box className={classes.resultAddress}>
                No results found. <br /> You can enter an address manually by clicking out of this
                text box then clicking &quot;Enter address manually&quot;
              </Box>
            )}
            {predictions.map((result, index) => (
              <Box
                key={result?.place_id}
                className={`${classes.resultsItem}
                  ${index === selectedItem && classes.resultsItemSelected}`}
                onMouseDown={() => selectAddress(index)}
                onMouseEnter={() => setSelectedItem(index)}
                onMouseLeave={() => setSelectedItem(null)}
              >
                <LocationSVG style={{ width: "20px", minWidth: "20px", maxWidth: "20px" }} />
                <Box className={classes.resultAddress}>{result?.description}</Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

AddressSearch.defaultProps = {
  label: "",
  placeholder: "",
  error: "",
  values: [],
  setValues: () => {},
  disallowCustomAddress: false,
  onShowCustomAddress: () => {},
};

AddressSearch.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  values: PropTypes.shape({}),
  setValues: PropTypes.func,
  disallowCustomAddress: PropTypes.bool,
  onShowCustomAddress: PropTypes.func,
};

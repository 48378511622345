import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    backgroundColor: "white",
    marginTop: "20px",
    marginBottom: "40px",
  },
}));

export default useStyles;

import { makeStyles } from "@mui/styles";
import { COLORS, SHADOWS } from "../../theme";

const useStyles = makeStyles(() => ({
  container: {},
  resultsWrapper: {
    position: "relative",
  },
  resultsBox: {
    boxSizing: "border-box",
    position: "absolute",
    padding: "8px",
    backgroundColor: "#FFFFFF",
    zIndex: 10,
    borderRadius: "12px",

    top: `-24px`,
    border: `1px solid ${COLORS.CLOUD}`,
    boxShadow: SHADOWS.STANDARD.boxShadow,
    width: "100%",
  },
  resultsItem: {
    display: "flex",
    gap: "14px",
    alignItems: "center",
    height: "36px",
    paddingLeft: "14px",
    paddingRight: "14px",
    userSelect: "none",
    cursor: "pointer",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    "&:hover": {
      backgroundColor: "#EFEFEF",
    },

    // transition: "background-color 0.1s",
  },
  resultAddress: {},
  resultsItemSelected: {
    backgroundColor: "#EFEFEF",
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  buttonContainer: {},
}));

export default useStyles;

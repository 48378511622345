import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import { getLabelForValue, dealDiningOptions, dealAmounts } from "../../data/ValueLists";
import { formatTimeRange, dayRange, sortAlphabetical } from "../../helpers/Helpers";
import "./DealListStyles.css";

export const DealList = ({ className, style, label, deals, onClick, allowEdit }) => {
  console.log("Deals shown:", deals);

  const handleClick = (index) => {
    if (!allowEdit) {
      return;
    }

    onClick(index);
  };

  return (
    <Box className={`deal-list-container ${className}`} style={style}>
      {label && (
        <Box className="paragraph-large brand-primary" style={{ marginBottom: "20px" }}>
          {label}
        </Box>
      )}
      <Box className="deal-list">
        <Box className="deal-list-full-width">
          <Box className="deal-list-inner">
            {deals.map((deal, index) => (
              <Box
                className={`deal-list-card ${allowEdit ? "deal-list-card-clickable" : ""}`}
                onClick={() => handleClick(index)}
              >
                <Box className="deal-list-card-header">
                  <Box className="deal-list-card-discount-pill">{deal.percentage || "0"}% off</Box>
                  <Box className="deal-list-card-header-text">
                    {deal?.diningOptions
                      .map((option) => getLabelForValue(option, dealDiningOptions()))
                      .sort(sortAlphabetical) // to keep Dine-in first
                      .join(" & ")}
                  </Box>
                </Box>
                <Box className="deal-list-card-body">
                  <Box style={{ marginBottom: "5px" }}>
                    {deal.timeType === "all_day"
                      ? "All day"
                      : `${formatTimeRange(deal.startTime, deal.endTime)}`}
                  </Box>
                  <Box style={{ marginBottom: "5px" }}>
                    {deal?.repeatType === "repeat"
                      ? `Repeats ${dayRange(deal?.repeatDays)}`
                      : "No repeat"}
                  </Box>
                  <Box>
                    {deal.amount === "no_limit"
                      ? "No limit"
                      : `${getLabelForValue(deal.amount, dealAmounts()) || "0"} deal${
                          deal.amount !== 1 ? "s" : ""
                        } available`}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DealList.defaultProps = {
  className: "",
  style: {},
  label: "",
  deals: [],
  onClick: () => {},
};

DealList.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  label: PropTypes.string,
  deals: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
};

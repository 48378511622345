import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@eatclub-apps/ec-component-library";
import { formStateType } from "../../../types";
import { membershipAmount, signupAmount } from "../../../data/DefaultFormValues";
import { formatAsSlug, isEmpty, trim } from "../../../helpers/Helpers";
import { ServiceSelection } from "../../ServiceSelection/ServiceSelection";
import { SlugInput } from "../../SlugInput/SlugInput";

export const Services = (props) => {
  const { formState, setFieldValue, isComplete, setFieldValues } = props;

  const [operationalEmail, setOperationalEmail] = useState(
    formState.services?.bookings?.email || "",
  );

  const currentServices = [
    { name: "services.dineIn.dineInVouchers", value: formState.services.dineIn.dineInVouchers },
    {
      name: "services.takeaway.takeawayVouchers",
      value: formState.services.takeaway.takeawayVouchers,
    },
    { name: "services.whiteLabel.takeaway", value: formState.services.whiteLabel.takeaway },
    {
      name: "services.whiteLabel.tableOrdering",
      value: formState.services.whiteLabel.tableOrdering,
    },
    { name: "services.whiteLabel.delivery", value: formState.services.whiteLabel.delivery },
  ];

  // Store what services were selected before Bookings only was clicked
  const [previousSelection, setPreviousSelection] = useState(currentServices);

  // Set the slug by default if we activated a whitelabel service
  useEffect(() => {
    // Don't change if it's already set, or it's disabled
    if (isComplete || !isEmpty(formState?.services?.orderDirect?.slug)) {
      return;
    }

    if (
      formState.services.whiteLabel.tableOrdering ||
      formState.services.whiteLabel.takeaway ||
      formState.services.whiteLabel.delivery
    ) {
      setFieldValue("orderDirect.slug", formatAsSlug(formState.businessInfo.tradingName));
    } else {
      setFieldValue("orderDirect.slug", formatAsSlug(""));
    }
  }, [
    formState.services.whiteLabel.tableOrdering,
    formState.services.whiteLabel.takeaway,
    formState.services.whiteLabel.delivery,
  ]);

  // Set the bookings slug by default if we activated bookings service
  useEffect(() => {
    // Don't change if it's already set, or it's disabled
    if (isComplete || !isEmpty(formState?.services?.bookings?.slug)) {
      return;
    }

    if (formState.services.bookings?.enabled) {
      setFieldValue("services.bookings.slug", formatAsSlug(formState.businessInfo.tradingName));
    }
  }, [formState.services.bookings?.enabled]);

  // TODO set the slug for ec bookings the same way

  // If bookings only is selected, deselect all other services.
  // TODO would be nice if it remembered what was selected before hitting bookings only
  const wasBookingsOnly = useRef(
    formState?.services?.bookings?.plan === "bookingsOnly" &&
      formState?.services?.bookings?.enabled,
  );
  const isBookingsOnly =
    formState?.services?.bookings?.plan === "bookingsOnly" &&
    formState?.services?.bookings?.enabled;

  useEffect(() => {
    if (!isComplete && !wasBookingsOnly.current && isBookingsOnly) {
      // Store what it was set to before, for easy undo
      setPreviousSelection(currentServices);

      // Deselect all other options
      setFieldValues([
        { name: "services.dineIn.dineInVouchers", value: false },
        { name: "services.takeaway.takeawayVouchers", value: false },
        { name: "services.whiteLabel.takeaway", value: false },
        { name: "services.whiteLabel.tableOrdering", value: false },
        { name: "services.whiteLabel.delivery", value: false },
      ]);
    }

    // Go back to the previously selected services if deselected
    if (!isComplete && wasBookingsOnly.current && !isBookingsOnly) {
      setFieldValues(previousSelection);
    }
  }, [isBookingsOnly]);

  // Set for next render
  useEffect(() => {
    wasBookingsOnly.current = isBookingsOnly;
  }, [isBookingsOnly]);

  // TODO take a prop like "onFieldUpdate" that passes the field name and value to the parent form
  return (
    <>
      <Box style={{ marginBottom: "20px" }}>
        <h1>Services</h1>
      </Box>
      <Box style={{ marginBottom: "50px" }}>
        <p className="paragraph-large">Select services to drive new customers.</p>
      </Box>
      <ServiceSelection
        isComplete={isComplete}
        selectDineIn={formState.services.dineIn.dineInVouchers}
        selectTakeaway={formState.services.takeaway.takeawayVouchers}
        selectODTakeaway={formState.services.whiteLabel.takeaway}
        selectODTableOrdering={formState.services.whiteLabel.tableOrdering}
        selectODDelivery={formState.services.whiteLabel.delivery}
        onSelectDineIn={(value) => setFieldValue("services.dineIn.dineInVouchers", value)}
        onSelectTakeaway={(value) => setFieldValue("services.takeaway.takeawayVouchers", value)}
        onSelectODTakeaway={(value) => setFieldValue("services.whiteLabel.takeaway", value)}
        onSelectODTableOrdering={(value) =>
          setFieldValue("services.whiteLabel.tableOrdering", value)
        }
        onSelectODDelivery={(value) => setFieldValue("services.whiteLabel.delivery", value)}
        selectEatClubBookings={formState.services?.bookings?.enabled}
        selectEatClubBookingsPlan={formState.services?.bookings?.plan}
        onSelectEatClubBookings={(value) => {
          if (value) {
            // ec bookings has locked membership fees
            setFieldValues([
              { name: "services.bookings.enabled", value },
              {
                name: "fees.membership.amount",
                value: formState.services?.bookings?.plan === "bookingsOnly" ? 0 : membershipAmount,
              },
              {
                name: "fees.setup.amount",
                value: formState.services?.bookings?.plan === "bookingsOnly" ? 0 : signupAmount,
              },
            ]);
          } else {
            setFieldValues([
              { name: "services.bookings.enabled", value },
              { name: "fees.membership.amount", value: membershipAmount }, // Back to default when disabled
              { name: "fees.setup.amount", value: signupAmount },
            ]);
          }
        }}
        onSelectEatClubBookingsPlan={(value) => {
          setFieldValues([
            { name: "services.bookings.plan", value },
            {
              name: "fees.membership.amount",
              value: value === "bookingsOnly" ? 0 : membershipAmount,
            },
            { name: "fees.setup.amount", value: value === "bookingsOnly" ? 0 : signupAmount },
          ]);
        }}
      />
      {formState.services?.bookings?.enabled && (
        <Box style={{ gap: "20px", display: "flex", flexDirection: "column" }}>
          <SlugInput isComplete={isComplete} formState={formState} setFieldValue={setFieldValue} />
          <TextField
            value={operationalEmail}
            onChange={setOperationalEmail}
            onBlur={() => {
              setFieldValue("services.bookings.email", trim(operationalEmail));
            }}
            disabled={isComplete}
            label="Operations email"
            style={{ maxWidth: "600px" }}
          />
        </Box>
      )}
    </>
  );
};

Services.propTypes = {
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  isComplete: PropTypes.bool.isRequired,
};

import { httpGet } from "../../helpers/Requests";

export const getApproximateGeolocationForAddress = async (address) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${import.meta.env.VITE_GOOGLE_PLACES_API_KEY}`;
  const response = JSON.parse(httpGet(url));
  // console.log("maps response:", response);
  if (response?.results?.length >= 1) {
    return response?.results[0]?.geometry?.location;
  }

  return null;
};
